import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import { ICountdown } from '../concepts/countdown';

export const create: StoreSlice<ICountdown, ILogger> = (set, get) => {
  return {
    isCountdownOn: false,
    countdownStartTime: 0,
    countdownDuration: 0,
    startCountdown(time: number, duration: number) {
      set({
        isCountdownOn: true,
        countdownStartTime: time,
        countdownDuration: duration,
      });
    },

    stopCountdown() {
      set({
        isCountdownOn: false,
      });
    },

    countdownEndTime() {
      if (!get().isCountdownOn) return 0;
      return get().countdownStartTime + get().countdownDuration;
    },

    countdownTimeLeft(time: number) {
      if (!get().isCountdownOn) return 0;
      return get().countdownEndTime() - time;
    },

    isCountdownAboutToExpire(time: number) {
      if (!get().isCountdownOn) return false;
      return get().countdownTimeLeft(time) <= get().countdownDuration * 0.1;
    },

    isCountdownExpired(time: number) {
      if (!get().isCountdownOn) return false;
      return get().countdownTimeLeft(time) < 0;
    },
  };
};
