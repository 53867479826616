import { useMemo } from 'react';
import { Link, useRoute } from 'wouter';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { useTheme } from '../../stores/theme';
import { PlayTimeScreen } from './play-time-screen';
import SectionTitle from '../game/section-title';
import { useGame } from '../../stores/game';
import roles from '../contents/roles';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';

const Profile = ({ name, role, avatar, icon, link, isOwnRole }) => {
  const images = useTheme(({ images }) => images);

  return (
    <Link href={link}>
      <div
        className={clsx(
          'relative flex flex-col items-center justify-start w-full h-full p-2 text-center text-gray-900 cursor-pointer',
          isOwnRole && 'border-2 border-blue-400/20 rounded-lg'
        )}
      >
        {isOwnRole && (
          <p className="absolute top-0 bottom-0 left-0 font-bold -rotate-90 text-blue-700/80 2xl:left-6">
            Il tuo ruolo
          </p>
        )}

        <span className="relative mb-2 bg-white border-2 rounded-full x-12 lg:w-16 aspect-square xl:border-4 border-neutral-200 xl:w-20 2xl:w-24">
          <img
            src={
              avatar ||
              images(
                buildOptimizedAssetPath('avatars/missing', ['avif', 'png'])
              )
            }
            alt={name}
            className="w-full rounded-full aspect-square"
          />
          <span className="absolute -bottom-2 -right-2 p-[2px] xl:p-[4px] 2xl:p-[6px] aspect-square border-2 border-hospitaliaTeal-base h-5 w-5 lg:h-6 lg:w-6 xl:h-7 xl:w-7 2xl:w-8 2xl:h-8 rounded-full bg-white">
            <img
              src={icon}
              alt={role}
              className="w-auto h-auto max-w-full max-h-full m-auto"
            />
          </span>

          {!avatar && (
            <span className="absolute px-4 py-1 text-xs font-bold text-center text-white uppercase scale-75 rotate-45 bg-red-400 rounded transform-gpu xl:scale-100 -top-1 -right-10">
              Assente
            </span>
          )}
        </span>

        <p className="text-base font-medium lg:text-lg xl:text-xl 2xl:text-2xl">
          {name}
        </p>
        <p className="text-xxs lg:text-xs xl:text-sm 2xl:text-base">{role}</p>
      </div>
    </Link>
  );
};

export const ProfilesScreen = () => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);
  const avatars = useGame(({ avatars }) => avatars);
  const team = useGame(({ team }) => team);
  const [_match, params] = useRoute<GameRouteParams>('/:gamePlayId/profiles');
  const ownRole: string = useGame(player => player?.role);

  const teamMembers = useMemo(
    () =>
      roles.map(role => ({
        ...role,
        name: formatMessage({ id: `profiles:${role.id}.name` }),
        role: formatMessage({ id: `profiles:${role.id}.title` }),
        icon: images(`icon-ruolo/Big/${role.iconName}`),
        avatar: avatars[`${role.shortname}.${team}`]
          ? images(`avatars/${avatars[`${role.shortname}.${team}`]}.svg`)
          : undefined,
        link: `/${params.gamePlayId}/profiles/${role.shortname}`,
      })),
    []
  );

  return (
    <PlayTimeScreen>
      <SectionTitle label="Il tuo team" />

      <div className="px-10 py-2 lg:py-4 2xl:py-8 lg:px-32 2xl:px-64">
        <div className="grid grid-cols-3 grid-rows-2 gap-3 lg:gap-4 xl:gap-6 2xl:gap-8">
          {teamMembers.map(teamMember => (
            <Profile
              key={teamMember.id}
              {...teamMember}
              isOwnRole={ownRole === teamMember.shortname}
            />
          ))}
        </div>
      </div>
    </PlayTimeScreen>
  );
};

type GameRouteParams = {
  gamePlayId: string;
  profileId: string;
};
