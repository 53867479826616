import CountUp from 'react-countup';
import { useIntl } from 'react-intl';
import shallow from 'zustand/shallow';
import { useGame } from '../../../stores/game';

const Phase1Scenario = () => {
  const { formatMessage: f } = useIntl();

  const { totalDrugsCostsYear0, unexpectedEventCostsYear0, complianceYear0 } =
    useGame(
      ({
        totalDrugsCostsYear0,
        unexpectedEventCostsYear0,
        complianceYear0,
      }) => ({
        totalDrugsCostsYear0: totalDrugsCostsYear0(),
        unexpectedEventCostsYear0: unexpectedEventCostsYear0(),
        complianceYear0: complianceYear0(),
      }),
      shallow
    );

  return (
    <div className="w-full h-[90%]">
      <div className="w-1/2 py-1 pr-8 mt-4 mb-5 text-right bg-red-400 xl:py-2 rounded-r-xl xl:w-1/3 xl:mt-8 lg:mb-6 xl:mb-8 2xl:mb-10 bg-opacity-80">
        <p className="text-xs font-bold leading-none text-white uppercase md:leading-none lg:leading-none xl:leading-none lg:text-sm xl:text-base 2xl:text-lg">
          {f({ id: 'pages:phases-phase-1.title' })}
        </p>
      </div>

      <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
        <p className="text-base xl:text-xl">
          {f({ id: 'pages:scenarios-phase-1.content' })}
        </p>

        <table className="min-w-full mt-2 divide-y divide-gray-300 xl:mt-3 2xl:mt-4">
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg">
                {f({ id: 'vars:spesa-totale-farmaci.label' })}
              </td>
              <td className="py-2 pl-4 pr-3 text-sm font-medium text-right text-gray-900 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  start={0}
                  end={Number(totalDrugsCostsYear0)}
                  decimals={0}
                  separator="."
                  suffix="€"
                />
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg">
                {f({ id: 'vars:spesa-totale-eventi-avversi.label' })}
              </td>
              <td className="py-2 pl-4 pr-3 text-sm font-medium text-right text-gray-900 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  start={0}
                  end={unexpectedEventCostsYear0}
                  decimals={0}
                  separator="."
                  suffix="€"
                />
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg">
                {f({ id: 'vars:compliance-media.label' })}
              </td>
              <td className="py-2 pl-4 pr-3 text-sm font-medium text-right text-gray-900 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  start={0}
                  end={Number(complianceYear0 * 100)}
                  decimals={2}
                  decimal=","
                  suffix="%"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <p className="mt-4 text-base lg:text-lg xl:text-xl 2xl:text-2xl lg:mt-6 xl:mt-8 2xl:mt-10">
          {f({ id: 'pages:meetings-first-meeting.questionStatement' })}
        </p>

        <p className="mt-2 text-sm font-bold xl:mt-3 2xl:mt-4 lg:text-base xl:text-lg 2xl:text-xl">
          {f({ id: 'pages:meetings-first-meeting.notes' })}
        </p>
      </div>
    </div>
  );
};

export default Phase1Scenario;
