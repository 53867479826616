import { PlayTimeScreen } from './play-time-screen';
import { useIntl } from 'react-intl';
import { useTheme } from '../../stores/theme';
import SectionTitle from '../game/section-title';

export const RulesScreen = () => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);

  return (
    <PlayTimeScreen>
      <SectionTitle label={formatMessage({ id: 'pages:rules.title' })} />
      <RulesContainer>
        <RuleItem>
          <RuleItemIcon
            src={images('navigation-bar/Archivio Documenti.svg')}
            alt="Archivio documenti"
          />
          <RuleItemContent>
            <RuleItemTitle>
              {formatMessage({ id: 'rules:documents.title' })}
            </RuleItemTitle>
            {formatMessage({ id: 'rules:documents.content' })}
          </RuleItemContent>
        </RuleItem>
        <RuleItem>
          <RuleItemIcon src={images('navigation-bar/Profili.svg')} alt="" />
          <RuleItemContent>
            <RuleItemTitle>
              {formatMessage({ id: 'rules:your-team.title' })}
            </RuleItemTitle>
            {formatMessage({ id: 'rules:your-team.content' })}
          </RuleItemContent>
        </RuleItem>
        <RuleItem>
          <RuleItemIcon src={images('navigation-bar/Obiettivi.svg')} alt="" />
          <RuleItemContent>
            <RuleItemTitle>
              {formatMessage({ id: 'rules:your-goals.title' })}
            </RuleItemTitle>
            {formatMessage({ id: 'rules:your-goals.content' })}
          </RuleItemContent>
        </RuleItem>
        <RuleItem>
          <RuleItemIcon src={images('navigation-bar/Chat.svg')} alt="" />
          <RuleItemContent>
            <RuleItemTitle>
              {formatMessage({ id: 'rules:chat.title' })}
            </RuleItemTitle>
            {formatMessage({ id: 'rules:chat.content' })}
          </RuleItemContent>
        </RuleItem>
        <RuleItem>
          <RuleItemIcon src={images('navigation-bar/Bussola.svg')} alt="" />
          <RuleItemContent>
            <RuleItemTitle>
              {formatMessage({ id: 'rules:back-to-story.title' })}
            </RuleItemTitle>
            {formatMessage({ id: 'rules:back-to-story.content' })}
          </RuleItemContent>
        </RuleItem>
        <RuleItem>
          <RuleItemIcon src={images('timer.png')} alt="" />
          <RuleItemContent>
            <RuleItemTitle>
              {formatMessage({ id: 'rules:timer.title' })}
            </RuleItemTitle>
            {formatMessage({ id: 'rules:timer.content' })}
          </RuleItemContent>
        </RuleItem>
      </RulesContainer>
    </PlayTimeScreen>
  );
};

const RulesContainer = ({ children }) => (
  <div className="flex flex-row flex-wrap w-full px-10 lg:px-20 xl:px-64 m-auto">
    {children}
  </div>
);

const RuleItem = ({ children }) => (
  <div className="flex flex-row basis-1/2 p-4 gap-4">{children}</div>
);

const RuleItemTitle = ({ children }) => (
  <h3 className="text-hospitaliaTeal-base font-bold text-sm xl:text-xl uppercase mb-1">
    {children}
  </h3>
);

const RuleItemIcon = ({ src, alt }) => (
  <span className="rounded-full border-2 border-neutral-200/90 bg-white h-12 xl:h-24 aspect-square flex items-center justify-center">
    <img src={src} alt={alt} className="aspect-square" />
  </span>
);

const RuleItemContent = ({ children }) => (
  <div className="leading-tight text-xs xl:text-base">{children}</div>
);
