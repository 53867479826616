import { GetState } from 'zustand';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IToDos } from '../../concepts/to-dos';
import { IPhaseExecutorFn } from '../game';

export const startPhase2End: IPhaseExecutorFn = (
  get: GetState<IGame & IDocs & IToDos & ILocations>
) => {
  get().clearToDos();
  ToDos.forEach(get().appendToDo);
};

const ToDos = [];
