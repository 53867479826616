import create, { GetState, SetState, UseBoundStore } from 'zustand';
import { persist, StateStorage, StoreApiWithPersist } from 'zustand/middleware';
import * as Avatar from './factories/avatar';
import * as Countdown from './factories/countdown';
import * as Docs from './factories/docs';
import * as Game from './factories/game';
import * as Locations from './factories/locations';
import * as Logger from './factories/logger';
import * as Player from './factories/player';
import * as ToDos from './factories/to-dos';
import * as Voting from './factories/voting';
import * as Kpi from './factories/kpi';
import { remote } from '../../remote';

type StoreType = ReturnType<typeof createRootSlice>;

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Avatar.create(set, get),
  ...Countdown.create(set, get),
  ...Docs.create(set, get),
  ...Game.create(set, get),
  ...Locations.create(set, get),
  ...Logger.create(set, get),
  ...Player.create(set, get),
  ...ToDos.create(set, get),
  ...Voting.create(remote)(set, get),
  ...Kpi.create(set, get),
});

const DEFAULT_STORE_NAME = 'hospitalia';

export const useGame: UseBoundStore<StoreType> &
  StoreApiWithPersist<StoreType> = create(
  persist(createRootSlice, {
    name: DEFAULT_STORE_NAME,

    onRehydrateStorage: state => {
      console.log('»» store hydration started');

      return (state, error) => {
        if (error) console.error('an error happened during hydration', error);
        else console.log('»» store hydration finished');
      };
    },

    getStorage: (): StateStorage => ({
      getItem(key: string) {
        return localStorage.getItem(key);
      },
      setItem(key: string, value: string) {
        if (key !== DEFAULT_STORE_NAME) localStorage.setItem(key, value);
      },
      removeItem(key) {
        return localStorage.removeItem(key);
      },
    }),
  })
);
