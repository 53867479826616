export default Object.freeze([
  {
    id: 'DirettoreSanitario',
    shortname: 'DS',
    iconName: 'Direttore Sanitario.svg',
  },
  {
    id: 'DirettoreFarmaciaOspedaliera',
    shortname: 'DF',
    iconName: 'Direttore Farmacia Ospedaliera.svg',
  },
  {
    id: 'DirettoreDipartimentoMedicinaInterna',
    shortname: 'DM',
    iconName: 'Direttore Dipartimento Medicina Interna.svg',
  },
  {
    id: 'DirettoreUOCPneumologia',
    shortname: 'DP',
    iconName: 'Direttore UOC Pneumologia.svg',
  },
  {
    id: 'ProvveditoreEconomo',
    shortname: 'PE',
    iconName: 'Provveditore Economo.svg',
  },
  {
    id: 'ClinicoReferenteFederAsma',
    shortname: 'CA',
    iconName: 'Clinico Referente FederAsma.svg',
  },
]);
