import useEvent from '@react-hook/event';
import { useState } from 'react';
import { useGame } from '../../../stores/game';
import { remote } from '../../../remote';
import { PhaseNames } from '../../../stores/game/concepts/game';
import { chat } from '../../../remote/chat';
import roles from '../../contents/roles';

const startGamePhase = async (gameId: string, phaseName: PhaseNames) => {
  await remote.startGamePhase(gameId, phaseName, Date.now());

  if (['phase-1', 'phase-2', 'phase-3'].includes(phaseName))
    await chat.sendMessage(gameId, phaseName, {
      timestamp: Date.now(),
      sender: '--divider--',
      content: phaseName,
    });
};

export const PhaseSelector = () => {
  const currentPhase = useGame(({ currentPhase }) => currentPhase);
  const gameId = useGame(({ gameId }) => gameId);
  const players = useGame(({ players }) => players);
  const isStarted = useGame(({ isStarted }) => isStarted);
  const meetingRooms = useGame(({ meetingRooms }) => meetingRooms);
  const startGame = useGame(({ start }) => start);
  const basecamp = useGame(({ basecampLocation }) => basecampLocation());
  const nextLocation = useGame(({ nextLocationToVisit }) =>
    nextLocationToVisit()
  );
  const isChatOpen = useGame(({ isChatOpen }) => isChatOpen);
  const setRoleMeetingRoom = useGame(
    ({ setRoleMeetingRoom }) => setRoleMeetingRoom
  );
  const team = useGame(({ team }) => team);

  const [isVisible, setIsVisible] = useState(
    process.env.ENVIRONMENT === 'development'
  );

  useEvent(window, 'keydown', ev => {
    switch (ev.key) {
      case '~':
        setIsVisible(s => !s);
    }
  });

  const [playerList, setPlayerList] = useState<string[]>(players);

  if (!isVisible) return null;

  return (
    <>
      <div id="events-debugger" className="pointer-events-none" />
      <div className="fixed z-50 flex flex-col p-4 space-y-1 font-mono text-xs text-white transition-all duration-200 ease-in-out rounded shadow-xl select-none left-10 bottom-10 bg-slate-600 bg-opacity-40 hover:bg-opacity-70 backdrop-filter backdrop-blur-md">
        <pre className="p-1 mb-2 border border-white ronded-lg">
          <div>is started: {isStarted.toString()}</div>
          <div>current phase: {currentPhase}</div>
          <div>basecamp: {basecamp}</div>
          <div>next location: {nextLocation}</div>
          <div>players: {players.join(', ')}</div>
          <div>
            is chat open: {isChatOpen.toString()}{' '}
            <button
              disabled={isChatOpen}
              onClick={() => remote.openChat(gameId)}
            >
              Open
            </button>{' '}
            <button
              disabled={!isChatOpen}
              onClick={() => remote.closeChat(gameId)}
            >
              Close
            </button>
          </div>
        </pre>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGame(Date.now())}
          >
            Start Game
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px mr-1 font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => remote.setPlayers(gameId, [...playerList])}
          >
            Update Players
          </button>
          <div className="my-1">
            <label className="mr-1 leading-none">Team</label>
            <p>{team}</p>{' '}
          </div>

          {roles.map(role => {
            const playerId = `${role.shortname}.${team}`;
            return (
              <div
                key={role.id}
                className="flex items-center my-1 hover:underline"
              >
                <input
                  className="cursor-pointer"
                  type="checkbox"
                  checked={new Set(playerList).has(playerId)}
                  onChange={e => {
                    const set = new Set(playerList);
                    if (e.target.checked) set.add(playerId);
                    else set.delete(playerId);
                    setPlayerList([...set]);
                  }}
                />
                <a
                  className="ml-1 leading-none"
                  target="_blank"
                  href={`/games/${gameId}:${playerId}`}
                >
                  {role.shortname}
                </a>
                <span className="ml-2">Room:</span>
                <input
                  type="text"
                  className="ml-2 text-blue-900"
                  value={meetingRooms[role.shortname] || ''}
                  onChange={e =>
                    setRoleMeetingRoom(role.shortname, e.target.value)
                  }
                />
              </div>
            );
          })}
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'intro')}
          >
            Intro
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'intro-2')}
          >
            Intro 2
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'phase-1')}
          >
            Phase 1
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'first-meeting')}
          >
            First Meeting
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'phase-1-voting')}
          >
            Phase 1 - Voting
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'phase-2')}
          >
            Phase 2
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'second-meeting')}
          >
            Second Meeting
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'phase-2-voting')}
          >
            Phase 2 - Voting
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'phase-3')}
          >
            Phase 3
          </button>
        </div>

        <div>
          <button
            className="px-2 py-px font-bold cursor-pointer hover:underline bg-white/20"
            onClick={() => startGamePhase(gameId, 'phase-3-voting')}
          >
            Phase 3 - Voting
          </button>
        </div>
      </div>
    </>
  );
};
