import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import {
  AllocationDecisionOptions,
  DesertTenderCostsDecisionOptions,
  DiagnosisDecisionOptions,
  DirectDistributionDecisionOptions,
  IKpi,
  toBudgetValue,
  toDeltaTreatments,
} from '../concepts/kpi';
import { IPlayer } from '../concepts/player';
import budgetAmountDecisionByRole from '../../../comps/contents/budget-amount-decision-by-role';
import { IVoting } from '../concepts/voting';

export const create: StoreSlice<IKpi, ILogger & IPlayer & IVoting> = (
  set,
  get
) => {
  return {
    /** Results */
    allocationDecisionResult: '',
    directDistributionDecisionResult: '',
    desertTenderCostsDecisionResult: '',
    diagnosisDecisionResult: '',

    /** Votes */
    availableBudgetVotes() {
      return budgetAmountDecisionByRole[get().role];
    },

    availableAllocationVotes() {
      if (get().budgetAmountResult() < 0)
        return ['DismissICS', 'ChangeOMABudget', 'ChangeMEPOBudget'];

      if (get().budgetAmountResult() === 0)
        return [
          'DoNothing',
          'MoveBudgetFromOMAtoMEPO',
          'MoveBudgetFromMEPOtoOMA',
        ];

      return [
        'IncreaseOMAandMEPOBudget',
        'ChangeOMABudget',
        'ChangeMEPOBudget',
      ];
    },

    availableDirectDistributionVotes() {
      return ['IncreaseDPCUtilization', 'ImproveWarehouseManagement'];
    },
    availableDesertTenderCostsVotes() {
      return ['ImproveSuppliersCompetition', 'CentralizePurchasing'];
    },
    availableDiagnosisVotes() {
      return ['ImproveWelfareContinuity', 'InvestOnPeopleSensitization'];
    },

    /** Decisions */
    budgetAmountResult() {
      const votes = get().readNotAbstainedVotes('phase-1');

      return (
        votes.map(toBudgetValue).reduce((sum, v) => (sum += v), 0) /
        votes.length
      );
    },

    allocationDecision() {
      return get().majority('phase-2', 'DS') as AllocationDecisionOptions;
    },

    directDistributionDecision() {
      return get().majority(
        'phase-3-distribution',
        'DS'
      ) as DirectDistributionDecisionOptions;
    },

    desertTenderCostsDecision() {
      return get().majority(
        'phase-3-tender',
        'DS'
      ) as DesertTenderCostsDecisionOptions;
    },

    diagnosisDecision() {
      return get().majority(
        'phase-3-diagnosis',
        'DS'
      ) as DiagnosisDecisionOptions;
    },

    /** Surveys */
    teamRepresentation() {
      return get().mean([
        'phase-1-survey-representation',
        'phase-2-survey-representation',
        'phase-3-survey-representation',
      ]);
    },
    teamSatisfaction() {
      return get().mean([
        'phase-1-survey-satisfaction',
        'phase-2-survey-satisfaction',
        'phase-3-survey-satisfaction',
      ]);
    },

    /** Feedback */
    feedback() {
      const score = [
        get().directDistributionDecision() === 'IncreaseDPCUtilization' ? 0 : 1,
        get().desertTenderCostsDecision() === 'ImproveSuppliersCompetition'
          ? 0
          : 1,
        get().diagnosisDecision() === 'ImproveWelfareContinuity' ? 0 : 1,
      ].reduce(toSum, 0);

      if (score >= 2) return 'MeMyselfAndI';
      return 'YouAreOpen';
    },

    /** Achievements */
    ownGoals() {
      switch (get().role) {
        case 'DS':
          return [
            'ReduceCostOfAsmaDrugs',
            'KeepAGoodOrganizationClimate',
            'ReduceAdmissionsDueToUnexpectedEvents',
          ];
        case 'DF':
          return [
            'ReduceCostOfAsmaDrugs',
            'ImproveWarehouseEfficacy',
            'ImproveCompliance',
          ];
        case 'DM':
          return [
            'ReduceCostOfAsmaDrugs',
            'ReduceAdmissionsDueToUnexpectedEvents',
            'ImprovePatientsWelfare',
          ];
        case 'DP':
          return [
            'IncreasePercentageOfPatientsTreatedWithBio',
            'ReduceAdmissionsDueToUnexpectedEvents',
            'ImproveCompliance',
          ];
        case 'PE':
          return [
            'ReduceCostOfAsmaDrugs',
            'ImproveTenderSpecificationWriting',
            'ReduceAdmissionsDueToUnexpectedEvents',
          ];
        case 'CA':
          return [
            'ImprovePeopleAwareness',
            'IncreaseNumberOfPediatricPatientsTreatedWithBio',
            'ImproveCompliance',
          ];
        default:
          return [];
      }
    },

    teamGoals() {
      return [
        {
          id: 'ReduceTotalDrugsCosts',
          isAchieved: get().budgetAmountResult() < 0,
        },
        {
          id: 'ReduceUnexpectedEventsRelatedCosts',
          isAchieved: get().unexpectedEventCostsDelta() < 0,
        },
        {
          id: 'IncreaseTotalCompliance',
          isAchieved: get().complianceDelta() > 0,
        },
      ];
    },

    personalGoals() {
      const isAchieved = (goal: string) => {
        switch (goal) {
          case 'ReduceCostOfAsmaDrugs':
            return get().budgetAmountResult() < 0;
          case 'KeepAGoodOrganizationClimate':
            return (
              (get().teamRepresentation() + get().teamSatisfaction()) / 2 >= 0.6
            );
          case 'ReduceAdmissionsDueToUnexpectedEvents':
            return get().unexpectedEventCostsDelta() < 0;
          case 'ImproveWarehouseEfficacy':
            return (
              get().directDistributionDecision() ===
              'ImproveWarehouseManagement'
            );
          case 'ImproveCompliance':
            return get().complianceDelta() > 0;
          case 'ImproveTenderSpecificationWriting':
            return (
              get().desertTenderCostsDecision() ===
              'ImproveSuppliersCompetition'
            );
          case 'ImprovePatientsWelfare':
            return get().diagnosisDecision() === 'ImproveWelfareContinuity';
          case 'ImprovePeopleAwareness':
            return get().diagnosisDecision() === 'InvestOnPeopleSensitization';
          case 'IncreaseNumberOfPediatricPatientsTreatedWithBio':
            return get().numberOfPediatricPatientsTreatedWithBioDelta() > 0;
          case 'IncreasePercentageOfPatientsTreatedWithBio':
            return get().percentageOfPatientsTreatedWithBioDelta() > 0;
          default:
            return true;
        }
      };
      return get()
        .ownGoals()
        .map((id: string) => ({
          id,
          isAchieved: isAchieved(id),
        }));
    },

    /** year 0 */

    complianceYear0() {
      return 0.2549;
    },
    unexpectedEventCostsYear0() {
      return 2.728755e6;
    },
    totalDrugsCostsYear0() {
      return 10.68e6;
    },
    totalOMACostYear0() {
      return costPerPatientOMA * get().numberOfPatientsTreatedWithOMAYear0();
    },
    totalMEPOCostYear0() {
      return costPerPatientMEPO * get().numberOfPatientsTreatedWithMEPOYear0();
    },

    numberOfPatientsTreatedWithOMAYear0() {
      return 960;
    },

    numberOfPatientsTreatedWithICSYear0() {
      return 800;
    },

    numberOfPatientsTreatedWithMEPOYear0() {
      return 240;
    },

    numberOfPatientsTreatedYear0() {
      return (
        get().numberOfPatientsTreatedWithOMAYear0() +
        get().numberOfPatientsTreatedWithICSYear0() +
        get().numberOfPatientsTreatedWithMEPOYear0()
      );
    },

    numberOfAdultPatientsTreatedWithOMAYear0() {
      return 121;
    },

    numberOfAllergicPatientsTreatedWithOMAYear0() {
      return 600;
    },

    numberOfPediatricPatientsTreatedWithOMAYear0() {
      return 239;
    },

    percentageOfPatientsTreatedWithBioYear0() {
      return 0.6;
    },

    adultPatientsTreatedWithOMAComplianceYear0() {
      return 0.26;
    },

    pediatricPatientsTreatedWithOMAComplianceYear0() {
      return 0.4;
    },

    allergicPatientsTreatedWithOMAComplianceYear0() {
      return 0.35;
    },

    /** year 1 */

    patientsVariation() {
      return {
        changeOMAPatientDelta:
          get().budgetAmountResult() /
          (costPerPatientOMA - costPerUnexpectedEventOMA),
        changeICSPatientDelta:
          get().budgetAmountResult() / costPerUnexpectedEventICS,
        changeMEPOPatientDelta:
          get().budgetAmountResult() /
          (costPerPatientMEPO - costPerUnexpectedEventMEPO),
      };
    },

    treatmentsVariation() {
      return toDeltaTreatments(get().allocationDecision())(
        get().patientsVariation()
      );
    },

    omaTreatmentsVariation() {
      return get().treatmentsVariation().oma;
    },

    mepoTreatmentsVariation() {
      return get().treatmentsVariation().mepo;
    },

    icsTreatmentsVariation() {
      return get().treatmentsVariation().ics;
    },

    numberOfPatientsTreatedWithOMAYear1() {
      return (
        get().numberOfPatientsTreatedWithOMAYear0() +
        get().omaTreatmentsVariation()
      );
    },

    numberOfPatientsTreatedWithMEPOYear1() {
      return (
        get().numberOfPatientsTreatedWithMEPOYear0() +
        get().mepoTreatmentsVariation()
      );
    },

    totalOMACostYear1() {
      return get().numberOfPatientsTreatedWithOMAYear1() * costPerPatientOMA;
    },

    totalMEPOCostYear1() {
      return get().numberOfPatientsTreatedWithMEPOYear1() * costPerPatientMEPO;
    },

    numberOfPatientsTreatedWithICSYear1() {
      return (
        get().numberOfPatientsTreatedWithICSYear0() +
        get().icsTreatmentsVariation()
      );
    },

    numberOfPatientsTreatedWithBioYear1() {
      return (
        get().numberOfPatientsTreatedWithOMAYear1() +
        get().numberOfPatientsTreatedWithMEPOYear1()
      );
    },

    numberOfPatientsTreatedYear1() {
      return (
        get().numberOfPatientsTreatedWithICSYear1() +
        get().numberOfPatientsTreatedWithBioYear1()
      );
    },

    numberOfAdultPatientsTreatedWithOMAYear1() {
      if (get().allocationDecision() === 'MoveBudgetFromOMAtoMEPO') return 0;

      if (get().allocationDecision() === 'ChangeMEPOBudget')
        return get().numberOfAdultPatientsTreatedWithOMAYear0();

      return (
        get().percentageOfAdultPatientsTreatedWithOMAYear1() *
        get().numberOfPatientsTreatedWithOMAYear1()
      );
    },

    numberOfAllergicPatientsTreatedWithOMAYear1() {
      if (
        get().allocationDecision() === 'MoveBudgetFromOMAtoMEPO' ||
        get().allocationDecision() === 'ChangeMEPOBudget'
      )
        return get().numberOfAllergicPatientsTreatedWithOMAYear0();

      return (
        get().percentageOfAllergicPatientsTreatedWithOMAYear1() *
        get().numberOfPatientsTreatedWithOMAYear1()
      );
    },

    // HERE?
    numberOfPediatricPatientsTreatedWithOMAYear1() {
      if (
        get().allocationDecision() === 'MoveBudgetFromOMAtoMEPO' ||
        get().allocationDecision() === 'ChangeMEPOBudget'
      )
        return get().numberOfPediatricPatientsTreatedWithOMAYear0();

      return (
        get().percentageOfPediatricPatientsTreatedWithOMAYear1() *
        get().numberOfPatientsTreatedWithOMAYear1()
      );
    },

    percentageOfPatientsTreatedWithICSYear1() {
      return (
        get().numberOfPatientsTreatedWithICSYear1() /
        get().numberOfPatientsTreatedYear1()
      );
    },

    percentageOfPatientsTreatedWithBioYear1() {
      return 1 - get().percentageOfPatientsTreatedWithICSYear1();
    },

    percentageOfPatientsTreatedWithOMAYear1() {
      return (
        get().numberOfPatientsTreatedWithOMAYear1() /
        get().numberOfPatientsTreatedWithBioYear1()
      );
    },

    percentageOfPatientsTreatedWithMEPOYear1() {
      return 1 - get().percentageOfPatientsTreatedWithOMAYear1();
    },

    adultPatientsTreatedWithOMAComplianceYear1() {
      return get().adultPatientsTreatedWithOMAComplianceYear0();
    },

    pediatricPatientsTreatedWithOMAComplianceYear1() {
      return get().pediatricPatientsTreatedWithOMAComplianceYear0();
    },

    allergicPatientsTreatedWithOMAComplianceYear1() {
      return get().allergicPatientsTreatedWithOMAComplianceYear0();
    },

    percentageOfAdultPatientsTreatedWithOMAYear1() {
      if (get().allocationDecision() === 'MoveBudgetFromOMAtoMEPO')
        return (
          get().numberOfAdultPatientsTreatedWithOMAYear1() /
          get().numberOfPatientsTreatedWithOMAYear1()
        );

      return (
        1 -
        get().percentageOfPediatricPatientsTreatedWithOMAYear1() -
        get().percentageOfAllergicPatientsTreatedWithOMAYear1()
      );
    },

    percentageOfPediatricPatientsTreatedWithOMAYear1() {
      if (get().allocationDecision() === 'MoveBudgetFromOMAtoMEPO')
        return (
          get().numberOfPediatricPatientsTreatedWithOMAYear1() /
          get().numberOfPatientsTreatedWithOMAYear1()
        );

      return (
        398 /
        get().numberOfPatientsTreatedYear1() /
        get().percentageOfPatientsTreatedWithOMAYear1()
      );
    },

    percentageOfAllergicPatientsTreatedWithOMAYear1() {
      if (get().allocationDecision() === 'MoveBudgetFromOMAtoMEPO')
        return (
          get().numberOfAllergicPatientsTreatedWithOMAYear1() /
          get().numberOfPatientsTreatedWithOMAYear1()
        );

      return (
        1000 /
        get().numberOfPatientsTreatedYear1() /
        get().percentageOfPatientsTreatedWithOMAYear1()
      );
    },

    mepoComplianceYear1() {
      return 0.26;
    },

    omaComplianceYear1() {
      return (
        get().percentageOfAdultPatientsTreatedWithOMAYear1() *
          get().adultPatientsTreatedWithOMAComplianceYear1() +
        get().percentageOfPediatricPatientsTreatedWithOMAYear1() *
          get().pediatricPatientsTreatedWithOMAComplianceYear1() +
        get().percentageOfAllergicPatientsTreatedWithOMAYear1() *
          get().allergicPatientsTreatedWithOMAComplianceYear1()
      );
    },

    bioComplianceYear1() {
      return (
        get().percentageOfPatientsTreatedWithOMAYear1() *
          get().omaComplianceYear1() +
        get().percentageOfPatientsTreatedWithMEPOYear1() *
          get().mepoComplianceYear1()
      );
    },

    nonBioComplianceYear1() {
      return 0.138;
    },

    complianceYear1() {
      return (
        get().percentageOfPatientsTreatedWithICSYear1() *
          get().nonBioComplianceYear1() +
        get().percentageOfPatientsTreatedWithBioYear1() *
          get().bioComplianceYear1()
      );
    },
    unexpectedEventCostsYear1() {
      return (
        get().numberOfPatientsTreatedWithICSYear1() *
          costPerUnexpectedEventICS +
        get().numberOfPatientsTreatedWithBioYear1() * costPerUnexpectedEventBio
      );
    },

    totalDrugsCostsYear1() {
      return get().totalDrugsCostsYear0() + get().budgetAmountResult();
    },

    /** Deltas */
    complianceDelta() {
      return get().complianceYear1() - get().complianceYear0();
    },

    unexpectedEventCostsDelta() {
      return (
        get().unexpectedEventCostsYear1() - get().unexpectedEventCostsYear0()
      );
    },

    totalDrugsCostsDelta() {
      return get().totalDrugsCostsYear1() - get().totalDrugsCostsYear0();
    },

    numberOfPatientsTreatedDelta() {
      return (
        get().numberOfPatientsTreatedYear1() -
        get().numberOfPatientsTreatedYear0()
      );
    },

    percentageOfPatientsTreatedWithBioDelta() {
      return (
        get().percentageOfPatientsTreatedWithBioYear1() -
        get().percentageOfPatientsTreatedWithBioYear0()
      );
    },

    numberOfPediatricPatientsTreatedWithBioDelta() {
      return (
        get().numberOfPediatricPatientsTreatedWithOMAYear1() -
        get().numberOfPediatricPatientsTreatedWithOMAYear0()
      );
    },
  };
};

const costPerPatientOMA = 9_000;
const costPerPatientMEPO = 8_500;
const costPerUnexpectedEventICS = 1_957.5;
const costPerUnexpectedEventBio = 968.9625;
const costPerUnexpectedEventMEPO = costPerUnexpectedEventBio;
const costPerUnexpectedEventOMA = costPerUnexpectedEventBio;

const toSum = (s: number, a: number) => s + a;
