import numeral from 'numeral';
import { Duration } from 'luxon';

numeral.register('locale', 'it', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return '';
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
numeral.locale('it');

export const timer = (v: number) =>
  `${v < 0 ? '+' : '-'} ${Duration.fromMillis(Math.abs(v)).toFormat('mm:ss')}`;

export const amount = (v: number) => Number(numeral(v).format('0,0.00'));
export const price = (v: number) => numeral(v).format('0,0 €');
export const priceInK = (v: number) => numeral(v).format('$ 0,0.0 a');
export const taskCost = (v: number) => numeral(v).format('$ 0,0');
export const fixedCost = (v: number) => numeral(v).format('$ 0,0.00 a');
export const revenues = (v: number) => numeral(v).format('$ 0,0.00 a');
export const balance = (v: number) => numeral(v).format('$ 0,0.00 a');
export const percent = (v: number) => numeral(v).format('0 %');
export const units = (v: number) => numeral(v).format('0,0');
export const level = (v: number) => numeral(v).format('0');
export const param = (v: number) => numeral(v).format('0.00');
