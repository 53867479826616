import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import produce from 'immer';
import { IDocs } from '../concepts/docs';

export const create: StoreSlice<IDocs, ILogger> = (set, get) => {
  return {
    collectedDocs: [],
    readDocs: [],

    readDoc(docId) {
      set(
        produce((s: IDocs) => {
          if (s.readDocs.includes(docId)) return s;
          s.readDocs.push(docId);
        })
      );
    },

    collectDoc(docId) {
      set(
        produce((s: IDocs) => {
          if (s.collectedDocs.includes(docId)) return s;
          s.collectedDocs.push(docId);
        })
      );
    },

    isDocRead(docId) {
      return get().readDocs.includes(docId);
    },

    unreadDocsCount() {
      return get().collectedDocs.filter(doc => !get().isDocRead(doc)).length;
    },

    areAllDocsRead() {
      return get().collectedDocs.every(get().isDocRead);
    },
  };
};
