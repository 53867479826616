import { PhaseExecutorMap } from '../game';
import { startIntro } from './01.intro';
import { startIntro2 } from './02.intro-2';
import { firstMeeting } from './04.first-meeting';
import { startPhase1 } from './03.phase-1';
import { startPhase1Voting } from './05.phase-1-voting';
import { startPhase1Outcome } from './06.phase-1-outcome';
import { startPhase2 } from './07.phase-2';
import { secondMeeting } from './08.second-meeting';
import { startPhase2Voting } from './09.phase-2-voting';
import { startPhase2Outcome } from './10.phase-2-outcome';
import { startPhase3 } from './11.phase-3';
import { startPhase3Voting } from './12.phase-3-voting';
import { startPhase3Outcome } from './13.phase-3-outcome';
import { startConclusions } from './14.conclusions';
import { startPhase1End } from './07.phase-1-end';
import { startPhase2End } from './11.phase-2-end';

export const executors: PhaseExecutorMap = {
  init: () => {},
  intro: startIntro,
  'intro-2': startIntro2,
  'phase-1': startPhase1,
  'first-meeting': firstMeeting,
  'phase-1-voting': startPhase1Voting,
  'phase-1-outcomes': startPhase1Outcome,
  'phase-1-end': startPhase1End,
  'phase-2': startPhase2,
  'second-meeting': secondMeeting,
  'phase-2-voting': startPhase2Voting,
  'phase-2-outcomes': startPhase2Outcome,
  'phase-2-end': startPhase2End,
  'phase-3': startPhase3,
  'phase-3-voting': startPhase3Voting,
  'phase-3-outcomes': startPhase3Outcome,
  conclusions: startConclusions,
};
