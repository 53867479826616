import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';

export const render = (host: HTMLElement) =>
  createRoot(host).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
