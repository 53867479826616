import { useGame } from '../../../stores/game';

export const NavPlayerInfo = () => {
  const team = useGame(({ team }) => team);
  const role = useGame(({ role }) => role);

  return (
    <div className="flex flex-col items-center justify-center w-full py-2 mb-0 leading-none text-center uppercase bg-white lg:py-4 xl:py-6 2xl:py-8">
      <p className="text-neutral-500 text-xxs xl:text-xs">Benvenuto</p>
      <p className="font-bold text-xxs xl:text-base 2xl:text-lg text-hospitaliaGold-base">
        {team} - {role}
      </p>
    </div>
  );
};
