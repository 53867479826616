import { LearningLabLogo } from '../assets/llab-logo';
import { SDABocconiLogo } from '../assets/sdabocconi-logo';
import { HomeHeader } from './home-header';
import { MainLayoutWithTheme } from './main-with-theme';

export const LandingScreenLayout: React.FC<{
  children: React.ReactNode;
}> = props => (
  <MainLayoutWithTheme primaryColor="red">
    <HomeHeader variant="landing">
      <SDABocconiLogo />
      <LearningLabLogo variant="landing" />
      <span />
    </HomeHeader>

    <div className="h-full overflow-y-auto">{props.children}</div>
  </MainLayoutWithTheme>
);
