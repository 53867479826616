import { useIntl } from 'react-intl';
import { PlayTimeScreen } from './play-time-screen';
import SectionTitle from '../game/section-title';
import { dictionaryMapper } from '../utils/dictionary-mapper';

export const IntroMandateScreen = () => {
  const { formatMessage } = useIntl();

  return (
    <PlayTimeScreen>
      <SectionTitle
        label={formatMessage({ id: 'pages:intro-mandate.title' })}
      />

      <div className="px-10 py-2 lg:py-4 xl:py-8 lg:px-32 xl:px-64">
        <div className="text-base xl:text-xl">
          {formatMessage({ id: 'pages:mandate.content' }, dictionaryMapper)}
        </div>
      </div>
    </PlayTimeScreen>
  );
};
