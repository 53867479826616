import { useGame } from '../../stores/game';
import { PlayTimeScreen } from './play-time-screen';
import Phase1Outcomes from '../game/outcomes/phase-1.outcome';
import Phase2Outcomes from '../game/outcomes/phase-2.outcome';
import Phase3Outcomes from '../game/outcomes/phase-3.outcome';

export const PhaseOutcomeScreen = () => {
  const currentPhase = useGame(({ currentPhase }) => currentPhase);

  return (
    <PlayTimeScreen>
      {currentPhase === 'phase-1-outcomes' ? (
        <Phase1Outcomes />
      ) : currentPhase === 'phase-2-outcomes' ? (
        <Phase2Outcomes />
      ) : currentPhase === 'phase-3-outcomes' ? (
        <Phase3Outcomes />
      ) : null}
    </PlayTimeScreen>
  );
};
