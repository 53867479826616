import shallow from 'zustand/shallow';
import CountUp from 'react-countup';
import { useIntl } from 'react-intl';
import { useGame } from '../../../stores/game';
import getPhaseColor from '../../../utils/get-phase-color';
import { motion } from 'framer-motion';
import TextRevealer from '../text-revealer';

const Phase2Scenario = () => {
  const { formatMessage } = useIntl();

  const { totalDrugsCostsYear0, totalDrugsCostsDelta, totalDrugsCostsYear1 } =
    useGame(
      ({
        totalDrugsCostsYear0,
        totalDrugsCostsDelta,
        totalDrugsCostsYear1,
      }) => ({
        totalDrugsCostsYear0: totalDrugsCostsYear0(),
        totalDrugsCostsDelta: totalDrugsCostsDelta(),
        totalDrugsCostsYear1: totalDrugsCostsYear1(),
      }),
      shallow
    );

  return (
    <div className="w-full h-[90%]">
      <div
        className="w-1/2 py-1 pr-8 mt-4 mb-5 text-right xl:py-2 rounded-r-xl xl:w-1/3 xl:mt-8 lg:mb-6 xl:mb-8 2xl:mb-10 bg-opacity-80"
        style={{ backgroundColor: getPhaseColor('phase-2') }}
      >
        <p className="text-xs font-bold leading-none text-white uppercase md:leading-none lg:leading-none xl:leading-none lg:text-sm xl:text-base 2xl:text-lg">
          {formatMessage({ id: 'pages:phases-phase-2.title' })}
        </p>
      </div>

      <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-base xl:text-lg 2xl:text-xl leading-tight`"
        >
          <TextRevealer
            label={formatMessage({ id: 'pages:scenarios-phase-2.content' })}
          />
        </motion.p>

        <motion.table
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.9 }}
          className="min-w-full mt-2 divide-y divide-gray-300 xl:mt-3 2xl:mt-4"
        >
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2 text-lg text-gray-500 whitespace-nowrap lg:py-3 xl:py-4">
                {' '}
              </td>
              <td className="px-3 py-2 text-base text-right text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 2xl:text-lg">
                Anno precedente
              </td>
              <td className="px-3 py-2 text-base text-right text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 2xl:text-lg">
                Variazione
              </td>
              <td className="px-3 py-2 text-base text-right text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 2xl:text-lg">
                Anno prossimo
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg">
                {formatMessage({
                  id: 'vars:spesa-totale-farmaci.label',
                })}
              </td>
              <td className="py-2 pl-4 pr-3 text-sm font-medium text-right text-gray-900 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalDrugsCostsYear0}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
              <td className="py-2 pl-4 pr-3 text-sm font-medium text-right text-gray-900 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalDrugsCostsDelta}
                  decimals={0}
                  separator="."
                  prefix={
                    totalDrugsCostsDelta === 0
                      ? ''
                      : totalDrugsCostsDelta > 0
                      ? '+'
                      : ''
                  }
                />{' '}
                €
              </td>
              <td className="py-2 pl-4 pr-3 text-sm font-medium text-right text-gray-900 whitespace-nowrap lg:py-3 xl:py-4 lg:text-base xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalDrugsCostsYear1}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
            </tr>
          </tbody>
        </motion.table>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 5.8 }}
          className="mt-5 text-base leading-tight lg:text-lg lg:leading-tight xl:leading-tight 2xl:leading-tight xl:text-xl 2xl:text-2xl xl:mt-10 2xl:mt-16"
        >
          Come penseresti di far variare o rimodulare l'adozione di farmaci
          biologici per il prossimo anno in funzione della variazione decisa dal
          tuo team?
        </motion.p>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 6.6 }}
          className="mt-10 text-sm font-bold leading-tight lg:text-base xl:text-lg 2xl:text-xl lg:leading-tight xl:leading-tight 2xl:leading-tight xl:mt-12 2xl:mt-20"
        >
          Ad esempio: aumento/diminuzione di uno dei biologici, spostamento del
          budget su uno dei due biologici. Prima di prendere una decisione ti
          consigliamo di rileggere il documento "L'Asma: contenuti e
          definizione" nell'archivio documenti.
        </motion.p>
      </div>
    </div>
  );
};

export default Phase2Scenario;
