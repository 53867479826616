import { GetState } from 'zustand';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IToDos } from '../../concepts/to-dos';
import { IPhaseExecutorFn } from '../game';
import roles from '../../../../comps/contents/roles';

export const startIntro2: IPhaseExecutorFn = (
  get: GetState<IGame & IDocs & IToDos & ILocations>
) => {
  get().closeLocation('/');
  get().closeLocation('avatar');

  get().openLocation('profiles');
  get().openLocationForever('profiles');

  roles.forEach(r => get().openLocation(`profiles/${r.shortname}`));
  roles.forEach(r => get().openLocationForever(`profiles/${r.shortname}`));

  get().openLocation('team-goals');
  get().openLocationForever('team-goals');

  get().collectDoc('AsmaGrave');
  get().collectDoc('LetteraDG');

  get().clearToDos();
  ToDos.forEach(get().appendToDo);
};

const ToDos = [
  {
    id: 'read-profiles',
    type: 'read-info',
    locationToVisit: 'profiles',
    payload: null,
    nudge: '',
  },
  ...roles.map(role => ({
    id: 'read-profile-' + role.shortname,
    type: 'read-info',
    locationToVisit: 'profiles/' + role.shortname,
    payload: null,
    nudge: '',
  })),
  {
    id: 'read-goals',
    type: 'read-info',
    locationToVisit: 'team-goals',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-all-docs',
    type: 'read-all-docs',
    locationToVisit: 'docs',
    payload: null,
    nudge: 'Leggi tutti i documenti prima di proseguire',
  },
];
