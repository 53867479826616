import { StoreSlice } from '../../utils/store-slice';
import produce from 'immer';
import { IChat, Message } from '../concept/chat';
import { ILogger } from '../concept/logger';
import { Unsubscribe } from 'firebase/firestore';
import { IRemoteChat, MessageDoc } from '../../../remote/apis/chat';
import { GetState } from 'zustand';

interface IPrivate {
  unsub: Unsubscribe;
  appendMessage(doc: MessageDoc): void;
}

export const create =
  (remote: IRemoteChat): StoreSlice<IChat & IPrivate, ILogger> =>
  (set, get) => {
    return {
      unsub: () => {},
      appendMessage(doc) {
        set(
          produce((s: IChat) => {
            const message = s.messages.find(withMessageId(doc.messageId));
            if (message) {
              Object.assign(message, docToMessage(get)(doc));
            } else {
              s.messages.push(docToMessage(get)(doc));
            }
          })
        );
      },

      channelId: '',
      sender: '',
      messages: [],

      init(channelId: string, sender: string) {
        get().unsub();
        set({
          channelId,
          sender,
          messages: [],
          unsub: remote.onMessageSnapshot(channelId, get().appendMessage),
        });
      },

      sendMessage(phase: string, content: string) {
        remote.sendMessage(get().channelId, phase, {
          content,
          sender: get().sender,
          timestamp: Date.now(),
        });
      },

      markAsRead(messageId: string) {
        remote.markMessageAsRead(get().channelId, messageId, get().sender);
      },
    };
  };

const withMessageId = (id: string) => (target: { messageId: string }) =>
  target.messageId === id;

const docToMessage =
  (get: GetState<IChat>) =>
  (doc: MessageDoc): Message => ({
    channelId: doc.channelId,
    content: doc.content,
    isRead: doc.viewers.includes(get().sender),
    messageId: doc.messageId,
    sender: doc.sender,
    phase: doc.phase,
    timestamp: doc.timestamp,
    viewers: doc.viewers,
  });
