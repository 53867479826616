import { useRoute } from 'wouter';
import { LandingScreenLayout } from '../layout/screen.landing';
import { useTheme } from '../../stores/theme';
import { WelcomeMessage } from '../game/welcome-message';
import { ContinueGameButton } from '../game/continue-button';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';

export const LandingScreen = () => {
  const [match, params] = useRoute<{ gamePlayId: string }>('/:gamePlayId');
  const images = useTheme(({ images }) => images);

  if (!match)
    return <span>This component is not loaded under the correct path.</span>;

  return (
    <LandingScreenLayout>
      <div
        className="flex flex-col items-center justify-center h-full gap-10 p-4 bg-center bg-cover select-none"
        style={{
          backgroundImage: `url(${images(
            buildOptimizedAssetPath('home_bg', ['avif', 'webp'])
          )}`,
        }}
      >
        <WelcomeMessage gamePlayId={params.gamePlayId} />
        <ContinueGameButton gamePlayId={params.gamePlayId} />
      </div>
    </LandingScreenLayout>
  );
};
