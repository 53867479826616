import { useEffect, useState } from 'react';
import { useRoute } from 'wouter';
import { useIntl } from 'react-intl';
import { useGame } from '../../stores/game';
import { useTheme } from '../../stores/theme';
import roles from '../contents/roles';
import SectionTitle from '../game/section-title';
import { dictionaryMapper } from '../utils/dictionary-mapper';
import { PlayTimeScreen } from './play-time-screen';
import { Goal } from '../game/goal';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import ServiceScreen from '../service-screen';

export const ProfileScreen = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isMyProfilePresentation, setIsMyProfilePresentation] =
    useState<boolean>(false);

  const images = useTheme(s => s.images);
  const { formatMessage } = useIntl();
  const [_match, params] = useRoute<{ gamePlayId: string; profileId: string }>(
    '/:gamePlayId/profiles/:profileId'
  );
  const playerFromGamePlayId = useGame(
    ({ playerFromGamePlayId }) => playerFromGamePlayId
  );
  const avatars = useGame(({ avatars }) => avatars);
  const team = useGame(({ team }) => team);
  const ownGoals = useGame(g => g.ownGoals());
  const role = roles.find(({ shortname }) => shortname === params.profileId);
  const avatar =
    avatars[`${role.shortname}.${team}`] &&
    images(`avatars/${avatars[`${role.shortname}.${team}`]}.svg`);

  useEffect(() => {
    const init = async () => {
      try {
        const playerData = await playerFromGamePlayId(params.gamePlayId);

        setIsMyProfilePresentation(playerData.role === role.shortname);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoading) init();
  }, [playerFromGamePlayId, isLoading]);

  if (isLoading) return <ServiceScreen text="Loading..." />;

  return (
    <PlayTimeScreen>
      <SectionTitle
        label={formatMessage({
          id: isMyProfilePresentation
            ? 'pages:avatar-selection.title'
            : 'pages:profiles.title',
        })}
      />
      <div className="w-4/5 m-auto">
        <div className="flex flex-row items-center gap-2 mb-3 xl:gap-4 xl:mb-8">
          <span className="relative w-12 bg-white border-2 rounded-full aspect-square xl:border-4 border-neutral-200 xl:w-16">
            <img
              src={
                avatar ||
                images(
                  buildOptimizedAssetPath('avatars/missing', ['avif', 'png'])
                )
              }
              alt={role.id}
              className="w-full rounded-full aspect-square"
            />

            {!avatar && (
              <span className="absolute px-4 py-1 text-xs font-bold text-center text-white uppercase scale-75 -rotate-45 bg-red-400 rounded transform-gpu xl:scale-100 -top-1 -left-10">
                Assente
              </span>
            )}
          </span>
          <div>
            <h2
              className="inline text-lg font-bold uppercase bg-no-repeat lg:text-xl xl:text-3xl text-hospitaliaGold-base bg"
              style={{
                backgroundImage:
                  'linear-gradient(rgba(248, 179, 75, 0.2), rgba(248, 179, 75, 0.2))',
                backgroundPosition: '0% 100%',
                backgroundSize: '100% 0.2em',
              }}
            >
              {formatMessage({ id: `profiles:${role.id}.name` })}
            </h2>
            <h4 className="text-base xl:text-xl">
              {formatMessage({ id: `profiles:${role.id}.title` })}
            </h4>
          </div>
        </div>
        <div className="mb-4 text-xs lg:text-sm xl:text-lg">
          {formatMessage(
            {
              id: `profiles:${role.id}.${
                isMyProfilePresentation ? 'long' : 'short'
              }-description`,
            },
            dictionaryMapper
          )}
        </div>
      </div>
      {isMyProfilePresentation && (
        <>
          <SectionTitle
            label={formatMessage({ id: 'pages:my-profile-goals.title' })}
          />
          <div className="flex w-4/5 m-auto text-center item-start justify-evenly">
            {ownGoals.map((goal: string) => (
              <Goal key={goal} goal={goal} className="w-1/3" />
            ))}
          </div>
        </>
      )}
    </PlayTimeScreen>
  );
};
