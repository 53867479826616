interface IProps {
  label: string;
  color?: string;
}

const SectionTitle = ({ label, color }: IProps) => (
  <div
    className="pr-8 py-1 xl:py-2 rounded-r-xl bg-hospitaliaGold-base w-1/2 xl:w-1/3 mt-4 xl:mt-8 mb-5 xl:mb-10 bg-opacity-80 text-right"
    style={{ backgroundColor: color }}
  >
    <p className="leading-none md:leading-none lg:leading-none xl:leading-none font-bold uppercase text-xs md:text-sm lg:text-sm xl:text-base 2xl:text-lg text-white">
      {label}
    </p>
  </div>
);

export default SectionTitle;
