export interface IKpi {
  availableBudgetVotes(): BudgetDecisionOptions[];
  availableAllocationVotes(): AllocationDecisionOptions[];
  availableDirectDistributionVotes(): DirectDistributionDecisionOptions[];
  availableDesertTenderCostsVotes(): DesertTenderCostsDecisionOptions[];
  availableDiagnosisVotes(): DiagnosisDecisionOptions[];

  budgetAmountResult(): number;
  allocationDecision(): AllocationDecisionOptions;
  allocationDecisionResult: string;
  directDistributionDecision(): DirectDistributionDecisionOptions;
  directDistributionDecisionResult: string;
  desertTenderCostsDecision(): DesertTenderCostsDecisionOptions;
  desertTenderCostsDecisionResult: string;
  diagnosisDecision(): DiagnosisDecisionOptions;
  diagnosisDecisionResult: string;

  feedback(): Feedbacks;
  teamRepresentation(): number;
  teamSatisfaction(): number;
  ownGoals(): string[];
  teamGoals(): Goal[];
  personalGoals(): Goal[];

  complianceYear0(): number;
  unexpectedEventCostsYear0(): number;
  totalDrugsCostsYear0(): number;
  totalOMACostYear0(): number;
  totalMEPOCostYear0(): number;
  numberOfPatientsTreatedWithOMAYear0(): number;
  numberOfPatientsTreatedWithICSYear0(): number;
  numberOfPatientsTreatedWithMEPOYear0(): number;
  numberOfPatientsTreatedYear0(): number;
  numberOfAdultPatientsTreatedWithOMAYear0(): number;
  numberOfAllergicPatientsTreatedWithOMAYear0(): number;
  numberOfPediatricPatientsTreatedWithOMAYear0(): number;
  percentageOfPatientsTreatedWithBioYear0(): number;

  adultPatientsTreatedWithOMAComplianceYear0(): number;
  pediatricPatientsTreatedWithOMAComplianceYear0(): number;
  allergicPatientsTreatedWithOMAComplianceYear0(): number;

  patientsVariation(): PatientsVariation;
  treatmentsVariation(): DeltaTreatments;
  omaTreatmentsVariation(): number;
  mepoTreatmentsVariation(): number;
  icsTreatmentsVariation(): number;
  totalOMACostYear1(): number;
  totalMEPOCostYear1(): number;

  numberOfPatientsTreatedWithOMAYear1(): number;
  numberOfPatientsTreatedWithMEPOYear1(): number;
  numberOfPatientsTreatedWithICSYear1(): number;
  numberOfPatientsTreatedWithBioYear1(): number;
  numberOfPatientsTreatedYear1(): number;
  numberOfAdultPatientsTreatedWithOMAYear1(): number;
  numberOfAllergicPatientsTreatedWithOMAYear1(): number;
  numberOfPediatricPatientsTreatedWithOMAYear1(): number;

  percentageOfPatientsTreatedWithICSYear1(): number;
  percentageOfPatientsTreatedWithBioYear1(): number;
  percentageOfPatientsTreatedWithOMAYear1(): number;
  percentageOfPatientsTreatedWithMEPOYear1(): number;

  adultPatientsTreatedWithOMAComplianceYear1(): number;
  pediatricPatientsTreatedWithOMAComplianceYear1(): number;
  allergicPatientsTreatedWithOMAComplianceYear1(): number;
  percentageOfAdultPatientsTreatedWithOMAYear1(): number;
  percentageOfPediatricPatientsTreatedWithOMAYear1(): number;
  percentageOfAllergicPatientsTreatedWithOMAYear1(): number;

  mepoComplianceYear1(): number;
  omaComplianceYear1(): number;
  bioComplianceYear1(): number;
  nonBioComplianceYear1(): number;
  complianceYear1(): number;

  complianceDelta(): number;
  unexpectedEventCostsYear1(): number;
  unexpectedEventCostsDelta(): number;
  totalDrugsCostsYear1(): number;
  totalDrugsCostsDelta(): number;
  numberOfPatientsTreatedDelta(): number;
  percentageOfPatientsTreatedWithBioDelta(): number;
  numberOfPediatricPatientsTreatedWithBioDelta(): number;
}

export type AllocationDecisionOptions =
  | 'DismissICS'
  | 'ChangeOMABudget'
  | 'ChangeMEPOBudget'
  | 'DoNothing'
  | 'MoveBudgetFromOMAtoMEPO'
  | 'MoveBudgetFromMEPOtoOMA'
  | 'IncreaseOMAandMEPOBudget';

export type DirectDistributionDecisionOptions =
  | 'IncreaseDPCUtilization'
  | 'ImproveWarehouseManagement';

export type DesertTenderCostsDecisionOptions =
  | 'ImproveSuppliersCompetition'
  | 'CentralizePurchasing';

export type DiagnosisDecisionOptions =
  | 'ImproveWelfareContinuity'
  | 'InvestOnPeopleSensitization';

export type BudgetDecisionOptions =
  | 'IncreaseBudgetFor3M'
  | 'IncreaseBudgetFor1MAnd500K'
  | 'IncreaseBudgetFor1M'
  | 'IncreaseBudgetFor500K'
  | 'DontChangeBudget'
  | 'DecreaseBudgetFor100K'
  | 'DecreaseBudgetFor500K'
  | 'DecreaseBudgetFor1M'
  | 'DecreaseBudgetFor1MAnd500K';

export const BudgetDecisionValues: Record<BudgetDecisionOptions, number> = {
  IncreaseBudgetFor3M: 3e6,
  IncreaseBudgetFor1MAnd500K: 1.5e6,
  IncreaseBudgetFor1M: 1e6,
  IncreaseBudgetFor500K: 0.5e6,
  DontChangeBudget: 0,
  DecreaseBudgetFor100K: -0.1e6,
  DecreaseBudgetFor500K: -0.5e6,
  DecreaseBudgetFor1M: -1e6,
  DecreaseBudgetFor1MAnd500K: -1.5e6,
};

export type BudgetVote = {
  voter: string;
  decision: BudgetDecisionOptions;
};

export type DeltaTreatments = {
  oma: number;
  mepo: number;
  ics: number;
};

export type PatientsVariation = {
  changeOMAPatientDelta: number;
  changeMEPOPatientDelta: number;
  changeICSPatientDelta: number;
};

export type Feedbacks = 'YouAreOpen' | 'MeMyselfAndI';
export type Goal = {
  id: string;
  isAchieved: boolean;
};

export const AllocationDecisionDeltaTreatments: Record<
  AllocationDecisionOptions,
  (v: PatientsVariation) => DeltaTreatments
> = {
  DismissICS: v => ({ oma: 0, mepo: 0, ics: v.changeICSPatientDelta }),
  ChangeOMABudget: v => ({
    oma: v.changeOMAPatientDelta,
    mepo: 0,
    ics: -v.changeOMAPatientDelta,
  }),
  ChangeMEPOBudget: v => ({
    oma: 0,
    mepo: v.changeMEPOPatientDelta,
    ics: -v.changeMEPOPatientDelta,
  }),
  DoNothing: v => ({ oma: 0, mepo: 0, ics: 0 }),
  MoveBudgetFromOMAtoMEPO: v => ({ oma: -121, mepo: 128, ics: -7 }),
  MoveBudgetFromMEPOtoOMA: v => ({ oma: 227, mepo: -240, ics: 13 }),
  IncreaseOMAandMEPOBudget: v => ({
    oma: v.changeOMAPatientDelta,
    mepo: 0,
    ics: -v.changeOMAPatientDelta,
  }),
};

export const toBudgetValue = (option: BudgetDecisionOptions): number => {
  return BudgetDecisionValues[option];
};

export const toDeltaTreatments =
  (option: AllocationDecisionOptions) =>
  (v: PatientsVariation): DeltaTreatments => {
    const treatments = AllocationDecisionDeltaTreatments[option];
    return treatments ? treatments(v) : { oma: 0, mepo: 0, ics: 0 };
  };
