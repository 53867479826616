import { ReactNode } from 'react';
import hash from 'object-hash';

export const dictionaryMapper: Record<
  string,
  (...chunks: ReactNode[]) => ReactNode
> = {
  p: (...chunks) => <p key={Math.random()}>{chunks}</p>,
  b: (...chunks) => <strong key={hash(chunks)}>{chunks}</strong>,
  em: (...chunks) => <em key={hash(chunks)}>{chunks}</em>,
};
