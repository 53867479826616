import { useEffect, useState } from 'react';

export interface IBrowserInfo {
  isSafari: boolean;
  isEdge: boolean;
}

export default function useBrowser() {
  const [browserInfo, setBrowserInfo] = useState<IBrowserInfo>({
    isSafari: false,
    isEdge: false,
  });

  useEffect(
    () =>
      typeof window !== 'undefined' &&
      setBrowserInfo({
        isSafari:
          /constructor/i.test(window.HTMLElement as unknown as string) ||
          (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
          })(
            !window['safari'] ||
              // @ts-ignore
              (typeof safari !== 'undefined' && safari?.pushNotification)
          ),
        isEdge:
          // @ts-ignore
          typeof navigator !== 'undefined' && /Edg/.test(navigator?.userAgent),
      }),
    []
  );

  return browserInfo;
}
