import { IGame, PhaseNames } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import { IPlayer } from '../concepts/player';
import { ICountdown } from '../concepts/countdown';
import { ILocations } from '../concepts/locations';
import { IDocs } from '../concepts/docs';
import { IToDos } from '../concepts/to-dos';
import { GetState } from 'zustand';
import { IAvatar } from '../concepts/avatar';
import { IVoting } from '../concepts/voting';
import { remote } from '../../../remote';
import { executors } from './phases';
import IMeetings from '../concepts/meetings';
import getLedspClient from '../../../utils/get-ledsp-client';
import GameRecordId from '../../../core/game-record-id.type';

export const create: StoreSlice<
  IGame,
  ILogger &
    IPlayer &
    ICountdown &
    ILocations &
    IDocs &
    IToDos &
    IVoting &
    IAvatar &
    IMeetings
> = (set, get) => {
  return {
    gameId: '--null--',
    gameRecordId: '--null--::-null--',
    gamePlayId: '--null--',
    isInitialized: false,
    isStarted: false,
    isStopped: false,
    isDebugModeOn: false,
    startTime: 0,
    stopTime: 0,

    async init(opts) {
      get().log(`game initialized, «${opts.gamePlayId}»`);
      const info = await get().playerFromGamePlayId(opts.gamePlayId);

      // Checking if no players have been retrieved from `gamePlayId`
      // (also checking the type because I'm not currently sure if an
      // empty array is still a valid option here).
      if (!info?.players?.length && typeof info?.players?.length !== 'number')
        throw new Error("Couldn't retrieve player from gamePlayId!");

      const players = get().players;

      set({
        gameId: info.gameId,
        gameRecordId: info.gameId as GameRecordId,
        playerId: info.playerId,
        team: info.team,
        role: info.role,
        gamePlayId: info.gamePlayId,
        isInitialized: true,
        players: [...new Set([...players, ...info.players])],
      });

      get().initVoting();
      get().resume();
    },

    start(time) {
      if (get().isStarted) return;

      remote
        .startGame(get().gameRecordId, time, get().players)
        .then(() => get().log('game started'))
        .catch(get().error);

      getLedspClient().sendGameProgressEvent({
        gameId: get().gameRecordId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-started',
      });
    },

    stop() {
      if (get().isStopped) return;

      get().log('game stopped');
      set({ isStopped: true, stopTime: Date.now() });
    },

    resume() {
      get().log(`game resumed: ${get().gameId}`);

      remote.onGameSnapshot(get().gameRecordId, doc => {
        get().log(`game snapshot received, ${doc.currentPhase}`);
        get().gotoPhase(doc.currentPhase, doc.currentPhaseStartTime);
        set(doc);
      });

      remote.onAvatarSnapshot(get().gameId, doc => {
        get().log(`avatar snapshot received, ${doc.avatar}`);
        get().selectAvatar(doc.playerId, doc.avatar);
      });

      remote.onMeetingRoomSnapshot(get().gameId, doc => {
        get().log(
          `meeting rooms snapshot received, ${doc.role} -> ${doc.meetingRoomURL}`
        );
        get().setRoleMeetingRoom(doc.role, doc.meetingRoomURL);
      });
    },

    timeElapsed: (now: number = Date.now()) => {
      if (!get().isStarted) return 0;
      if (get().isStopped) return get().stopTime - get().startTime;
      return now - get().startTime;
    },

    /** Chat */
    isChatOpen: false,
    isChatReadable: false,

    openChat() {
      set({ isChatOpen: true, isChatReadable: true });
    },

    closeChat() {
      set({ isChatOpen: false });
    },

    /** Phases */
    currentPhase: '--null--',
    currentPhaseStartTime: 0,
    gotoPhase(phase, time) {
      if (get().currentPhase === phase) return;

      get().log(`entered phase: «${phase}»`);

      set({ currentPhase: phase, currentPhaseStartTime: time });

      executors[phase](get);
    },

    setRoleMeetingRoom(role: string, url: string) {
      const meetingRooms = get().meetingRooms;

      remote
        .setRoleMeetingRoom(get().gameId, role, url)
        .then(() => get().log(`set meeting room "${url}" for "${role}"`))
        .catch(get().error);

      set({
        meetingRooms: {
          ...meetingRooms,
          [role]: url,
        },
      });
    },

    /** Meeting */
    meetingRoom: '',
    currentMeeting: '',
    isMeetingOn: false,
    startMeeting(id: string) {
      set({ isMeetingOn: true, currentMeeting: id });

      // NOTE: Here and not in factories/phases because we don't have playerId and
      // team there.
      if (id === 'first-leg')
        getLedspClient().sendGameProgressEvent({
          step: 'Phase1',
          stage: 'Meeting',
          gameId: get().gameId,
          playerId: get().playerId,
          teamId: get().team,
          eventType: 'game-stage-entered',
        });
      else if (id === 'second-leg')
        getLedspClient().sendGameProgressEvent({
          step: 'Phase2',
          stage: 'Meeting',
          gameId: get().gameId,
          playerId: get().playerId,
          teamId: get().team,
          eventType: 'game-stage-entered',
        });
    },
    closeMeeting() {
      set({ isMeetingOn: false });
    },
    meetingRooms: {
      DS: null,
      DF: null,
      DM: null,
      DP: null,
      PE: null,
      CA: null,
    },
  };
};

export interface IPhaseExecutorFn {
  (get: GetState<any>): void;
}

export type PhaseExecutorMap = Record<PhaseNames, IPhaseExecutorFn>;
