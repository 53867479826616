import { useIntl } from 'react-intl';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import { useTheme } from '../../stores/theme';
import { PlayTimeScreen } from './play-time-screen';

export const ThanksScreen = () => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);

  return (
    <PlayTimeScreen>
      <div className="flex flex-col items-center text-center justify-center h-full">
        <h1 className="font-bold text-hospitaliaTeal-base decoration-hospitaliaTeal-light max-w-[540px] underline text-4xl xl:text-5xl 2xl:text-6xl">
          {formatMessage({ id: 'pages:thankyou.title' })}
        </h1>
        <img
          src={images(buildOptimizedAssetPath('thankyou', ['avif', 'webp']))}
          alt={formatMessage({ id: 'pages:thankyou.img.alt' })}
          className="max-w-[700px] mt-6"
        />
      </div>
    </PlayTimeScreen>
  );
};
