import { FC, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useGame } from '../../../stores/game';
import { useTheme } from '../../../stores/theme';
import buildOptimizedAssetPath from '../../../utils/build-optimized-asset-path';

export const SurveyItem: FC<{
  session: string;
  question: string;
}> = props => {
  const { formatMessage } = useIntl();
  const availableVotes = useGame(({ availableVotes }) =>
    availableVotes(props.session)
  );
  const playerId = useGame(({ playerId }) => playerId);
  const issueVote = useGame(({ issueVote }) => issueVote);
  const issuedVote = useGame(({ readVote }) =>
    readVote(props.session, playerId)
  );
  const images = useTheme(({ images }) => images);

  const [selectedVote, selectVote] = useState(issuedVote);

  const isVoteSelectionDisabled = issuedVote !== '';

  const canConfirmVote: boolean = !(
    selectedVote === '' || isVoteSelectionDisabled
  );

  const [hoveredValue, setHoveredValue] = useState<number>(0);

  return (
    <>
      <div className="flex flex-col items-center p-2 lg:p-4 xl:p-5 2xl:p-8">
        <p className="mb-1 text-xs lg:text-sm xl:text-base 2xl:text-lg lg:mb-1 xl:mb-2 2xl:mb-3">
          {formatMessage({ id: props.question })}
        </p>
        <ul
          className="flex flex-row space-x-1"
          style={isVoteSelectionDisabled ? { pointerEvents: 'none' } : {}}
        >
          {availableVotes.map((value, index) => (
            <motion.li
              key={index}
              className={clsx(
                'w-8 xl:w-10 2xl:w-12 h-8 xl:h-10 2xl:h-12 cursor-pointer',
                (hoveredValue || Number(selectedVote) - 1) >= index
                  ? 'saturate-100'
                  : 'saturate-0'
              )}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onMouseOver={() => setHoveredValue(index)}
              onMouseLeave={() =>
                hoveredValue === index && setHoveredValue(undefined)
              }
              onClick={() => selectVote(value)}
            >
              <img
                src={images(
                  buildOptimizedAssetPath('survey-star', ['avif', 'png'])
                )}
                alt={`${index + 1}`}
              />
            </motion.li>
          ))}
        </ul>

        <motion.button
          animate={{ y: 0 }}
          whileHover={{ y: canConfirmVote ? -1 : 0 }}
          whileTap={{ y: canConfirmVote ? 1 : 0 }}
          className="relative flex flex-row items-center py-1 pl-4 pr-8 m-auto mt-4 mb-4 space-x-8 font-bold text-white uppercase rounded-full shadow-lg cursor-pointer select-none lg:mb-4 xl:mb-6 2xl:mb-10 xl:mt-6 2xl:mt-8 disabled:bg-neutral-200 disabled:cursor-default disabled:shadow-none bg-hospitaliaTeal-base xl:py-2 hover:shadow-xl hover:bg-hospitaliaTeal-light active:bg-hospitaliaTeal-dark"
          disabled={!canConfirmVote}
          onClick={() => {
            if (isVoteSelectionDisabled) return;

            issueVote({
              session: props.session,
              time: Date.now(),
              vote: selectedVote,
              voter: playerId,
              motivation: '',
            });
          }}
        >
          <img src={images('checkmark.svg')} alt="Checkmark" />
          <p className="text-xs lg:text-sm 2xl:text-base">
            {isVoteSelectionDisabled ? 'Voto Confermato' : 'Conferma Voto'}
          </p>
        </motion.button>
      </div>
    </>
  );
};
