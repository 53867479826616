import { GetState } from 'zustand';
import { ICountdown } from '../../concepts/countdown';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IToDos } from '../../concepts/to-dos';
import { IPhaseExecutorFn } from '../game';

export const startPhase2: IPhaseExecutorFn = (
  get: GetState<IGame & IDocs & IToDos & ILocations & ICountdown>
) => {
  get().pinLocation('phases/phase-2/intro');
  get().openLocation('phases/phase-2/intro');
  get().openLocation('phases/phase-2/scenario');

  get().clearToDos();
  ToDos.forEach(get().appendToDo);

  get().startCountdown(get().currentPhaseStartTime, 45 * 60 * 1000);
};

const ToDos = [
  {
    id: 'read-intro',
    type: 'read-info',
    locationToVisit: 'phases/phase-2/intro',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-intro',
    type: 'read-info',
    locationToVisit: 'phases/phase-2/scenario',
    payload: null,
    nudge: '',
  },
];
