import clsx from 'clsx';

interface IProps {
  isActive?: boolean;
  children: JSX.Element | JSX.Element[];
}

export const NavigationBar = ({ isActive, children }: IProps) => (
  <nav
    className={clsx(
      'flex flex-col items-center text-center w-full h-full overflow-hidden border-l-2 border-neutral-200 right-0 bg-neutral-200',
      { 'pointer-events-none opacity-50': !isActive }
    )}
  >
    {children}
  </nav>
);
