import { useEffect } from 'react';
import { Route, Switch } from 'wouter';
import { IntlProvider } from 'react-intl';
import { CheckToDoCompletion } from './comps/game/check-todo-completion';
import { PhaseSelector } from './comps/game/dev-tools/phase-selector';
import { Initializer } from './comps/game/initializer';
import { RecordLocationVisit } from './comps/game/record-location-visit';
import { RedirectToBaseCamp } from './comps/game/redirect-to-basecamp';
import { AchievementsScreen } from './comps/screens/achievements';
import { AvatarScreen } from './comps/screens/avatar';
import { DocsInventoryScreen } from './comps/screens/docs-inventory';
import { FeedbackScreen } from './comps/screens/feedback';
import { IntroMandateScreen } from './comps/screens/intro-mandate';
import { IntroTeamScreen as IntroRolesScreen } from './comps/screens/intro-team';
import { LandingScreen } from './comps/screens/landing';
import { MeetingScreen } from './comps/screens/meeting';
import { PhaseIntroScreen } from './comps/screens/phase.intro';
import { PhaseOutcomeScreen } from './comps/screens/phase.outcome';
import { PhaseScenarioScreen } from './comps/screens/phase.scenario';
import { PhaseSurveyScreen } from './comps/screens/phase.survey';
import { PhaseVoteScreen } from './comps/screens/phase.vote';
import { ProfileScreen } from './comps/screens/profile';
import { RulesScreen } from './comps/screens/rules';
import { TeamGoalsScreen } from './comps/screens/team-goals';
import { ThanksScreen } from './comps/screens/thanks';
import { NestedRoutes } from './comps/utils/nested-routes';
import { useGame } from './stores/game';
import messages from './comps/assets/locales/it';
import { NotStartedScreen } from './comps/screens/not-started';
import { ProfilesScreen } from './comps/screens/profiles';
import { ModalProvider } from './comps/providers/modal.provider';
import Phase3BreakingNewsScreen from './comps/screens/phase-3.breaking-news';
import { CheckIfPlayerIsInGame } from './comps/game/check-if-player-is-in-game';
import { LedspSetup } from './comps/game/ledsp-setup';
import useFullscreen from './comps/hooks/useFullscreen.hook';
import FullscreenAlert from './comps/FullscreenAlert';
import { ConversationReport } from './comps/screens/conversation-report';

export const App = () => {
  const isFullscreen: boolean = useFullscreen();

  useEffect(() => {
    console.log(
      `[branch|commit]: [${process.env.CF_PAGES_BRANCH}|${process.env.GIT_COMMIT_HASH}]`
    );
  });

  return (
    <IntlProvider messages={messages} locale="en">
      <Switch>
        <Route path="/init" component={LedspSetup} />
        <Initializer>
          <ModalProvider>
            {!isFullscreen && <FullscreenAlert />}

            <NestedRoutes base="/games">
              <PhaseSelector />

              <CheckIfPlayerIsInGame>
                <RedirectToBaseCamp />
              </CheckIfPlayerIsInGame>

              <Switch>
                <Route
                  path="/:id/is-not-started"
                  component={NotStartedScreen}
                />
                <Route path="/:id/not-in-game" component={NotStartedScreen} />
                <InGameRoutes />
              </Switch>
            </NestedRoutes>
          </ModalProvider>
        </Initializer>
      </Switch>
    </IntlProvider>
  );
};

const InGameRoutes = () => {
  const isMeetingOn = useGame(({ isMeetingOn }) => isMeetingOn);

  return (
    <Route path="/:id/:rest*">
      <RecordLocationVisit />
      <CheckToDoCompletion />

      <Switch>
        {isMeetingOn && <Route path="/:rest*" component={MeetingScreen} />}

        <Route path="/:id" component={LandingScreen} />
        <Route path="/:id/conversation-report" component={ConversationReport} />
        <Route path="/:id/rules" component={RulesScreen} />
        <Route path="/:id/intro/mandate" component={IntroMandateScreen} />
        <Route path="/:id/intro/roles" component={IntroRolesScreen} />
        <Route path="/:id/docs/:docId?" component={DocsInventoryScreen} />
        <Route path="/:id/avatar" component={AvatarScreen} />
        <Route path="/:id/profiles" component={ProfilesScreen} />
        <Route path="/:id/profiles/:profileId" component={ProfileScreen} />
        <Route path="/:id/team-goals" component={TeamGoalsScreen} />

        <Route path="/:id/phases/:p/intro" component={PhaseIntroScreen} />
        <Route path="/:id/phases/:p/scenario" component={PhaseScenarioScreen} />
        <Route
          path="/:id/phases/phase-3/breaking-news"
          component={Phase3BreakingNewsScreen}
        />

        <Route path="/:id/phases/:p/vote" component={PhaseVoteScreen} />
        <Route path="/:id/phases/:p/outcome" component={PhaseOutcomeScreen} />
        <Route path="/:id/phases/:p/survey" component={PhaseSurveyScreen} />

        <Route path="/:id/feedback" component={FeedbackScreen} />
        <Route path="/:id/achievements" component={AchievementsScreen} />
        <Route path="/:id/thanks" component={ThanksScreen} />

        <Route path="/:rest*">
          {(params: any) =>
            `[404] Sorry,
          the page $ { params.rest } does not exist !`
          }
        </Route>
      </Switch>
    </Route>
  );
};
