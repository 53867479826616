import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGame } from '../../stores/game';
import { PlayerInfo } from '../../stores/game/concepts/player';
import { useTheme } from '../../stores/theme';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import roles from '../contents/roles';
import ServiceScreen from '../service-screen';
import { InvalidGamePlayMessage } from './msg.invalid-game-play';

export const NotStartedMessage: FC<{ gamePlayId: string }> = ({
  gamePlayId,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [playerInfo, setPlayerInfo] = useState<PlayerInfo>();
  const { formatMessage } = useIntl();

  const images = useTheme(({ images }) => images);
  const playerFromGamePlayId = useGame(
    ({ playerFromGamePlayId }) => playerFromGamePlayId
  );

  useEffect(() => {
    const init = async () => {
      try {
        setPlayerInfo(await playerFromGamePlayId(gamePlayId));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoading) init();
  }, [playerFromGamePlayId, isLoading, gamePlayId]);

  if (isLoading) return <ServiceScreen text="Loading..." />;

  if (!playerInfo.isValid) return <InvalidGamePlayMessage />;
  const role = roles.find(role => role.shortname === playerInfo.role);

  return (
    <>
      <img
        className="w-1/4"
        src={images(buildOptimizedAssetPath('home_logo', ['avif', 'webp']))}
        alt="Home Logo"
      />

      <div className="p-8 text-center uppercase bg-hospitaliaGold-base text-hospitaliaBlue-base rounded-2xl">
        Benvenuto <strong>{playerInfo.playerId}</strong>,<br /> sei il{' '}
        <strong>{formatMessage({ id: `profiles:${role.id}.title` })}</strong>{' '}
        della squadra <strong>{playerInfo.team}</strong>.
        <br />
        <br />
        La partita inizierà fra poco.
      </div>
    </>
  );
};
