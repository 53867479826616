import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import { ITheme } from '../concepts/theme';

export const create: StoreSlice<ITheme, ILogger> = (set, get) => {
  return {
    assetsUrl: process.env.ASSETS_URL,

    images(path: string) {
      return `${get().assetsUrl}/images/${path}`;
    },

    documents(path: string) {
      return `${get().assetsUrl}/documents/${path}`;
    },
  };
};
