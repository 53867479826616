import qs from 'query-string';
import gameRecordId from './game-record-id';

const paramsKey = (id: string) => `hospitalia-params-${id}`;

type LedspDefaultParams = {
  demoId: string;
  role: string;
  gameId: string;
  playerId: string;
  team: string;
};

export const extractDefaultGameParams = (gameId: string) => {
  if (typeof window === 'undefined') return;

  return JSON.parse(
    localStorage.getItem(paramsKey(gameId)) || '{}'
  ) as LedspDefaultParams;
};

export const saveDefaultGameParams = () => {
  if (typeof window === 'undefined') return;

  const parsed = qs.parse(location.search);
  const demoId = parsed?.demoId?.toString();
  if (!demoId) return;

  // `demoId` looks like `sessionId::gameId:shortRole.T1`.

  const [sessionId, rest] = demoId?.split('::');
  const [gameId, playerId] = rest?.split(':');
  const [role, team] = playerId?.split('.');

  if (!sessionId || !gameId || !playerId || !role || !team)
    throw new Error(
      'Invalid demoId param, format should be `sessionId::gameId:role.T1`!'
    );

  localStorage.setItem(
    paramsKey(demoId),
    JSON.stringify({
      demoId: parsed.demoId,
      role,
      team,
      gameId: gameRecordId(sessionId, gameId),
      playerId,
    })
  );
};
