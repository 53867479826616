import create, { GetState, SetState } from 'zustand';
import { chat } from '../../remote/chat';
import * as Chat from './factories/chat';
import * as Logger from './factories/logger';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Chat.create(chat)(set, get),
  ...Logger.create(set, get),
});

export const useChat = create(createRootSlice);
