import { useIntl } from 'react-intl';
import { useRoute } from 'wouter';
import { useGame } from '../../stores/game';
import extractPhase from '../../utils/extract-phase';
import getPhaseColor from '../../utils/get-phase-color';
import SectionTitle from '../game/section-title';
import { SurveyItem } from '../game/survey';
import { PlayTimeScreen } from './play-time-screen';

export const PhaseSurveyScreen = () => {
  const [match, params] = useRoute<RouteParams>(
    '/:gamePlayId/phases/:phaseId/survey'
  );
  const { formatMessage: f } = useIntl();
  const currentPhase = useGame(({ currentPhase }) => currentPhase);

  const surveyItems = [
    `${params.phaseId}-survey-satisfaction`,
    `${params.phaseId}-survey-representation`,
  ];
  const missingVotesCount = useGame(s =>
    Math.max(...surveyItems.map(surveyItem => s.missingVotesCount(surveyItem)))
  );

  return (
    <PlayTimeScreen>
      <SectionTitle
        label={f({ id: `pages:phases-${extractPhase(currentPhase)}.title` })}
        color={getPhaseColor(currentPhase)}
      />
      {surveyItems.map((surveyItem, i) => (
        <SurveyItem
          key={i}
          session={surveyItem}
          question={`pages:survey.title-${i + 1}`}
        />
      ))}
      <p className="w-1/2 mx-auto mt-1 text-base text-center uppercase bg-gray-200 rounded-full 2xl:w-1/3 opacity-80 xl:text-xl xl:mt-1">
        Rimangono ancora <strong>{missingVotesCount}</strong> voti da
        collezionare...
      </p>
    </PlayTimeScreen>
  );
};

type RouteParams = {
  gamePlayId: string;
  phaseId: string;
};
