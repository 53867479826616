import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import path from 'path';
import { useGame } from '../../stores/game';

export const RedirectToBaseCamp: FC<{
  children?: ReactElement;
}> = props => {
  const [match, params] = useRoute<GameRouteParams>('/:gamePlayId/:rest*');
  const [, setLocation] = useLocation();

  const isStarted = useGame(({ isStarted }) => isStarted);
  const shouldLocationChange = useGame(({ shouldLocationChange }) =>
    shouldLocationChange(params.rest)
  );
  const basecampLocation = useGame(({ basecampLocation }) =>
    basecampLocation()
  );

  const pathname = path.join('/', params.gamePlayId, basecampLocation);

  useEffect(() => {
    if (params?.rest?.includes('conversation-report')) return;
    if (!isStarted) {
      setLocation(`/${params.gamePlayId}/is-not-started`);
      return;
    }

    if (shouldLocationChange) {
      setLocation(pathname);
      return;
    }
  }, [isStarted, params?.gamePlayId, pathname]);

  if (!match)
    return <span>This component is not loaded under the correct path</span>;

  return props.children;
};

type GameRouteParams = {
  gamePlayId: string;
  rest: string;
};
