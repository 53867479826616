import { Redirect, useRoute } from 'wouter';
import { LandingScreenLayout } from '../layout/screen.landing';
import { useTheme } from '../../stores/theme';
import { NotStartedMessage } from '../game/not-started-message';
import { useGame } from '../../stores/game';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import { useEffect } from 'react';

export const NotStartedScreen = () => {
  const [match, params] = useRoute<{ gamePlayId: string }>(
    '/:gamePlayId/:rest*'
  );
  const images = useTheme(({ images }) => images);
  const isStarted = useGame(({ isStarted }) => isStarted);

  if (!match)
    return <span>`This component is not loaded under the correct path`</span>;

  if (isStarted) return <Redirect to={`/${params.gamePlayId}/rules`} />;

  return (
    <LandingScreenLayout>
      <div
        className="flex flex-col items-center justify-center h-full gap-10 p-4 bg-center bg-cover"
        style={{
          backgroundImage: `url(${images(
            buildOptimizedAssetPath('home_bg', ['avif', 'webp'])
          )}`,
        }}
      >
        <NotStartedMessage gamePlayId={params.gamePlayId} />
      </div>
    </LandingScreenLayout>
  );
};
