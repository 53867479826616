import { MutableRefObject, useEffect, useState } from 'react';

export function useOverflow(
  elementReference: MutableRefObject<HTMLDivElement>
): boolean {
  const [isOverflowing, setOverflowing] = useState<boolean>(false);

  useEffect(
    () =>
      setOverflowing(
        !!(
          elementReference &&
          elementReference.current &&
          elementReference.current.clientHeight <
            elementReference.current.scrollHeight
        )
      ),
    [
      elementReference,
      elementReference?.current,
      elementReference?.current?.clientHeight,
      elementReference?.current?.scrollHeight,

      // Needed?
      elementReference?.current?.offsetHeight,
      elementReference?.current?.clientTop,
      elementReference?.current?.scrollTop,
      elementReference?.current?.offsetTop,
    ]
  );

  return isOverflowing;
}
