export default Object.seal({
  DS: ['DecreaseBudgetFor1M', 'DontChangeBudget', 'IncreaseBudgetFor500K'],
  DF: [
    'DecreaseBudgetFor1MAnd500K',
    'DecreaseBudgetFor500K',
    'DontChangeBudget',
  ],
  DM: ['DecreaseBudgetFor500K', 'DontChangeBudget', 'IncreaseBudgetFor500K'],
  DP: [
    'IncreaseBudgetFor1MAnd500K',
    'DontChangeBudget',
    'DecreaseBudgetFor100K',
  ],
  PE: [
    'DecreaseBudgetFor1MAnd500K',
    'DecreaseBudgetFor500K',
    'DontChangeBudget',
  ],
  CA: ['IncreaseBudgetFor3M', 'IncreaseBudgetFor1M', 'DontChangeBudget'],
});
