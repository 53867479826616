import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { IToDos } from '../concepts/to-dos';
import { StoreSlice } from '../../utils/store-slice';
import produce from 'immer';
import { ILocations } from '../concepts/locations';
import { remote } from '../../../remote';
import { IKpi } from '../concepts/kpi';

export const create: StoreSlice<ILocations, ILogger & IGame & IToDos & IKpi> = (
  set,
  get
) => {
  return {
    availableLocations: ['/'],
    alwaysAvailableLocations: ['rules', 'intro/mandate', 'intro/roles', 'docs'],
    visitedLocations: [],
    pinnedLocation: '',
    latestLocation: '',

    closeLocation(path: string) {
      set(
        produce((s: ILocations) => {
          s.availableLocations = s.availableLocations.filter(
            availableLocation => availableLocation !== path
          );
        })
      );
    },

    openLocation(path: string) {
      set(
        produce((s: ILocations) => {
          s.visitedLocations = s.visitedLocations.filter(
            visitedLocation => visitedLocation !== path
          );

          if (s.availableLocations.includes(path)) return;

          s.availableLocations.push(path);
        })
      );
    },

    openLocationForever(path: string) {
      set(
        produce((s: ILocations) => {
          if (s.alwaysAvailableLocations.includes(path)) return;
          else s.alwaysAvailableLocations.push(path);
        })
      );
    },

    visitLocation(path: string) {
      if (!path) return;
      if (!get().isStarted) return;

      set(
        produce((s: ILocations) => {
          if (s.visitedLocations.includes(path)) return;

          s.visitedLocations.push(path);

          s.latestLocation = path;

          if (s.pinnedLocation === path) s.pinnedLocation = '';
        })
      );

      get().syncLocations();

      if (path === 'feedback') {
        remote.saveVotesResults(get().gameRecordId, {
          allocationDecisionResult: get().allocationDecision(),
          directDistributionDecisionResult: get().directDistributionDecision(),
          desertTenderCostsDecisionResult: get().desertTenderCostsDecision(),
          diagnosisDecisionResult: get().diagnosisDecision(),
        });
      }
    },

    pinLocation(path) {
      set({ pinnedLocation: path });

      get().syncLocations();
    },

    unpinLocation() {
      set({ pinnedLocation: '' });

      get().syncLocations();
    },

    isLocationVisited(path: string) {
      return get().visitedLocations.includes(path);
    },

    shouldLocationChange(path: string = '/') {
      if (!get().isStarted && path !== '/') return true;
      if (get().isStarted && path === '/') return true;
      if (get().pinnedLocation && path !== get().pinnedLocation) return true;

      return ![
        ...get().availableLocations,
        ...get().alwaysAvailableLocations,
      ].includes(path);
    },

    basecampLocation() {
      if (get().pinnedLocation) return get().pinnedLocation;
      if (get().availableLocations.length === 0) return 'rules';

      return (
        get().nextLocationToVisit() ||
        get().availableLocations[get().availableLocations.length - 1]
      );
    },

    syncLocations() {
      remote.syncLocations(get().gameId, get().gamePlayId, {
        availableLocations: get().availableLocations,
        alwaysAvailableLocations: get().alwaysAvailableLocations,
        visitedLocations: get().visitedLocations,
        pinnedLocation: get().pinnedLocation,
        basecampLocation: get().basecampLocation(),
        latestLocation: get().latestLocation,
      });
    },
  };
};
