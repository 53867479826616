import { useIntl } from 'react-intl';
import { AvatarList } from '../game/avatar';
import { PlayTimeScreen } from './play-time-screen';
import SectionTitle from '../game/section-title';
import { useGame } from '../../stores/game';
import roles from '../contents/roles';

export const AvatarScreen = () => {
  const { formatMessage } = useIntl();
  const playerRole = useGame(({ role }) => role);
  const role = roles.find(r => r.shortname === playerRole);

  return (
    <PlayTimeScreen>
      <SectionTitle label="Tu sei il..." />
      <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
        <p className="mb-6 text-xl font-bold uppercase xl:mb-10 xl:text-3xl text-hospitaliaGold-base">
          {formatMessage({ id: `profiles:${role.id}.title` })}
        </p>

        <AvatarList />
      </div>
    </PlayTimeScreen>
  );
};
