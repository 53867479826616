import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useTheme } from '../../stores/theme';
import getPhaseColor from '../../utils/get-phase-color';
import TextRevealer from './text-revealer';

const PhaseIntro = ({ phase }) => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);

  return (
    <div className="flex flex-col w-full p-2 mt-4 lg:p-4 xl:p-6 2xl:p-8 lg:pt-1 xl:mt-20">
      <span className="relative block overflow-y-hidden h-28">
        <motion.img
          src={images(`phases-intros/${phase}.svg`)}
          className="w-24 mx-auto"
          initial={{ opacity: 0, y: 32 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 0.6 }}
          alt={phase}
        />
      </span>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-auto px-8 py-2 mx-auto mt-4 mb-6 text-center rounded-xl"
        style={{ backgroundColor: getPhaseColor(phase) }}
      >
        <p className="text-xl font-bold text-white uppercase">
          {formatMessage({ id: `pages:phases-${phase}.title` })}
        </p>
      </motion.span>
      <p className="w-1/2 mx-auto text-base text-center lg:text-lg xl:text-xl">
        <TextRevealer
          initialDelay={0.8}
          label={formatMessage({ id: `pages:phases-${phase}.intro.content` })}
        />
      </p>
    </div>
  );
};

export default PhaseIntro;
