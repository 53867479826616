import { useEffect, useState } from 'react';
import { Redirect } from 'wouter';
import { saveDefaultGameParams } from '../../utils/default-game-params';
import useGameQueryParams from '../../utils/useGameQueryParams.hook';
import ServiceScreen from '../service-screen';

export const LedspSetup = () => {
  const [ledspReady, setLedspReady] = useState(false);
  const { gamePlayInfoId, demoId, rp } = useGameQueryParams();
  const gamePlayId = gamePlayInfoId || demoId;

  useEffect(() => {
    if (!gamePlayId) return;

    console.log('>>> setup with ledsp params');
    saveDefaultGameParams();
    setLedspReady(true);
  }, [gamePlayId]);

  if (!gamePlayId) return <p>Missing query string params</p>;

  if (!ledspReady) return <ServiceScreen text="Loading..." />;

  if (rp) return <Redirect to={`/games/${gamePlayId}/${rp}`} />;

  return <Redirect to={`/games/${gamePlayId}`} />;
};
