{
  "_id": "62df9af46d0adbc114923bb4",
  "id": "Hospitalia-1.0.0",
  "availablePlayOptions": [
    {
      "name": "scenarioName",
      "description": "",
      "format": "text",
      "required": true,
      "acceptedValues": ["classic"]
    }
  ],
  "availablePropsAndToolsSets": [
    {
      "name": "Hospitalia",
      "gameLaunchService": {
        "method": "POST",
        "url": "https://api.ledsp.va5.it/api/game-launcher/launch",
        "headers": {}
      },
      "gameService": {
        "method": "GET",
        "url": "https://api.ledsp.va5.it/api/game-launcher/involvements",
        "headers": {}
      },
      "gameLaunchInfoSetValidationEndpoint": {
        "method": "POST",
        "url": "https://ledsp-authority-staging-gtispat4va-ew.a.run.app/validate-launch-info",
        "headers": {
          "Content-Type": "application/json"
        }
      },
      "gameLaunchSettingsEndpoint": {
        "method": "POST",
        "url": "https://ledsp-authority-staging-gtispat4va-ew.a.run.app/settings",
        "headers": {
          "Content-Type": "application/json"
        }
      },
      "gameRoomConfigurationEndpoint": {
        "method": "POST",
        "url": "https://ledsp-authority-staging-gtispat4va-ew.a.run.app/game-room-configuration",
        "headers": {
          "Content-Type": "application/json"
        }
      },
      "gamePlayEndpoint": {
        "method": "GET",
        "url": "https://release.hospitalia-game.pages.dev"
      }
    }
  ],
  "defaultPlayOptionsSet": {
    "scenarioName": "classic"
  },
  "gameFlow": [
    {
      "id": "Introduction",
      "phase": "Introduction",
      "description": "The goal of this phase is to introduce the players to the game."
    },
    {
      "id": "Phase1",
      "phase": "Phase1",
      "description": "Todo."
    },
    {
      "id": "Phase2",
      "phase": "Phase2",
      "description": "Todo."
    },
    {
      "id": "Phase3",
      "phase": "Phase3",
      "description": "Todo."
    },
    {
      "id": "Endgame",
      "phase": "Endgame",
      "description": "Todo."
    }
  ],
  "name": "Hospitalia",
  "phases": [
    {
      "name": "Onboarding",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": ["Rules"]
    },
    {
      "name": "Introduction",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": [
        "Mandate",
        "RolesOverview",
        "ReadDocuments",
        "AvatarSelection",
        "TeamDrilldown",
        "TeamGoals"
      ]
    },
    {
      "name": "Phase1",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": [
        "Intro",
        "ReadDocuments",
        "Scenario",
        "Meeting",
        "Voting",
        "VoteResults",
        "Survey"
      ]
    },
    {
      "name": "Phase2",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": [
        "Intro",
        "Scenario",
        "Meeting",
        "Voting",
        "VoteResults",
        "Survey"
      ]
    },
    {
      "name": "Phase3",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": [
        "Intro",
        "BreakingNews",
        "ReadDocuments",
        "Scenario",
        "Voting",
        "VoteResults",
        "Survey"
      ]
    },
    {
      "name": "Endgame",
      "expectedDurationInSeconds": {
        "min": 1000,
        "max": 2000
      },
      "stages": ["Feeedback", "Achievements", "Thanks"]
    }
  ],
  "playersPartyFormationInfo": {
    "sizeCriterion": {
      "kind": "size",
      "size": 6,
      "subKind": "number-of-members"
    },
    "minSize": 1,
    "maxSize": 6
  },
  "teamFormationInfo": {
    "availableTeamRoles": ["DS", "DP", "DM", "PE", "CA"],
    "teamSizingCriteria": {
      "kind": "size",
      "size": 6,
      "subKind": "number-of-members"
    },
    "uniqRolePerTeam": true
  },
  "version": "1.0.0"
}
