import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import TextRevealer from '../text-revealer';
import SectionTitle from '../section-title';
import { useGame } from '../../../stores/game';
import getPhaseColor from '../../../utils/get-phase-color';

const Phase3Outcomes = () => {
  const { formatMessage } = useIntl();

  const {
    diagnosisDecision,
    directDistributionDecision,
    desertTenderCostsDecision,
  } = useGame(
    ({
      diagnosisDecision,
      directDistributionDecision,
      desertTenderCostsDecision,
    }) => ({
      diagnosisDecision: diagnosisDecision(),
      directDistributionDecision: directDistributionDecision(),
      desertTenderCostsDecision: desertTenderCostsDecision(),
    })
  );

  const teamId: string = useGame(({ team }) => team);

  const diagnosisMotivation = useGame(({ readMotivation }) =>
    readMotivation('phase-3-diagnosis', `DS.${teamId}`)
  );
  const directDistributionMotivation = useGame(({ readMotivation }) =>
    readMotivation('phase-3-distribution', `DS.${teamId}`)
  );
  const desertTenderMotivation = useGame(({ readMotivation }) =>
    readMotivation('phase-3-tender', `DS.${teamId}`)
  );

  return (
    <div>
      <SectionTitle
        label="Cosa ha deciso il direttore sanitario"
        color={getPhaseColor('phase-3')}
      />

      <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
        <p className="mb-6 text-base lg:text-lg xl:text-xl 2xl:text-2xl lg:mb-8 xl:mb-10 2xl:mb-12">
          <TextRevealer label="Il direttore sanitario ha preso le seguenti decisioni" />
        </p>

        <div className="flex flex-row w-full pb-8 space-x-4 xl:space-x-6 2xl:space-x-8">
          <motion.span
            initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
            animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
            transition={{ delay: 1, duration: 1 }}
            className="relative flex flex-col items-center justify-start w-2/3 p-4 mx-auto mt-2 border-2 shadow-xl rounded-3xl 2xl:p-6 border-neutral-200"
          >
            <p className="mb-10 font-semibold leading-tight text-center xl:xl:text-lg 2xl:text-xl xl:leading-tight">
              1.
              <br />
              {formatMessage({
                id: 'pages:decisions-diagnosis.statement',
              })}
            </p>
            <p className="items-center justify-center px-4 py-px text-lg font-bold uppercase rounded-full xl:text-lg text-teal-700/60 bg-teal-200/50">
              Scelta
            </p>
            <p className="text-center text-hospitaliaTeal-base xl:text-lg 2xl:text-xl 2xl:leading-tight">
              {formatMessage({
                id: `pages:decisions-diagnosis.answer-${diagnosisDecision}`,
              })}
            </p>
            <hr />
            <p className="flex items-center justify-center px-4 py-px mt-4 text-base font-medium uppercase rounded-full xl:text-lg text-teal-700/60 bg-teal-200/50">
              Motivazione
            </p>
            <p className="text-sm italic text-gray-600 lg:text-base xl:text-lg 2xl:text-xl">
              {diagnosisMotivation}
            </p>
          </motion.span>
          <motion.span
            initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
            animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
            transition={{ delay: 2, duration: 1 }}
            className="relative flex flex-col items-center justify-start w-2/3 p-4 mx-auto mt-2 border-2 shadow-xl rounded-3xl 2xl:p-6 border-neutral-200"
          >
            <p className="mb-10 font-semibold leading-tight text-center xl:text-lg 2xl:text-xl xl:leading-tight">
              2. <br />
              {formatMessage({
                id: 'pages:decisions-distribution.statement',
              })}
            </p>
            <p className="items-center justify-center px-4 py-px text-lg font-bold uppercase rounded-full xl:text-lg text-teal-700/60 bg-teal-200/50">
              Scelta
            </p>
            <p className="text-center text-hospitaliaTeal-base xl:text-lg 2xl:text-xl 2xl:leading-tight">
              {formatMessage({
                id: `pages:decisions-distribution.answer-${directDistributionDecision}`,
              })}
            </p>
            <hr />
            <p className="flex items-center justify-center px-4 py-px mt-4 text-base font-medium uppercase rounded-full xl:text-lg text-teal-700/60 bg-teal-200/50">
              Motivazione
            </p>
            <p className="text-sm italic text-gray-600 lg:text-base xl:text-lg 2xl:text-xl">
              {directDistributionMotivation}
            </p>
          </motion.span>
          <motion.span
            initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
            animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
            transition={{ delay: 3, duration: 1 }}
            className="relative flex flex-col items-center justify-start w-2/3 p-4 mx-auto mt-2 border-2 shadow-xl rounded-3xl 2xl:p-6 border-neutral-200"
          >
            <p className="mb-10 font-semibold leading-tight text-center xl:text-lg 2xl:text-xl xl:leading-tight">
              3.
              <br />
              {formatMessage({
                id: 'pages:decisions-tender.statement',
              })}
            </p>
            <p className="flex items-center justify-center px-4 py-px text-base font-bold uppercase rounded-full xl:text-lg text-teal-700/60 bg-teal-200/50">
              Scelta
            </p>
            <p className="text-center text-hospitaliaTeal-base xl:text-lg 2xl:text-xl 2xl:leading-tight">
              {formatMessage({
                id: `pages:decisions-tender.answer-${desertTenderCostsDecision}`,
              })}
            </p>
            <hr />
            <p className="flex items-center justify-center px-4 py-px mt-4 text-base font-medium uppercase rounded-full xl:text-lg text-teal-700/60 bg-teal-200/50">
              Motivazione
            </p>
            <p className="text-sm italic text-gray-600 lg:text-base xl:text-lg 2xl:text-xl">
              {desertTenderMotivation}
            </p>
          </motion.span>
        </div>

        <br />
      </div>
    </div>
  );
};

export default Phase3Outcomes;
