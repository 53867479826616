import { GetState } from 'zustand';
import getLedspClient from '../../../../utils/get-ledsp-client';
import { ICountdown } from '../../concepts/countdown';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IPlayer } from '../../concepts/player';
import { IToDos, VotingPayload } from '../../concepts/to-dos';
import { IVoting } from '../../concepts/voting';
import { IPhaseExecutorFn } from '../game';

export const startPhase3Outcome: IPhaseExecutorFn = (
  get: GetState<
    IGame & IDocs & IToDos & ILocations & IVoting & IPlayer & ICountdown
  >
) => {
  get().pinLocation('phases/phase-3/outcome');
  get().openLocation('phases/phase-3/outcome');
  get().openLocation('phases/phase-3/survey');

  get().clearToDos();
  ToDos.forEach(get().appendToDo);

  get().startVotingSession('phase-3-survey-satisfaction', {
    availableVotes: ['1', '2', '3', '4', '5'],
    voters: get().players,
    isMotivationRequired: false,
  });

  get().startVotingSession('phase-3-survey-representation', {
    availableVotes: ['1', '2', '3', '4', '5'],
    voters: get().players,
    isMotivationRequired: false,
  });

  get().appendToDo<VotingPayload>({
    id: 'vote-phase-3-survey',
    type: 'vote',
    locationToVisit: 'phases/phase-3/survey',
    nudge: '',
    payload: {
      sessions: [
        'phase-3-survey-satisfaction',
        'phase-3-survey-representation',
      ],
      gotoPhase: 'conclusions',
    },
  });

  get().stopCountdown();

  getLedspClient().sendGameProgressEvent({
    step: 'Phase3',
    stage: 'VoteResults',
    gameId: get().gameId,
    playerId: get().playerId,
    teamId: get().team,
    eventType: 'game-stage-entered',
  });
};

const ToDos = [
  {
    id: 'read-vote',
    type: 'read-info',
    locationToVisit: 'phases/phase-3/outcome',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-vote',
    type: 'read-info',
    locationToVisit: 'phases/phase-3/survey',
    payload: null,
    nudge: '',
  },
];
