import { GetState } from 'zustand';
import { ICountdown } from '../../concepts/countdown';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IToDos } from '../../concepts/to-dos';
import { IPhaseExecutorFn } from '../game';

export const startPhase3: IPhaseExecutorFn = (
  get: GetState<IGame & IDocs & IToDos & ILocations & ICountdown>
) => {
  get().collectDoc('Articolo');
  get().collectDoc('Report');
  get().collectDoc('LetteraDS');

  get().pinLocation('phases/phase-3/intro');
  get().openLocation('phases/phase-3/intro');
  get().openLocation('phases/phase-3/breaking-news');
  get().openLocation('phases/phase-3/scenario');

  get().clearToDos();
  ToDos.forEach(get().appendToDo);
  get().startCountdown(get().currentPhaseStartTime, 45 * 60 * 1_000);
};

const ToDos = [
  {
    id: 'read-intro',
    type: 'read-info',
    locationToVisit: 'phases/phase-3/intro',
    payload: null,
    nudge: '',
  },
  {
    id: 'breaking-news',
    type: 'read-info',
    locationToVisit: 'phases/phase-3/breaking-news',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-scenario',
    type: 'read-info',
    locationToVisit: 'phases/phase-3/scenario',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-all-docs',
    type: 'read-all-docs',
    locationToVisit: 'docs',
    payload: null,
    nudge: 'Leggi tutti i documenti prima di proseguire',
  },
];
