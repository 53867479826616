import { GetState, SetState } from 'zustand';
import produce from 'immer';
import { StoreSlice } from '../../utils/store-slice';
import { ILogger } from '../concepts/logger';
import { IGame } from '../concepts/game';
import {
  IToDos,
  ReadInfoPayload,
  ToDoDescription,
  VotingPayload,
} from '../concepts/to-dos';
import { ILocations } from '../concepts/locations';
import { IDocs } from '../concepts/docs';
import { IAvatar } from '../concepts/avatar';
import { IVoting } from '../concepts/voting';
import { remote } from '../../../remote';
import getLedspClient from '../../../utils/get-ledsp-client';

export const create: StoreSlice<IToDos, ILogger & IGame> = (set, get) => {
  return {
    thingsToDo: [],
    goForwardLabel: 'Vai alla prossima cosa da fare',

    clearToDos() {
      set({ thingsToDo: [] });
    },

    appendToDo(toDo) {
      set(
        produce((s: IToDos) => {
          s.thingsToDo.push(toDo);
        })
      );

      get().syncToDos();
    },

    completeFirstToDo() {
      if (get().thingsToDo.length === 0) return;

      get().log(`completing first todo: «${get().thingsToDo[0].id}»`);

      const todo = createTodo(get().thingsToDo[0]);

      if (todo.isDone(get)) {
        set(
          produce((s: IToDos) => {
            s.thingsToDo.shift();
          })
        );

        todo.onComplete(get, set);

        get().putFocusOnNextToDo();
      }

      get().syncToDos();
    },

    completeAllToDos() {
      const toDos = get().thingsToDo.map(createTodo);

      set(
        produce((s: IToDos) => {
          s.thingsToDo = s.thingsToDo.filter((t, i) => !toDos[i].isDone(get));
        })
      );

      toDos.filter(t => t.isDone).forEach(t => t.onComplete(get, set));

      get().putFocusOnNextToDo();

      get().syncToDos();
    },

    putFocusOnNextToDo() {
      if (get().thingsToDo.length === 0) return;
      createTodo(get().thingsToDo[0]).onFocus(get, set);
    },

    nextLocationToVisit() {
      if (get().thingsToDo.length === 0) return;
      return get().thingsToDo[0].locationToVisit;
    },

    currentToDoNudge() {
      if (get().thingsToDo.length === 0) return;
      return get().thingsToDo[0].nudge;
    },

    syncToDos() {
      remote.syncToDos(get().gameId, get().gamePlayId, get().thingsToDo);
    },
  };
};

interface IToDo {
  onFocus(get: GetState<any>, set: SetState<any>): void;
  isDone(get: GetState<any>): boolean;
  onComplete(get: GetState<any>, set: SetState<any>): void;
}

const createTodo = (description: ToDoDescription): IToDo => {
  switch (description.type) {
    case 'read-info':
      return readInfo(description);

    case 'read-all-docs':
      return readAllDocs(description);

    case 'select-avatar':
      return selectAvatar(description);

    case 'vote':
      return vote(description);

    default:
      return mockTodo(description);
  }
};

const readInfo = (todo: ToDoDescription<ReadInfoPayload>): IToDo => ({
  onFocus(get, set: SetState<IGame>) {
    // EMIT EVENT INTRO STAGE
    if (todo.id === 'read-rules')
      getLedspClient().sendGameProgressEvent({
        step: 'Onboarding',
        stage: 'Rules',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'read-mandate')
      getLedspClient().sendGameProgressEvent({
        step: 'Intro',
        stage: 'Mandate',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'read-roles')
      getLedspClient().sendGameProgressEvent({
        step: 'Intro',
        stage: 'RolesOverview',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (
      todo.id === 'read-intro' &&
      todo.locationToVisit === 'phases/phase-1/intro'
    )
      getLedspClient().sendGameProgressEvent({
        step: 'Phase1',
        stage: 'Intro',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (
      todo.id === 'read-intro' &&
      todo.locationToVisit === 'phases/phase-2/intro'
    )
      getLedspClient().sendGameProgressEvent({
        step: 'Phase2',
        stage: 'Intro',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (
      todo.id === 'read-intro' &&
      todo.locationToVisit === 'phases/phase-3/intro'
    )
      getLedspClient().sendGameProgressEvent({
        step: 'Phase3',
        stage: 'Intro',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'breaking-news')
      getLedspClient().sendGameProgressEvent({
        step: 'Phase3',
        stage: 'BreakingNews',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (
      todo.id === 'read-scenario' &&
      todo.locationToVisit === 'phases/phase-1/scenario'
    )
      getLedspClient().sendGameProgressEvent({
        step: 'Phase1',
        stage: 'Scenario',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (
      todo.id === 'read-scenario' &&
      todo.locationToVisit === 'phases/phase-2/scenario'
    )
      getLedspClient().sendGameProgressEvent({
        step: 'Phase2',
        stage: 'Scenario',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (
      todo.id === 'read-scenario' &&
      todo.locationToVisit === 'phases/phase-3/scenario'
    )
      getLedspClient().sendGameProgressEvent({
        step: 'Phase3',
        stage: 'Scenario',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'read-vote' && todo.locationToVisit === 'feedback')
      getLedspClient().sendGameProgressEvent({
        step: 'Endgame',
        stage: 'Feedback',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'read-vote' && todo.locationToVisit === 'achievements')
      getLedspClient().sendGameProgressEvent({
        step: 'Endgame',
        stage: 'Achievements',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'read-vote' && todo.locationToVisit === 'thanks')
      getLedspClient().sendGameProgressEvent({
        step: 'Endgame',
        stage: 'Thanks',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
  },
  isDone(get: GetState<ILocations>) {
    return get().isLocationVisited(todo.locationToVisit);
  },
  onComplete() {},
});

const readAllDocs = (todo: ToDoDescription): IToDo => ({
  onFocus(get, set: SetState<IGame>) {
    const currentPhase = get().currentPhase;

    if (!currentPhase) return;
    else if (currentPhase === 'intro')
      getLedspClient().sendGameProgressEvent({
        step: 'Intro',
        stage: 'ReadDocuments1', // TODO: How do I handle ReadDocuments2?
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (currentPhase === 'phase-1')
      getLedspClient().sendGameProgressEvent({
        step: 'Phase1',
        stage: 'ReadDocuments',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
    else if (currentPhase === 'phase-3')
      getLedspClient().sendGameProgressEvent({
        step: 'Phase3',
        stage: 'ReadDocuments',
        gameId: get().gameId,
        playerId: get().playerId,
        teamId: get().team,
        eventType: 'game-stage-entered',
      });
  },
  isDone(get: GetState<IDocs>) {
    return get().areAllDocsRead();
  },
  onComplete() {},
});

const selectAvatar = (todo: ToDoDescription): IToDo => ({
  onFocus(get: GetState<ILocations>) {
    get().openLocation('avatar');

    // TODO: How to retrieve gameId, playerId, team?
    getLedspClient().sendGameProgressEvent({
      step: 'Intro',
      stage: 'AvatarSelection',
      gameId: 'get().gameId',
      playerId: 'get().playerId',
      teamId: 'get().team',
      eventType: 'game-stage-entered',
    });
  },
  isDone(get: GetState<IAvatar>) {
    return get().areAllAvatarsSelected();
  },
  onComplete(get: GetState<IGame>) {
    remote.startGamePhase(get().gameId, 'intro-2');
  },
});

const vote = (todo: ToDoDescription<VotingPayload>): IToDo => ({
  onFocus(get: GetState<ILocations>) {
    get().openLocation('avatar'); // TODO: ??

    // TODO: How to retrieve gameId, playerId, team?
    if (todo.id === 'vote-phase-1-survey')
      getLedspClient().sendGameProgressEvent({
        step: 'Phase1',
        stage: 'Survey',
        gameId: 'get().gameId',
        playerId: 'get().playerId',
        teamId: 'get().team',
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'vote-phase-2-survey')
      getLedspClient().sendGameProgressEvent({
        step: 'Phase2',
        stage: 'Survey',
        gameId: 'get().gameId',
        playerId: 'get().playerId',
        teamId: 'get().team',
        eventType: 'game-stage-entered',
      });
    else if (todo.id === 'vote-phase-3-survey')
      getLedspClient().sendGameProgressEvent({
        step: 'Phase3',
        stage: 'Survey',
        gameId: 'get().gameId',
        playerId: 'get().playerId',
        teamId: 'get().team',
        eventType: 'game-stage-entered',
      });
  },
  isDone(get: GetState<IVoting & ILogger>) {
    return todo.payload.sessions.every(s => {
      get().log(`checking ${s}: ${get().isSessionEnded(s, Date.now())}`);
      return get().isSessionEnded(s, Date.now());
    });
  },
  onComplete(get: GetState<IGame>) {
    remote.startGamePhase(get().gameId, todo.payload.gotoPhase);
  },
});

const mockTodo = (todo: ToDoDescription): IToDo => ({
  onFocus(get: GetState<ILogger>, set: SetState<ILogger>) {
    get().log(`mock todo focused, ${todo.type}/${todo.id}`);
  },
  isDone(get: GetState<ILogger>) {
    get().log(`mock todo checked, ${todo.type}/${todo.id}`);
    return false;
  },
  onComplete() {},
});
