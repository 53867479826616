import { log } from './log';

export default class InMemoryCache {
  private static cache: { [key: string]: string | number | Date | boolean } =
    {};

  private static buildKey(collection: string, key: string): string {
    return `${collection}->${key}`;
  }

  public static get(
    collection: string,
    key: string
  ): string | number | Date | boolean {
    log('InMemoryCache', `Getting \`${key}\` from \`${collection}\`...`);

    return this.cache[this.buildKey(collection, key)];
  }

  public static set(
    collection: string,
    key: string,
    value: string | number | Date | boolean
  ): void {
    log(
      'InMemoryCache',
      `Setting \`${key}\` in \`${collection}\` to \`${value}\`...`
    );

    this.cache[this.buildKey(collection, key)] = value;
  }
}
