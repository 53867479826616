import {
  arrayUnion,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { IRemoteChat, MessageDoc } from './apis/chat';
import { db } from './firestore';

const messagesCollection = (postfix: string) => `messages-${postfix}`;

export const chat: IRemoteChat = {
  onMessageSnapshot(channelId, cb) {
    const q = query(collection(db, messagesCollection(channelId)));

    return onSnapshot(q, snapshot => {
      snapshot
        .docChanges()
        .filter(change => change.type === 'added' || change.type === 'modified')
        .map(change => initMessageDoc(change.doc.data()))
        .forEach(cb);
    });
  },

  async markMessageAsRead(channelId, messageId, viewer) {
    const ref = doc(db, messagesCollection(channelId), messageId);
    return updateDoc(ref, {
      viewers: arrayUnion(viewer),
    });
  },

  generateMessageId() {
    return typeof window !== 'undefined' && window?.crypto?.randomUUID
      ? window.crypto.randomUUID()
      : Date.now()
          .toString(36)
          .concat('-')
          .concat(Math.ceil(Math.random() * Date.now()).toString(36));
  },

  async sendMessage(channelId, phase, message) {
    const messageId = this.generateMessageId();

    const ref = doc(db, messagesCollection(channelId), messageId);

    return setDoc(
      ref,
      initMessageDoc({
        ...message,
        messageId,
        channelId,
        phase,
        viewers: [message.sender],
      })
    );
  },
};

const initMessageDoc = (plain: Partial<MessageDoc>): MessageDoc => ({
  channelId: '--null--',
  phase: '--null--',
  messageId: '--null--',
  sender: '--null--',
  content: '',
  viewers: [],
  timestamp: 0,
  ...plain,
});
