import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import getPhaseColor from '../../../utils/get-phase-color';
import SectionTitle from '../section-title';
import TextRevealer from '../text-revealer';
import { useGame } from '../../../stores/game';
import { param } from '../../layout/formatters';

const Phase1Outcomes = () => {
  const { formatMessage } = useIntl();
  const budgetAmount = useGame(({ budgetAmountResult }) =>
    budgetAmountResult()
  );

  return (
    <div>
      <SectionTitle
        label={formatMessage({ id: 'pages:outcomes.title' })}
        color={getPhaseColor('phase-1')}
      />

      <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
        <p className="text-base lg:text-lg xl:text-xl 2xl:text-2xl">
          <TextRevealer
            label={formatMessage({
              id: 'pages:conversation-report.outcomes-phase-1.title',
            })}
          />
        </p>

        <motion.span
          initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
          animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
          transition={{ delay: 2, duration: 1 }}
          className="relative flex items-center justify-center w-1/2 px-6 py-4 mx-auto mt-10 text-2xl font-bold text-center border-2 shadow-xl rounded-3xl lg:mt-12 xl:mt-16 2xl:mt-20 2xl:w-1/3 lg:py-6 xl:py-7 2xl:py-8 xl:px-10 border-teal-50 shadow-teal-100 xl:text-4xl text-hospitaliaTeal-base"
        >
          <CountUp
            start={0}
            end={Number(budgetAmount)}
            decimals={0}
            separator="."
            prefix={Number(budgetAmount) > 0 ? '+' : ''}
          />{' '}
          €
        </motion.span>
      </div>
    </div>
  );
};

export default Phase1Outcomes;
