import { useIntl } from 'react-intl';
import { useGame } from '../../stores/game';
import SectionTitle from '../game/section-title';
import { PlayTimeScreen } from './play-time-screen';
import { Goal } from '../game/goal';
import { motion } from 'framer-motion';
import { dictionaryMapper } from '../utils/dictionary-mapper';
import { useTheme } from '../../stores/theme';

export const TeamGoalsScreen = () => {
  const { formatMessage } = useIntl();
  const teamGoals = useGame(g => g.teamGoals());
  const ownGoals = useGame(g => g.personalGoals());
  const feedback = useGame(s => s.feedback());
  const currentPhase = useGame(({ currentPhase }) => currentPhase);
  const images = useTheme(({ images }) => images);

  const isGameClear = currentPhase === 'conclusions';

  return (
    <PlayTimeScreen>
      <SectionTitle
        label={formatMessage({ id: 'pages:intro-mandate.title' })}
      />

      <div className="px-10 py-2 lg:py-4 xl:py-8 lg:px-32 xl:px-64">
        <div className="text-base xl:text-xl">
          {formatMessage({ id: 'pages:mandate.content' }, dictionaryMapper)}
        </div>
      </div>

      <br />
      <br />

      {isGameClear && (
        <>
          <SectionTitle
            label={formatMessage({
              id: `pages:feedbacks-${feedback}.title`,
            })}
          />
          <p className="text-xl 2xl:text-4xl max-w-[640px] leading-tight ml-32 mt-16">
            {formatMessage({
              id: `pages:feedbacks-${feedback}.message`,
            })}
          </p>
          <img
            className="opacity-20 absolute"
            style={{
              maxWidth: '40%',
              marginTop: '1em',
              float: 'right',
              bottom: '3em',
              right: '10em',
              zIndex: '-1',
            }}
            src={images(
              formatMessage({
                id: `pages:feedbacks-${feedback}.img.url`,
              })
            )}
            alt={formatMessage({
              id: `pages:feedbacks-${feedback}.img.alt`,
            })}
          />

          <br />
          <br />
        </>
      )}

      <SectionTitle label="Gli obiettivi del tuo team" />
      <div className="flex flex-row w-3/4 mx-auto space-x-16 lg:space-x-20 xl:space-x-24 2xl:space-x-32">
        {teamGoals.map((teamGoal, teamGoalIndex) => (
          <motion.div
            key={teamGoalIndex}
            className="w-1/3 flex items-center flex-col justify-center h-full"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: teamGoalIndex / 1.5 },
            }}
          >
            <Goal
              key={teamGoal.id}
              goal={teamGoal.id}
              prefix="team"
              goalResult={
                isGameClear
                  ? teamGoal.isAchieved
                    ? 'achieved'
                    : 'not-achieved'
                  : undefined
              }
              hideNotes
            />
          </motion.div>
        ))}
      </div>

      <br />
      <br />

      <SectionTitle
        label={formatMessage({ id: 'pages:my-profile-goals.title' })}
      />
      <div className="flex flex-row w-3/4 mx-auto space-x-16 lg:space-x-20 xl:space-x-24 2xl:space-x-32">
        {ownGoals.map((goal, personalGoalIndex) => (
          <motion.div
            key={personalGoalIndex}
            className="w-1/3 flex items-center flex-col justify-center h-full"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 2 + personalGoalIndex / 1.5 },
            }}
          >
            <Goal
              key={goal.id}
              prefix="personal"
              goal={goal.id}
              goalResult={
                isGameClear
                  ? goal.isAchieved
                    ? 'achieved'
                    : 'not-achieved'
                  : undefined
              }
              hideNotes={isGameClear}
            />
          </motion.div>
        ))}
      </div>
    </PlayTimeScreen>
  );
};
