import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGame } from '../../stores/game';
import { PlayerInfo } from '../../stores/game/concepts/player';
import { useTheme } from '../../stores/theme';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import roles from '../contents/roles';
import ServiceScreen from '../service-screen';
import { InvalidGamePlayMessage } from './msg.invalid-game-play';

export const WelcomeMessage: FC<{ gamePlayId: string }> = props => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [info, setInfo] = useState<PlayerInfo>();
  const { formatMessage } = useIntl();

  const playerFromGamePlayId = useGame(
    ({ playerFromGamePlayId }) => playerFromGamePlayId
  );
  const images = useTheme(({ images }) => images);

  useEffect(() => {
    const init = async () => {
      try {
        setInfo(await playerFromGamePlayId(props.gamePlayId));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoading) init();
  }, [playerFromGamePlayId, isLoading, props?.gamePlayId]);

  if (isLoading) return <ServiceScreen text="Loading..." />;

  if (!info.isValid) return <InvalidGamePlayMessage />;

  const role = roles.find(({ shortname }) => shortname === info.role);

  return (
    <>
      <img
        className="w-1/4"
        src={images(buildOptimizedAssetPath('home_logo', ['avif', 'webp']))}
        alt="Home Logo"
      />
      <div className="p-8 text-center uppercase select-none bg-hospitaliaGold-base text-hospitaliaBlue-base rounded-2xl">
        Benvenuto <strong>{info.displayName || info.playerId}</strong>,<br />{' '}
        sei il{' '}
        <strong>{formatMessage({ id: `profiles:${role.id}.title` })}</strong>{' '}
        della squadra <strong>{info.team}</strong>.
      </div>
    </>
  );
};
