import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import produce from 'immer';
import { IAvatar } from '../concepts/avatar';
import { IPlayer } from '../concepts/player';
import { remote } from '../../../remote';
import { IGame } from '../concepts/game';

export const create: StoreSlice<IAvatar, ILogger & IPlayer & IGame> = (
  set,
  get
) => {
  return {
    availableAvatars: new Array(12).fill(0).map((v, i) => `avatar-${i}`),
    avatars: {},

    setPlayerAvatar(playerId: string, avatar: string) {
      set(
        produce((s: IAvatar) => {
          s.avatars[playerId] = avatar;
        })
      );
    },

    selectAvatar(playerId: string, avatar: string) {
      if (get().hasSelectedAvatar(playerId)) return;
      get().log(`avatar selected: player ${playerId}, avatar: ${avatar}`);
      get().setPlayerAvatar(playerId, avatar);
      remote.saveAvatar(get().gameId, playerId, avatar);
    },

    isAvatarSelected(playerId, avatar) {
      return get().avatars[playerId] === avatar;
    },

    hasSelectedAvatar(playerId: string) {
      return !!get().avatars[playerId];
    },

    hasNotSelectedAvatar(playerId: string) {
      return !get().hasSelectedAvatar(playerId);
    },

    areAllAvatarsSelected() {
      return get().players.every(get().hasSelectedAvatar);
    },

    playersWithoutAvatarCount() {
      return get().players.filter(get().hasNotSelectedAvatar).length;
    },
  };
};
