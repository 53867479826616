import { GetState } from 'zustand';
import getLedspClient from '../../../../utils/get-ledsp-client';
import { ICountdown } from '../../concepts/countdown';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IPlayer } from '../../concepts/player';
import { IToDos } from '../../concepts/to-dos';
import { IVoting } from '../../concepts/voting';
import { IPhaseExecutorFn } from '../game';

export const startConclusions: IPhaseExecutorFn = (
  get: GetState<
    IGame & IDocs & IToDos & ILocations & IVoting & IPlayer & ICountdown
  >
) => {
  get().openLocation('feedback');
  get().openLocation('achievements');
  get().openLocation('thanks');

  get().clearToDos();
  ToDos.forEach(get().appendToDo);
};

const ToDos = [
  {
    id: 'read-vote',
    type: 'read-info',
    locationToVisit: 'feedback',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-vote',
    type: 'read-info',
    locationToVisit: 'achievements',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-vote',
    type: 'read-info',
    locationToVisit: 'thanks',
    payload: null,
    nudge: '',
  },
];
