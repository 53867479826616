import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

interface IProps {
  selectedOption?: string;
  options: string[];
  currentQuestion: string;
  handleChange?: (value: string) => void;
  canVote?: boolean;
}

export default function Phae3VotePicker({
  selectedOption,
  options,
  handleChange,
  currentQuestion,
  canVote,
}: IProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="w-full h-full">
      <div className="w-full max-w-md mx-auto">
        <div>
          <label className="sr-only">-</label>
          <div className="space-y-2">
            {options.map(option => {
              const checked: boolean = selectedOption === option;

              return (
                <motion.span
                  key={option}
                  whileHover={{ scale: 1.04 }}
                  whileTap={{ scale: 0.98 }}
                  animate={{ scale: 1 }}
                  className={clsx(
                    'relative flex px-4 py-3 border-2 rounded-lg shadow cursor-pointer border-neutral-200 hover:border-blue-200 xl:px-5 xl:py-4 focus:outline-none',
                    {
                      'pointer-events-none': !canVote,
                    },
                    checked
                      ? 'bg-hospitaliaTeal-base bg-opacity-75 text-white'
                      : 'bg-white'
                  )}
                  onClick={() => handleChange(option)}
                >
                  <div className="flex flex-row items-center w-full space-x-4">
                    <div className="text-white shrink-0">
                      <CheckIcon
                        className={clsx(
                          'h-6 w-6 transition-all duration-200 ease-in-out transform-gpu',
                          'checked'
                            ? 'opacity-100 scale-100'
                            : 'opacity-0 scale-50'
                        )}
                      />
                    </div>
                    <div className="flex items-center">
                      <div className="text-sm">
                        <p className="text-xs leading-none transition-none xl:text-lg xl:leading-tight">
                          {formatMessage({
                            id: `pages:decisions-${currentQuestion}.answer-${option}`,
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
