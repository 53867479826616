import qs from 'query-string';
import { QSDecode } from 'ledsp-sdk';

export default function useGameQueryParams() {
  const parsed = qs.parse(location.search);
  const ledsp = parsed?.ledsp?.toString();

  try {
    return {
      gamePlayInfoId: ledsp ? QSDecode(ledsp)?.id : undefined,
      demoId: parsed?.demoId?.toString(),
      rp: parsed?.rp?.toString(),
    };
  } catch (error) {
    console.error('Ledsp query string is invalid', error);
  }
  return {
    gamePlayInfoId: '--null--',
    demoId: parsed?.demoId?.toString(),
    rp: parsed?.rp?.toString(),
  };
}
