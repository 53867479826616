import { useState, createContext, ReactNode } from 'react';
import Modal from '../../comps/game/modal';

interface ModalParams {
  title?: string;
  content?: JSX.Element | JSX.Element[] | ReactNode;
  handleClose?: () => any;
}

export const ModalContext = createContext<{
  setModalInfo: (params?: ModalParams) => any;
}>(undefined);

interface IProps {
  children: JSX.Element | JSX.Element[] | ReactNode;
}

export const ModalProvider = ({ children }: IProps) => {
  const [modalInfo, setModalInfo] = useState<ModalParams>();

  return (
    <ModalContext.Provider value={{ setModalInfo }}>
      {children}

      <Modal
        isOpen={!!modalInfo?.content}
        handleClose={() => setModalInfo(undefined)}
        title={modalInfo?.title}
      >
        {modalInfo?.content}
      </Modal>
    </ModalContext.Provider>
  );
};
