import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { useChat } from '../../stores/chat';
import { useGame } from '../../stores/game';
import { useTheme } from '../../stores/theme';
import roles from '../contents/roles';

export const Chat = ({ disabled = false, readOnly = false }) => {
  const images = useTheme(({ images }) => images);
  const messages = useChat(({ messages }) => messages);
  const sendMessage = useChat(({ sendMessage }) => sendMessage);
  const markAsRead = useChat(({ markAsRead }) => markAsRead);
  const init = useChat(({ init }) => init);
  const gameId = useGame(({ gameId }) => gameId);
  const currentPhase = useGame(({ currentPhase }) => currentPhase);
  const playerId = useGame(({ playerId }) => playerId);

  const [content, setContent] = useState('');
  const [isChatOpen, setChatOpen] = useState<boolean>(false);

  const notReadCount = messages.filter(
    m => !m.isRead && m.sender !== '--divider--'
  ).length;

  const [scrolling, setScrolling] = useState<boolean>(false);
  const messagesContainer = useRef<HTMLDivElement>(null);
  const BOTTOM_SCROLL_TOLERANCE: number = 80;
  const MESSAGE_MARK_AS_READ_TIMEOUT: number = 2500;
  const [readTimeout, setReadTimeout] = useState<NodeJS.Timeout>();

  const isChatAtBottom = () =>
    isChatOpen &&
    !!messagesContainer.current &&
    messagesContainer.current.scrollTop >=
      messagesContainer?.current?.scrollHeight -
        messagesContainer.current.offsetHeight -
        BOTTOM_SCROLL_TOLERANCE;

  const chatNotYetScrollable = () =>
    isChatOpen &&
    messagesContainer.current &&
    (messagesContainer?.current?.scrollHeight === 0 ||
      messagesContainer?.current?.scrollHeight -
        messagesContainer?.current?.offsetHeight ===
        0);

  const scrollMessagesContainer = (to?: number) => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTop =
        to || messagesContainer?.current?.scrollHeight;

      setTimeout(() => {
        readMessages();
      }, MESSAGE_MARK_AS_READ_TIMEOUT);
    }
  };

  const readMessages = () => {
    messages.filter(m => !m.isRead).forEach(m => markAsRead(m.messageId));
  };

  useEffect(() => {
    // TODO add a loader if the chat is not initialized
    init(gameId, playerId);
  }, [gameId, playerId]);

  useEffect(() => {
    // Automatically mark messages as read when there are too few
    // messages in the chat to scroll all the way to the bottom.
    if (isChatOpen)
      if (isChatAtBottom() || chatNotYetScrollable())
        setReadTimeout(
          setTimeout(() => {
            readMessages();
          }, MESSAGE_MARK_AS_READ_TIMEOUT)
        );
      else if (
        typeof document !== 'undefined' &&
        messagesContainer?.current?.scrollTop === 0
      )
        if (document.getElementById('unread-messages-separator')?.offsetTop) {
          messagesContainer.current.scrollTo(
            0,
            document.getElementById('unread-messages-separator')!.offsetTop - 80
          );
        } else scrollMessagesContainer();
  }, [isChatOpen, notReadCount]);

  const dispatchNewMessage = () => {
    if (content.trim() !== '') {
      sendMessage(getPhaseName(currentPhase), content);
      setContent('');
    }
  };

  const getPhaseName = (currentPhase: string) => {
    if (currentPhase.includes('phase-1')) return 'phase-1';
    if (currentPhase.includes('phase-2')) return 'phase-2';
    if (currentPhase.includes('phase-3')) return 'phase-3';
    return '--null--';
  };

  const sendMessageFromEnter = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      dispatchNewMessage();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (notReadCount === 0) scrollMessagesContainer();
  }, [messages.length]);

  const [toastNotification, setToastNotification] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const key = `${gameId}.${playerId}.chat-toast-notification`;
    if (readOnly) {
      setToastNotification(null);
      localStorage?.setItem(key, 'false');
    } else if (!disabled && localStorage?.getItem(key) !== 'true') {
      localStorage?.setItem(key, 'true');
      setToastNotification(
        setTimeout(() => {
          setToastNotification(null);
        }, 5000)
      );
    }
  }, [readOnly, disabled]);

  const oldMessages = messages
    .filter(m => m.isRead)
    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
  const newMessages = messages
    .filter(m => !m.isRead)
    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

  return (
    <>
      <span
        className={clsx(
          'relative w-2/3 xl:w-1/2 flex items-center justify-center aspect-square rounded-full bg-none p-2 border transition-all duration-200 ease-in-out cursor-pointer group',
          isChatOpen
            ? 'border-neutral-300 shadow-[rgba(0,0,0,0.1)_0px_0px_10px_0px] bg-neutral-100'
            : !disabled
            ? 'border-transparent hover:shadow-[rgba(0,0,0,0.08)_0px_0px_10px_0px]'
            : 'cursor-not-allowed'
        )}
        onClick={() => !disabled && setChatOpen(!isChatOpen)}
      >
        <img
          src={images('navigation-bar/Chat.svg')}
          alt="Chat"
          title="Chat"
          style={{
            filter: !disabled ? 'none' : 'saturate(0)',
          }}
        />
        {!disabled && (
          <NotReadBadge
            notReadCount={notReadCount}
            className="w-5 h-5 text-xs top-4 right-4"
          />
        )}
      </span>

      {isChatOpen && (
        <motion.div
          initial={{ opacity: 0, y: 400 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, type: 'tween' }}
          layoutId="chat-container"
          className="fixed bottom-0 h-[70vh] w-[30vw] shadow-xl shadow-black/40 border-4 border-blue-100 right-[8.3%] rounded-t-xl bg-white bg-opacity-90 backdrop-blur-sm backdrop-filter z-50"
        >
          <div
            className="relative h-[90%] flex flex-col overflow-y-auto scrollbar-thin p-4"
            ref={messagesContainer}
            onScroll={() => {
              if (isChatOpen && isChatAtBottom())
                setReadTimeout(
                  setTimeout(() => {
                    setScrolling(false);
                    readMessages();
                  }, MESSAGE_MARK_AS_READ_TIMEOUT)
                );
              else setScrolling(true);
            }}
          >
            {oldMessages.map(message => {
              const role = roles.find(
                role => role.shortname === message.sender.split('.')[0]
              );
              const isMessageMine = message.sender === playerId;
              return (
                <Message
                  key={message.messageId}
                  {...message}
                  role={role}
                  isMessageMine={isMessageMine}
                />
              );
            })}
            {notReadCount > 0 && (
              <div
                id="unread-messages-separator"
                className="p-1 text-md text-center my-4 bg-[#ddd] rounded-md"
              >
                {notReadCount === 1 ? (
                  <div>1 Messaggio non letto</div>
                ) : (
                  <div>{notReadCount} Messaggi non letti</div>
                )}
              </div>
            )}
            {newMessages.map(message => {
              const role = roles.find(
                role => role.shortname === message.sender.split('.')[0]
              );
              const isMessageMine = message.sender === playerId;
              return (
                <Message
                  key={message.messageId}
                  {...message}
                  role={role}
                  isMessageMine={isMessageMine}
                />
              );
            })}
          </div>
          <div className="bg-neutral-200 flex flex-row items-center text-neutral-600 text-sm h-[10%] p-1 w-full relative">
            {readOnly ? (
              <span className="ml-2">
                La Chat è disabilitata in questa fase.
              </span>
            ) : (
              <>
                <textarea
                  value={content}
                  disabled={disabled}
                  className={clsx(
                    'text-xs w-5/6 h-full p-2 bg-transparent outline-none border-none resize-none rounded-lg bg-neutral-100'
                  )}
                  onChange={e => setContent(e.target.value)}
                  onKeyDown={e => sendMessageFromEnter(e)}
                />
                <div className="flex items-center justify-center w-1/5 h-full p-2">
                  <button
                    onClick={() => dispatchNewMessage()}
                    className="flex items-center justify-center w-full transition-all duration-200 ease-in-out scale-100 rounded-full aspect-square hover:scale-110 transform-gpu"
                  >
                    <img className="h-8" src={images('paper-plane.svg')} />
                  </button>
                </div>
              </>
            )}

            {!isChatAtBottom() && (
              <div
                className="absolute h-6 w-12 -top-8 rounded-md right-6 cursor-pointer text-center bg-[#eee] hover:scale-110"
                onClick={() => scrollMessagesContainer()}
              >
                <NotReadBadge
                  notReadCount={notReadCount}
                  className="-top-4 -right-3"
                />
                <svg
                  viewBox="0 0 19 20"
                  width="19"
                  height="20"
                  className="inline-block align-middle"
                >
                  <path
                    fill="currentColor"
                    d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </motion.div>
      )}
      {toastNotification && (
        <motion.div
          initial={{ right: '-64rem' }}
          animate={{ right: '0rem' }}
          transition={{ duration: 1, type: 'spring' }}
          className="fixed right-0 flex items-center justify-between w-64 h-12 p-4 font-bold text-white rounded-l-lg bottom-4 bg-hospitaliaTeal-light z-100"
        >
          La chat è aperta!
          <span
            className="flex items-center justify-center w-6 h-6 p-1 rounded-full cursor-pointer hover:bg-opacity-70 hover:bg-hospitaliaGold-base"
            onClick={() => setToastNotification(null)}
          >
            x
          </span>
        </motion.div>
      )}
    </>
  );
};

const NotReadBadge = ({ notReadCount, className = '' }) => (
  <>
    {notReadCount > 0 && (
      <span
        className={clsx(
          'absolute w-6 h-6 border border-white rounded-full z-10 bg-red-500 text-white flex items-center justify-center top-6 right-6',
          className
        )}
      >
        {notReadCount}
      </span>
    )}
    {notReadCount > 0 && (
      <span
        className={clsx(
          'absolute block w-6 h-6 border border-white rounded-full z-10 bg-red-500 animate-ping top-6 right-6',
          className
        )}
      />
    )}
  </>
);

const Message = ({
  messageId,
  sender,
  isMessageMine = false,
  isRead = false,
  role,
  timestamp,
  content,
}) => {
  const { formatMessage } = useIntl();

  return sender === '--divider--' ? (
    <div
      className={clsx(
        'flex items-center text-sm uppercase text-[#979797] my-4 font-semibold',
        "before:content-[' '] before:h-[2px] before:bg-[#dcdcdc] before:flex-grow before:mr-1",
        "after:content-[' '] after:h-[2px] after:bg-[#dcdcdc] after:flex-grow after:ml-1"
      )}
    >
      {`Fase ${content?.replace(/^\D+/g, '')}`}
    </div>
  ) : (
    <span
      key={messageId}
      className={clsx(
        'w-[80%] relative  mb-2 py-3 px-4 text-white',
        isMessageMine
          ? 'rounded-br-2xl rounded-l-2xl bg-hospitaliaGold-base self-end'
          : 'rounded-bl-2xl rounded-r-2xl bg-[#1e65af]'
      )}
    >
      <span
        className={clsx(
          'absolute w-2 h-4 transform-gpu top-0',
          isMessageMine
            ? 'right-0 -rotate-45 bg-hospitaliaGold-base'
            : 'left-0 rotate-45 bg-[#1e65af]'
        )}
      />

      {!isRead && (
        <span className="absolute z-10 block w-4 h-4 bg-red-500 border border-white rounded-full -top-1 -right-1" />
      )}
      {!isRead && (
        <span className="absolute z-10 block w-4 h-4 bg-red-500 border border-white rounded-full -top-1 -right-1 animate-ping" />
      )}
      <span className="flex flex-row justify-between w-full text-xs">
        {role && (
          <span className="flex flex-row space-x-1 font-bold text-left">
            <p>
              {formatMessage({ id: `profiles:${role.id}.name` })} -{' '}
              {formatMessage({ id: `profiles:${role.id}.title` })}
            </p>
          </span>
        )}
        <p
          className="font-light text-right"
          title={`Messaggio delle ${DateTime.fromMillis(timestamp).toFormat(
            'HH:mm'
          )}`}
        >
          {DateTime.fromMillis(timestamp).toFormat('HH:mm')}
        </p>
      </span>
      <p className="mt-2 text-sm leading-tight text-left">
        {content || '-- missing content --'}
      </p>
    </span>
  );
};
