import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useGame } from '../../../stores/game';
import { useTheme } from '../../../stores/theme';
import extractPhase from '../../../utils/extract-phase';
import getPhaseColor from '../../../utils/get-phase-color';
import SectionTitle from '../section-title';
import Phase3Voting from './phase-3.voting';
import { price } from '../../layout/formatters';

export const VoteSelection: FC<{ session: string }> = ({ session }) => {
  const { formatMessage: f } = useIntl();

  const availableVotes = useGame(({ availableVotes }) =>
    availableVotes(session)
  );
  const missingVotesCount = useGame(({ missingVotesCount }) =>
    missingVotesCount(session)
  );
  const playerId = useGame(({ playerId }) => playerId);
  const issueVote = useGame(({ issueVote }) => issueVote);
  const issuedVote = useGame(({ readVote }) => readVote(session, playerId));
  const images = useTheme(({ images }) => images);
  const currentPhase: string = useGame(({ currentPhase }) => currentPhase);
  const budgetAmountResult = useGame(({ budgetAmountResult }) =>
    budgetAmountResult()
  );

  const [selectedVote, selectVote] = useState<string>(issuedVote);

  const isVoteSelectionDisabled: boolean = issuedVote !== '';

  if (
    currentPhase &&
    ![
      'phase-1-voting',
      'phase-1-outcomes',
      'phase-2-voting',
      'phase-2-outcomes',
      'phase-3-voting',
      'phase-3-outcomes',
    ].includes(currentPhase)
  )
    return (
      <div className="flex items-center justify-center w-full h-full">
        <motion.p
          initial={{ scale: 1.4, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: 'spring', bounce: 0.4 }}
          className="text-4xl text-slate-400"
        >
          Votazioni non attive.
        </motion.p>
      </div>
    );

  return (
    <>
      <SectionTitle
        label={f({ id: `pages:phases-${extractPhase(currentPhase)}.title` })}
        color={getPhaseColor(currentPhase)}
      />

      {['phase-3-voting', 'phase-3-outcomes'].includes(currentPhase) ? (
        <Phase3Voting
          currentPhase={currentPhase}
          session={session}
          playerId={playerId}
        />
      ) : (
        <div className="flex flex-col max-h-full px-10 py-1 lg:px-20 xl:px-32 2xl:px-64">
          <p className="mb-2 text-base leading-tight lg:text-lg xl:text-xl 2xl:text-2xl lg:leading-tight xl:leading-tight 2xl:leading-tight 2xl:mb-6">
            {f(
              { id: `pages:decisions-${extractPhase(currentPhase)}.title` },
              { budgetAmountResult: price(budgetAmountResult) }
            )}{' '}
            {currentPhase === 'phase-1-voting' &&
              f({ id: 'pages:meetings-first-meeting.notes' })}
          </p>
          <ul
            className="w-1/2 p-2 m-auto space-y-4 text-center 2xl:w-2/3"
            style={isVoteSelectionDisabled ? { pointerEvents: 'none' } : {}}
          >
            {availableVotes.map(vote => (
              <motion.li
                key={vote}
                whileHover={{ scale: 1.006, transition: { duration: 0.16 } }}
                whileTap={{ scale: 0.994, transition: { duration: 0.04 } }}
                className={clsx(
                  'border-[3px] 2xl:border-4 rounded-2xl 2xl:rounded-3xl px-4 xl:px-6 2xl:px-8 py-2 xl:py-3 2xl:py-4 cursor-pointer',
                  selectedVote === vote
                    ? isVoteSelectionDisabled
                      ? 'text-white bg-hospitaliaTeal-base border-hospitaliaTeal-base shadow-xl shadow-teal-100'
                      : 'text-gray-900 bg-white border-hospitaliaTeal-base shadow-xl shadow-teal-100'
                    : 'text-gray-900 bg-white border-neutral-200 shadow-xl hover:border-teal-600/20',
                  {
                    'opacity-50 blur-[0.01]':
                      isVoteSelectionDisabled && selectedVote !== vote,
                  }
                )}
                onClick={() => selectVote(vote)}
              >
                <p className="text-base xl:text-xl 2xl:text-3xl">
                  {f({
                    id: `decisions:${extractPhase(
                      currentPhase
                    )}.option:${getLabelID(vote, budgetAmountResult)}`,
                  })}
                </p>
              </motion.li>
            ))}
          </ul>

          <p className="mt-2 text-sm text-center opacity-50 xl:text-md 2xl:mt-6 font-meduium">
            {f({
              id: `pages:decisions-${extractPhase(currentPhase)}.message`,
            })}
          </p>

          <motion.button
            whileHover={
              selectedVote !== '' &&
              !isVoteSelectionDisabled && {
                y: '-2px',
                transition: { duration: 0.2 },
              }
            }
            whileTap={{ y: '1px', transition: { duration: 0.05 } }}
            className="relative flex flex-row items-center py-2 pl-4 pr-8 m-auto mt-8 space-x-8 text-xs font-bold text-white uppercase rounded-full shadow-lg cursor-pointer select-none 2xl:text-base xl:text-sm xl:py-4 xl:mt-10 2xl:mt-12 disabled:bg-neutral-300 disabled:cursor-default disabled:shadow-none bg-hospitaliaTeal-base hover:shadow-xl hover:bg-hospitaliaTeal-light active:bg-hospitaliaTeal-dark"
            disabled={selectedVote === '' || isVoteSelectionDisabled}
            onClick={() => {
              issueVote({
                session,
                time: Date.now(),
                vote: selectedVote,
                voter: playerId,
                motivation: '',
              });
            }}
            style={{ opacity: !isVoteSelectionDisabled ? 1! : 0 }}
          >
            <img src={images('checkmark.svg')} alt="Checkmark" />
            <p>
              {selectedVote !== '' && !isVoteSelectionDisabled
                ? 'Conferma Voto'
                : selectedVote === '' && !isVoteSelectionDisabled
                ? "Scegli un'opzione"
                : '-'}
            </p>
          </motion.button>

          <p className="mx-auto mt-6 text-base text-center xl:text-xl xl:mt-8">
            Rimangono ancora <strong>{missingVotesCount}</strong> voti da
            collezionare.
          </p>
        </div>
      )}
    </>
  );
};

export const getLabelID = (choiceID: string, budgetAmountResult: number) => {
  switch (choiceID) {
    case 'ChangeOMABudget': {
      if (budgetAmountResult && budgetAmountResult > 0)
        return 'IncreaseOMABudget';
      else return 'DecreaseOMABudget';
    }
    case 'ChangeMEPOBudget': {
      if (budgetAmountResult && budgetAmountResult > 0)
        return 'IncreaseMEPOBudget';
      else return 'DecreaseMEPOBudget';
    }
    default:
      return choiceID;
  }
};
