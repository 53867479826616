import { GetState } from 'zustand';
import { ICountdown } from '../../concepts/countdown';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IToDos } from '../../concepts/to-dos';
import { IPhaseExecutorFn } from '../game';

export const secondMeeting: IPhaseExecutorFn = (
  get: GetState<IGame & IDocs & IToDos & ILocations & ICountdown>
) => {
  get().startMeeting('second-leg');
  get().startCountdown(get().currentPhaseStartTime, 30 * 60 * 1000);
};
