import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../../utils/store-slice';
import {
  IssuedVote,
  IVoting,
  VoteRequest,
  VotingSession,
} from '../concepts/voting';
import produce from 'immer';
import { IGame } from '../concepts/game';
import { IPlayer } from '../concepts/player';
import { IRemoteVote } from '../../../remote/apis/vote';

interface IPrivate {
  addIssueVote(issuedVote: IssuedVote): void;
}

export const create =
  (
    remote: IRemoteVote
  ): StoreSlice<IVoting & IPrivate, ILogger & IGame & IPlayer> =>
  (set, get) => {
    return {
      votingSessions: [],
      votes: [],

      addIssueVote(iv) {
        get().log(
          `register vote «${iv.vote}» on session «${iv.session}» from «${iv.voter}»`
        );

        set(
          produce((s: IVoting) => {
            if (s.votes.find(sameIssuedVote(iv))) return s;
            s.votes.push(iv);
          })
        );
      },

      initVoting() {
        get().log(
          `Watching votes for gameRecordId: \`${get().gameRecordId}\`...`
        );

        remote.onVoteSnapshot(get().gameRecordId, get().addIssueVote);
      },

      startVotingSession(id, opts) {
        if (get().votingSessions.find(sessionWithId(id))) return;

        set(
          produce((s: IVoting) => {
            s.votingSessions.push({
              id,
              ...opts,
            });
          })
        );
      },

      issueVote(request) {
        get().log(
          `issuing vote «${request.vote}» on session «${request.session}» from «${request.voter}»`
        );

        const session = get().votingSessions.find(
          sessionWithId(request.session)
        );

        if (!session) {
          throw new Error(`Session ${request.session} doesn't exists`);
        }

        if (!session.availableVotes.includes(request.vote)) {
          throw new Error(
            `Vote «${
              request.vote
            }» is invalid, expected [${session.availableVotes.join(', ')}]`
          );
        }

        if (get().votes.find(issuedVoteForRequest(request))) {
          get().log('a vote was recorded already');
          return;
        }

        const issuedVote = fromVoteRequest(request);
        get().addIssueVote(issuedVote);

        remote
          .saveVote(
            get().gameId,
            `${[get().playerId, request.session].join(':')}`,
            issuedVote
          )
          .then(() => console.log('Vote saved successfully.'))
          .catch(error => console.error(error));
      },

      issueAbstention(request) {
        if (get().votes.find(issuedVoteForRequest(request))) return;

        set(
          produce((s: IVoting) => {
            s.votes.push({
              isAbstained: true,
              session: request.session,
              voter: request.voter,
              motivation: '',
              vote: '',
            });
          })
        );
      },

      hasVoted(session: string, voter: string) {
        const issuedVote = get().votes.find(
          issuedVoteForRequest({ session, voter })
        );

        return !!(issuedVote && issuedVote.vote);
      },

      hasProvidedMotivation(session: string, voter: string) {
        const issuedVote = get().votes.find(
          issuedVoteForRequest({ session, voter })
        );
        return !!(issuedVote && issuedVote.motivation);
      },

      areAllVotesCollected(id) {
        const session = get().votingSessions.find(sessionWithId(id));

        if (!session) {
          get().error(`Voting Session ${id} doesn't exists`);
          return false;
        }

        return session.voters.every(voter => get().hasVoted(id, voter));
      },

      areAllMotivationsProvided(id) {
        const session = get().votingSessions.find(sessionWithId(id));

        if (!session) {
          get().error(`Voting Session ${id} doesn't exists`);
          return false;
        }

        return session.voters.every(voter =>
          get().hasProvidedMotivation(id, voter)
        );
      },

      isSessionEnded(id, time) {
        const session = get().votingSessions.find(sessionWithId(id));
        if (!session) return false;

        if (session.isMotivationRequired)
          return (
            get().areAllVotesCollected(id) &&
            get().areAllMotivationsProvided(id)
          );

        return get().areAllVotesCollected(id);
      },

      missingVotesCount(id) {
        const session = get().votingSessions.find(sessionWithId(id));

        if (!session) {
          get().error(`Voting Session ${id} doesn't exist`);
          return 0;
        }

        console.log(`Session voters: ${session.voters}`);

        return session.voters.filter(voter => !get().hasVoted(id, voter))
          .length;
      },

      availableVotes(id) {
        const session = get().votingSessions.find(sessionWithId(id));

        if (!session) {
          get().error(`Voting Session ${id} doesn't exists`);
          return [];
        }

        return session.availableVotes;
      },

      isVoter(id, voter) {
        const session = get().votingSessions.find(sessionWithId(id));
        return session.voters.includes(voter);
      },

      isVoterInAtLeastOne(sessions: string[], voter: string) {
        return sessions.some(session => get().isVoter(session, voter));
      },

      readVote(session, voter) {
        const vote = get().votes.find(
          v => v.voter === voter && v.session === session
        );

        if (!vote) return '';

        return vote.vote;
      },

      readMotivation(session, voter) {
        const vote = get().votes.find(
          v => v.voter === voter && v.session === session
        );

        if (!vote) return '';

        return vote.motivation;
      },

      readNotAbstainedVotes(session) {
        return get()
          .votes.filter(v => v.session === session && !v.isAbstained)
          .map(({ vote }) => vote);
      },

      majority(id, primaryVoter) {
        const session = get().votingSessions.find(sessionWithId(id));

        if (!session) {
          get().error(`Voting Session ${id} doesn't exists`);
          return '--null--';
        }

        const votes = get().votes.filter(withSession(id));
        const primary = votes.find(withVoter(primaryVoter));
        const primaryVote = (primary && primary.vote) || '';

        const mappedVotes = session.availableVotes
          .map(vote => ({
            vote,
            count: votes.filter(withVote(vote)).length,
          }))
          .map(c => {
            if (c.vote === primaryVote) return { ...c, count: c.count + 1 };
            else return c;
          })
          .sort((a, b) => b.count - a.count);

        const first = mappedVotes[0];

        if (first) return first.vote;
        else return '--null--';
      },

      mean(ids) {
        const votes = get().votes.filter(v => ids.includes(v.session));
        return (
          votes.reduce((sum, v) => (sum += Number(v.vote) || 0), 0) /
          votes.length
        );
      },
    };
  };

const withSession = (id: string) => (target: { session: string }) =>
  target.session === id;

const withVoter = (id: string) => (target: { voter: string }) =>
  target.voter === id;

const withVote = (id: string) => (target: { vote: string }) =>
  target.vote === id;

const sessionWithId = (id: string) => (target: VotingSession) =>
  target.id === id;

const issuedVoteForRequest =
  (req: { session: string; voter: string }) => (target: IssuedVote) =>
    req.session === target.session && req.voter === target.voter;

const sameIssuedVote = (issuedVote: IssuedVote) => (target: IssuedVote) =>
  issuedVote.isAbstained === target.isAbstained &&
  issuedVote.session === target.session &&
  issuedVote.vote === target.vote &&
  issuedVote.voter === target.voter;

const fromVoteRequest = (request: VoteRequest): IssuedVote => ({
  isAbstained: false,
  session: request.session,
  vote: request.vote,
  voter: request.voter,
  motivation: request.motivation,
});
