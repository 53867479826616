import { useIntl } from 'react-intl';
import { DocsArchive } from '../game/docs';
import { PlayTimeScreen } from './play-time-screen';

export const DocsInventoryScreen = () => {
  const { formatMessage } = useIntl();

  return (
    <PlayTimeScreen>
      <div className="p-2 xl:p-7 2xl:p-8 h-full">
        <h1 className="text-xl xl:text-2xl 2xl:text-3xl uppercase text-hospitaliaTeal-base font-bold text-center">
          {formatMessage({ id: 'pages:documents.title' })}
        </h1>

        <DocsArchive />
      </div>
    </PlayTimeScreen>
  );
};
