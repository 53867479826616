import { useRoute } from 'wouter';
import { VoteSelection } from '../game/voting';
import { PlayTimeScreen } from './play-time-screen';

export const PhaseVoteScreen = () => {
  const [match, params] = useRoute<RouteParams>(
    '/:gamePlayId/phases/:phaseId/vote'
  );

  if (!match)
    return <span>This component is not loaded under the correct path</span>;

  return (
    <PlayTimeScreen>
      <VoteSelection session={params.phaseId} />
    </PlayTimeScreen>
  );
};

type RouteParams = {
  gamePlayId: string;
  phaseId: string;
};
