import { motion } from 'framer-motion';

interface IProps {
  initialDelay?: number;
  label: string;
}

const TextRevealer = ({ initialDelay = 0, label }: IProps) => (
  <>
    {label.split('').map((character, characterIndex) => (
      <motion.span
        key={characterIndex}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: (initialDelay ?? 0.4) + characterIndex / 80 }}
      >
        {character}
      </motion.span>
    ))}
  </>
);

export default TextRevealer;
