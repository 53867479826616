import { useEffect, useState } from 'react';
import { useRoute } from 'wouter';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { useGame } from '../../stores/game';
import { useTheme } from '../../stores/theme';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import roles from '../contents/roles';
import TextRevealer from '../game/text-revealer';
import { PlayTimeScreen } from './play-time-screen';
import ServiceScreen from '../service-screen';
import { price } from '../layout/formatters';
import { getLabelID } from '../game/voting';

export const MeetingScreen = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [roleInfo, setRoleInfo] = useState<{
    id: string;
    shortname: string;
    iconName: string;
  }>();

  const { formatMessage } = useIntl();
  const currentPhase = useGame(({ currentPhase }) => currentPhase);
  const [_match, params] = useRoute<{ gamePlayId: string }>(
    '/:gamePlayId/:rest*'
  );
  const playerFromGamePlayId = useGame(
    ({ playerFromGamePlayId }) => playerFromGamePlayId
  );
  const images = useTheme(({ images }) => images);
  const meetingRooms = useGame(({ meetingRooms }) => meetingRooms);
  const budgetAmountResult = useGame(({ budgetAmountResult }) =>
    budgetAmountResult()
  );
  const availableAllocationVotes = useGame(({ availableAllocationVotes }) =>
    availableAllocationVotes()
  );

  const meetingRoom = meetingRooms[roleInfo?.shortname];

  useEffect(() => {
    const init = async () => {
      try {
        const playerData = await playerFromGamePlayId(params.gamePlayId);

        setRoleInfo(
          roles.find(({ shortname }) => shortname === playerData.role)
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoading) init();
  }, [playerFromGamePlayId, isLoading]);

  if (isLoading) return <ServiceScreen text="Loading..." />;

  if (!roleInfo) return <p>Error retrieving role info.</p>;

  return (
    <PlayTimeScreen>
      <div
        className="py-2 lg:py-4 xl:py-6 2xl:py-8 flex flex-col h-[90%] px-10 lg:px-20 xl:px-32 2xl:px-64"
        style={{
          backgroundImage:
            currentPhase === 'first-meeting'
              ? `url(${images(
                  buildOptimizedAssetPath('meetings/meeting_1', ['avif', 'webp'])
                )})`
              : currentPhase === 'second-meeting'
              ? `url(${images(
                  buildOptimizedAssetPath('meetings/meeting_2', ['avif', 'webp'])
                )})`
              : 'TODO',
          backgroundSize: 'cover',
          backgroundPositionY: 'bottom',
        }}
      >
        <div className="flex flex-col m-auto">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.02 }}
            className="mx-auto mb-2 text-3xl font-bold text-center text-purple-500 underline xl:mb-3 2xl:mb-4 lg:text-4xl xl:text-5xl 2xl:text-6xl"
          >
            {formatMessage({ id: `pages:meetings-${currentPhase}.title` })}
          </motion.h2>
          <span className="w-1/2 mx-auto text-sm leading-tight text-center lg:text-base xl:text-lg 2xl:text-xl lg:leading-tight xl:leading-tight 2xl:leading-tight">
            <TextRevealer
              initialDelay={0.4}
              label={formatMessage({
                id: `pages:meetings-${currentPhase}.content`,
              })}
            />
            <b>
              <TextRevealer
                initialDelay={6.4}
                label={formatMessage({
                  id: `pages:meetings-${roleInfo.id}.sign`,
                })}
              />
            </b>
            &nbsp;
            <TextRevealer
              initialDelay={6.8}
              label={formatMessage({
                id: `pages:meetings-${roleInfo.id}.sign.ext`,
              })}
            />
            <br />
            <br />
            <p className="text-sm leading-none text-purple-500 xl:text-base 2xl:text-xl 2xl:leading-tight xl:leading-tight">
              <TextRevealer
                initialDelay={7.7}
                label={formatMessage({ id: 'pages:meetings.questionTitle' })}
              />
            </p>
            <br />
            <p className="text-sm font-bold leading-none xl:text-base 2xl:text-xl xl:leading-tight 2xl:leading-tight">
              <TextRevealer
                initialDelay={8.8}
                label={formatMessage(
                  {
                    id: `pages:meetings-${currentPhase}.questionStatement`,
                  },
                  { budgetAmountResult: price(budgetAmountResult) }
                )}
              />
            </p>
            <ul className="flex flex-col mt-3 space-y-2 text-sm text-purple-800">
              {currentPhase === 'second-meeting' &&
                availableAllocationVotes.map((vote, voteIndex) => (
                  <motion.li
                    key={vote}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 11.4 + voteIndex * 0.5 }}
                    className="p-2 rounded-lg bg-purple-200/80"
                  >
                    {formatMessage({
                      id: `decisions:phase-2.option:${getLabelID(
                        vote,
                        budgetAmountResult
                      )}`,
                    })}
                  </motion.li>
                ))}
            </ul>
            <br />
            <p className="text-sm leading-tight">
              <TextRevealer
                initialDelay={10}
                label={formatMessage({
                  id: `pages:meetings-${currentPhase}.notes`,
                })}
              />
            </p>
          </span>

          {meetingRoom && (
            <motion.a
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 11 }}
              className="mt-4 text-sm font-medium text-center underline cursor-pointer lg:mt-6 xl:mt-8 2xl:mt-10 lg:text-base xl:text-lg 2xl:text-xl"
              href={meetingRoom}
              target="_blank"
            >
              {formatMessage({ id: 'pages:meetings.roomLink' })}
            </motion.a>
          )}
        </div>
      </div>
    </PlayTimeScreen>
  );
};
