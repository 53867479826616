import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useTheme } from '../../stores/theme';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';

interface IProps {
  prefix?: 'personal' | 'team';
  goal: string;
  goalResult?: 'achieved' | 'not-achieved';
  hideNotes?: boolean;
  animationDelay?: number;
  className?: string;
}

export const Goal = ({
  goal,
  goalResult,
  hideNotes,
  prefix = 'personal',
  animationDelay = 0,
  className = '',
}: IProps) => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);

  return (
    <div className={clsx('flex flex-col p-4 xl:p-8 items-center', className)}>
      {goalResult && (
        <motion.img
          initial={{ opacity: 0, scale: 2, rotate: -27 }}
          animate={{ opacity: 1, scale: 1, rotate: 0 }}
          transition={{ delay: animationDelay }}
          className="w-1/2 max-w-[70] mb-4"
          src={images(
            formatMessage({
              id: `goal.${
                goalResult === 'achieved' ? 'correct' : 'wrong'
              }.icon.url`,
            })
          )}
          alt={goal}
        />
      )}
      <img
        className="w-full mb-2 xl:mb-4"
        src={images(
          buildOptimizedAssetPath(
            formatMessage({ id: `${prefix}-goals:${goal}.icon.url` }),
            ['avif', 'png']
          )
        )}
      />
      <p className="mt-1 text-sm leading-tight text-center lg:text-base 2xl:text-lg lg:leading-tight 2xl:leading-tight">
        {formatMessage({ id: `${prefix}-goals:${goal}.title` })}
      </p>
      {!hideNotes && (
        <p className="mt-2 text-xs">
          {formatMessage({ id: `${prefix}-goals:${goal}.notes` })}
        </p>
      )}
    </div>
  );
};
