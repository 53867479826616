import { useEffect, useState } from 'react';
import { useRoute } from 'wouter';
import { useGame } from '../../stores/game';
import ServiceScreen from '../service-screen';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const Initializer = ({ children }: IProps): JSX.Element => {
  const init = useGame(({ init }) => init);
  const isInitialized = useGame(({ isInitialized }) => isInitialized);

  const [isReady, setReady] = useState<boolean>(false);

  const [, params] = useRoute<{ gamePlayId: string }>(
    '/games/:gamePlayId/:rest*'
  );
  const gamePlayIdInUse: string = params?.gamePlayId;

  useEffect(() => {
    if (isInitialized) setReady(true);

    if (!gamePlayIdInUse) return;

    useGame.persist.setOptions({ name: `hospitalia-${gamePlayIdInUse}` });

    useGame.persist.rehydrate().then(() => {
      init({
        gamePlayId: gamePlayIdInUse,
      }).then(() => setReady(true));
    });
  }, [gamePlayIdInUse]);

  // If everything is ready, render the children.
  if (isReady) return <>{children}</>;

  // If we're not ready, and no game id was retrieved, then we're should not be
  // here.
  if (!gamePlayIdInUse) return <ServiceScreen text="Invalid link." />;

  return null;
};
