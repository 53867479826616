import React from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import shallow from 'zustand/shallow';
import getPhaseColor from '../../../utils/get-phase-color';
import SectionTitle from '../section-title';
import TextRevealer from '../text-revealer';
import { useIntl } from 'react-intl';
import { useGame } from '../../../stores/game';

const getDecisionlabelID = (budgetAmountResult: number, choiceID: string) => {
  switch (choiceID) {
    case 'ChangeOMABudget': {
      if (budgetAmountResult && budgetAmountResult > 0)
        return 'IncreaseOMABudget';
      else return 'DecreaseOMABudget';
    }
    case 'ChangeMEPOBudget': {
      if (budgetAmountResult && budgetAmountResult > 0)
        return 'IncreaseMEPOBudget';
      else return 'DecreaseMEPOBudget';
    }
    default:
      return choiceID;
  }
};

const Phase2Outcomes = () => {
  const { formatMessage } = useIntl();
  const allocationDecision = useGame(({ allocationDecision }) =>
    allocationDecision()
  );

  const {
    budgetAmountResult,

    totalDrugsCostsYear0,
    totalOMACostYear0,
    totalMEPOCostYear0,

    totalDrugsCostsYear1,
    totalOMACostYear1,
    totalMEPOCostYear1,

    unexpectedEventCostsYear0,
    unexpectedEventCostsDelta,
    unexpectedEventCostsYear1,

    complianceYear0,
    complianceDelta,
    complianceYear1,

    numberOfPatientsTreatedYear0,
    numberOfPatientsTreatedYear1,

    percentageOfPatientsTreatedWithBioYear0,
    percentageOfPatientsTreatedWithBioYear1,
  } = useGame(
    ({
      budgetAmountResult,

      totalDrugsCostsYear0,
      totalOMACostYear0,
      totalMEPOCostYear0,

      totalDrugsCostsYear1,
      totalOMACostYear1,
      totalMEPOCostYear1,

      unexpectedEventCostsYear0,
      unexpectedEventCostsDelta,
      unexpectedEventCostsYear1,

      complianceYear0,
      complianceDelta,
      complianceYear1,

      numberOfPatientsTreatedYear0,
      numberOfPatientsTreatedYear1,

      percentageOfPatientsTreatedWithBioYear0,
      percentageOfPatientsTreatedWithBioYear1,
    }) => ({
      budgetAmountResult: budgetAmountResult(),

      totalDrugsCostsYear0: totalDrugsCostsYear0(),
      totalOMACostYear0: totalOMACostYear0(),
      totalMEPOCostYear0: totalMEPOCostYear0(),

      totalDrugsCostsYear1: totalDrugsCostsYear1(),
      totalOMACostYear1: totalOMACostYear1(),
      totalMEPOCostYear1: totalMEPOCostYear1(),

      unexpectedEventCostsYear0: unexpectedEventCostsYear0(),
      unexpectedEventCostsDelta: unexpectedEventCostsDelta(),
      unexpectedEventCostsYear1: unexpectedEventCostsYear1(),

      complianceYear0: complianceYear0(),
      complianceDelta: complianceDelta(),
      complianceYear1: complianceYear1(),

      numberOfPatientsTreatedYear0: numberOfPatientsTreatedYear0(),
      numberOfPatientsTreatedYear1: numberOfPatientsTreatedYear1(),

      percentageOfPatientsTreatedWithBioYear0:
        percentageOfPatientsTreatedWithBioYear0(),
      percentageOfPatientsTreatedWithBioYear1:
        percentageOfPatientsTreatedWithBioYear1(),
    }),
    shallow
  );

  const phase2DecisionId = getDecisionlabelID(
    budgetAmountResult,
    allocationDecision
  );

  return (
    <div>
      <SectionTitle
        label="La decisione del tuo team"
        color={getPhaseColor('phase-2')}
      />

      <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
        <p className="text-base lg:text-lg xl:text-xl 2xl:text-2xl">
          <TextRevealer
            label={formatMessage({ id: 'decisions:phase-2.teamDecision' })}
          />
        </p>

        <motion.span
          initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
          animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
          transition={{ delay: 1, duration: 1 }}
          className="relative flex items-center justify-center w-1/2 p-4 mx-auto mt-2 text-base font-bold text-center border-2 shadow-xl rounded-3xl 2xl:w-2/3 2xl:p-6 border-teal-50 shadow-teal-100 md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-hospitaliaTeal-base"
        >
          {formatMessage({
            id: `decisions:phase-2.option:${phase2DecisionId}`,
          })}
        </motion.span>

        <motion.p
          initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
          animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
          transition={{ delay: 1.9, duration: 1 }}
          className="mt-4 text-xs text-gray-800 lg:text-sm xl:text-base 2xl:text-lg lg:mt-5 xl:mt-6 2xl:mt-8"
        >
          {formatMessage({ id: 'decisions:phase-2.tableDescription' })}
        </motion.p>

        <motion.table
          initial={{ opacity: 0, y: 32, filter: 'blur(8px)' }}
          animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
          transition={{ delay: 2.5, duration: 1 }}
          className="min-w-full mt-2 divide-y divide-gray-300"
        >
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg"></td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                {formatMessage({ id: 'pages:scenarios-phase-2.table.h1' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                {formatMessage({ id: 'pages:scenarios-phase-2.table.h2' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                {formatMessage({ id: 'pages:scenarios-phase-2.table.h3' })}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg">
                {formatMessage({ id: 'vars:spesa-totale-farmaci.label' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalDrugsCostsYear0}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={budgetAmountResult || 0}
                  decimals={0}
                  separator="."
                  prefix={budgetAmountResult > 0 ? '+' : ''}
                />{' '}
                € (*)
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalDrugsCostsYear1}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
            </tr>
            <tr>
              <td className="indent-4 px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm">
                {formatMessage({ id: 'vars:spesa-totale-oma.label' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalOMACostYear0}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalOMACostYear1 - totalOMACostYear0 || 0}
                  decimals={0}
                  separator="."
                  prefix={totalOMACostYear1 - totalOMACostYear0 > 0 ? '+' : ''}
                />{' '}
                € (*)
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalOMACostYear1}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
            </tr>
            <tr>
              <td className="indent-4 px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm">
                {formatMessage({ id: 'vars:spesa-totale-mepo.label' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalMEPOCostYear0}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalMEPOCostYear1 - totalMEPOCostYear0}
                  decimals={0}
                  separator="."
                  prefix={
                    totalMEPOCostYear1 - totalMEPOCostYear0 > 0 ? '+' : ''
                  }
                />{' '}
                € (*)
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={totalMEPOCostYear1}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
            </tr>
            <tr>
              <td className="px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg">
                {formatMessage({
                  id: 'vars:spesa-totale-eventi-avversi.label',
                })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={unexpectedEventCostsYear0}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={unexpectedEventCostsDelta}
                  decimals={0}
                  separator="."
                  prefix={unexpectedEventCostsDelta > 0 ? '+' : ''}
                />{' '}
                €
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={unexpectedEventCostsYear1}
                  decimals={0}
                  separator="."
                />{' '}
                €
              </td>
            </tr>
            <tr>
              <td className="px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg">
                {formatMessage({ id: 'vars:compliance-media.label' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={complianceYear0 * 100}
                  decimals={2}
                  decimal=","
                />
                %
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={complianceDelta * 100}
                  decimals={2}
                  decimal=","
                  prefix={
                    complianceDelta * 100 > 0
                      ? '+'
                      : '' /* `-` is already there! */
                  }
                />
                %
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={complianceYear1 * 100}
                  decimals={2}
                  decimal=","
                />
                %
              </td>
            </tr>
            <tr>
              <td className="px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg">
                {formatMessage({ id: 'vars:treated-patients.label' })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={numberOfPatientsTreatedYear0}
                  decimals={0}
                  separator="."
                />
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={
                    numberOfPatientsTreatedYear1 - numberOfPatientsTreatedYear0
                  }
                  decimals={0}
                  separator="."
                  prefix={
                    numberOfPatientsTreatedYear1 -
                      numberOfPatientsTreatedYear0 >
                    0
                      ? '+'
                      : ''
                  }
                />
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={3}
                  start={0}
                  end={numberOfPatientsTreatedYear1}
                  decimals={0}
                  separator="."
                />
              </td>
            </tr>
            <tr>
              <td className="px-3 py-1 text-xs text-gray-500 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg">
                {formatMessage({
                  id: 'vars:treated-patients-biological.label',
                })}
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={2.5}
                  start={0}
                  end={percentageOfPatientsTreatedWithBioYear0 * 100}
                  decimals={2}
                  decimal=","
                />
                %
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={2.5}
                  start={0}
                  end={
                    (percentageOfPatientsTreatedWithBioYear1 -
                      percentageOfPatientsTreatedWithBioYear0) *
                    100
                  }
                  decimals={2}
                  decimal=","
                  prefix={
                    percentageOfPatientsTreatedWithBioYear1 -
                      percentageOfPatientsTreatedWithBioYear0 >
                    0
                      ? '+'
                      : ''
                  }
                />
                %
              </td>
              <td className="py-1 pl-4 pr-3 text-xs font-medium text-right text-gray-900 whitespace-nowrap lg:py-2 xl:py-3 2xl:py-4 lg:text-sm xl:text-base 2xl:text-lg sm:pl-6 md:pl-0">
                <CountUp
                  delay={2.5}
                  start={0}
                  end={percentageOfPatientsTreatedWithBioYear1 * 100}
                  decimals={2}
                  decimal=","
                />
                %
              </td>
            </tr>
          </tbody>
          <span className="text-sm xl:text-sm 2xl:text-base">
            {formatMessage({ id: 'vars:budget-amount-result.note' })}
          </span>
        </motion.table>

        <br />
      </div>
    </div>
  );
};

export default Phase2Outcomes;
