import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import Lottie from 'react-lottie-player';
import { useGame } from '../../../stores/game';
import { useTheme } from '../../../stores/theme';
import animation from '../../../../lottie-animations/85185-checkmark.json';

export const AvatarList = () => {
  const images = useTheme(({ images }) => images);
  const [chosenAvatar, chooseAvatar] = useState('');
  const playerId = useGame(({ playerId }) => playerId);
  const availableAvatars = useGame(({ availableAvatars }) => availableAvatars);
  const selectAvatar = useGame(({ selectAvatar }) => selectAvatar);
  const hasSelectedAvatar = useGame(({ hasSelectedAvatar }) =>
    hasSelectedAvatar(playerId)
  );
  const areAllAvatarsSelected = useGame(({ areAllAvatarsSelected }) =>
    areAllAvatarsSelected()
  );
  const playersWithoutAvatarCount = useGame(({ playersWithoutAvatarCount }) =>
    playersWithoutAvatarCount()
  );
  const isAvatarSelected = useGame(({ isAvatarSelected }) => isAvatarSelected);

  if (areAllAvatarsSelected)
    return (
      <p className="text-4xl text-gray-800">
        Tutti i giocatori hanno selezionato il proprio Avatar!
      </p>
    );

  return (
    <>
      <p className="mb-4 text-base text-gray-800 xl:mb-8 xl:text-xl">
        Scegli un avatar dall'elenco qui sotto e dai un volto al tuo personaggio
      </p>

      <div className="grid grid-cols-6 gap-2 xl:gap-4">
        {availableAvatars.map(id => (
          <Avatar
            key={id}
            id={id}
            selected={chosenAvatar === id || isAvatarSelected(playerId, id)}
            disabled={hasSelectedAvatar}
            onSelect={id => chooseAvatar(id)}
          />
        ))}
      </div>

      {!hasSelectedAvatar && (
        <button
          className="relative flex flex-row items-center py-1 pl-4 pr-8 m-auto mt-6 mb-4 space-x-8 font-bold text-white uppercase rounded-full shadow-lg cursor-pointer select-none lg:mb-4 xl:mb-6 2xl:mb-10 xl:mt-6 2xl:mt-8 disabled:bg-neutral-200 disabled:cursor-default disabled:shadow-none bg-hospitaliaTeal-base xl:py-2 hover:shadow-xl hover:bg-hospitaliaTeal-light active:bg-hospitaliaTeal-dark"
          disabled={!chosenAvatar || hasSelectedAvatar}
          onClick={() => {
            if (!chosenAvatar || hasSelectedAvatar) return;

            selectAvatar(playerId, chosenAvatar);
          }}
        >
          <img src={images('checkmark.svg')} alt="Checkmark" />
          <p className="text-xs lg:text-sm 2xl:text-base">Scegli Avatar</p>
        </button>
      )}

      {hasSelectedAvatar && (
        <div className="flex w-full mt-8">
          <span className="flex flex-row w-2/3 items-center mx-auto mt-auto mb-4 text-base text-center text-gray-800 xl:text-lg 2xl:text-xl lg:mb-6 xl:mb-8">
            <>
              <span className="h-16 lg:h-20 xl:h-24 2xl:h-32">
                <Lottie
                  animationData={animation}
                  style={{ height: '100%' }}
                  play
                  loop={false}
                />
              </span>
              <p className="font-light text-green-900">
                {`Ottimo, hai scelto il tuo avatar! Ora attendi che anche i tuoi compagni di squadra selezionino il proprio avatar. Ne ${
                  playersWithoutAvatarCount > 1 ? 'mancano' : 'manca'
                } ancora ${playersWithoutAvatarCount}.`
                  .split('')
                  .map((c, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.4 + index / 80 }}
                    >
                      {c}
                    </motion.span>
                  ))}
              </p>
            </>
          </span>
        </div>
      )}
    </>
  );
};

const Avatar: FC<{
  id: string;
  selected?: boolean;
  disabled?: boolean;
  onSelect(id: string): void;
}> = props => {
  const images = useTheme(({ images }) => images);

  return (
    <motion.img
      src={images(`avatars/${props.id}.svg`)}
      alt={props.id}
      className={clsx(
        'border-2 h-12 xl:h-24 w-12 xl:w-24 rounded-full cursor-pointer',
        props.selected
          ? 'bg-hospitaliaGold-base border-hospitaliaGold-base'
          : 'bg-white  border-transparent',
        {
          'cursor-default': props.disabled,
          'hover:shadow-2xl hover:border-hospitaliaGold-base':
            !props.disabled && !props.selected,
        }
      )}
      whileHover={{
        scale: props.selected || props.disabled ? 1 : 1.08,
        transition: { duration: 0.26 },
      }}
      whileTap={{ scale: 0.92, transition: { duration: 0.05 } }}
      onClick={() => !props.disabled && props.onSelect(props.id)}
    />
  );
};
