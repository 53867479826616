import { motion } from 'framer-motion';
import React from 'react';
import Lottie from 'react-lottie-player';
import { Link, useRoute } from 'wouter';
import { useGame } from '../../stores/game';
import { Menu } from '../game/menu';
import { PlayTimeScreenLayout } from '../layout/screen.play-time';
import animation from '../../../lottie-animations/34137-waiting-animation.json';

export const PlayTimeScreen: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = props => (
  <PlayTimeScreenLayout side={<Menu />} navigation={<GoForwardButton />}>
    {props.children}
  </PlayTimeScreenLayout>
);

const GoForwardButton = () => {
  const [_match, params] = useRoute<GameRouteParams>('/:gamePlayId/:rest*');
  const nextLocationToVisit = useGame(({ nextLocationToVisit }) =>
    nextLocationToVisit()
  );
  const currentToDoNudge = useGame(({ currentToDoNudge }) =>
    currentToDoNudge()
  );
  const goForwardLabel = useGame(({ goForwardLabel }) => goForwardLabel);

  // TODO: Refactor to `IdleGameHintMessage` component.
  if (!nextLocationToVisit)
    return (
      <span className="flex flex-row items-center w-5/6 py-px pl-6 pr-10 mx-auto space-x-4 bg-blue-100 rounded-full lg:w-2/3 xl:w-1/2 xl:py-1">
        <span className="h-16 2xl:h-20">
          {params.rest !== 'thanks' && (
            <Lottie
              animationData={animation}
              style={{ height: '100%' }}
              play
              loop={false}
            />
          )}
        </span>
        <motion.p className="text-xs leading-none text-center lg:leading-none xl:leading-none text-slate-900 lg:text-sm xl:text-lg">
          {params.rest === 'thanks'
            ? 'Hai completato il gioco, attendi istruzioni dai docenti su cosa fare adesso!'
            : params.rest === 'rules'
            ? 'Sei al passo con gli eventi, mentre attendi che venga attivata la prima fase, leggi le regole del gioco.'
            : `Sei al passo con gli eventi, mentre attendi che il gioco proceda
          potresti cogliere l'occasione per studiare meglio documenti, profili
          dei personaggi e obiettivi.`
                .split('')
                .map((character, characterIndex) => (
                  <motion.span
                    key={characterIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 + characterIndex / 80 }}
                  >
                    {character}
                  </motion.span>
                ))}
        </motion.p>
      </span>
    );

  if (nextLocationToVisit === params.rest)
    return <span>{currentToDoNudge}</span>;

  return (
    <Link href={`/${params.gamePlayId}/${nextLocationToVisit}`}>
      <motion.span
        className="relative px-4 py-px text-xs font-bold text-white uppercase rounded-full shadow-lg cursor-pointer select-none xl:px-8 2xl:py-1 bg-hospitaliaTeal-base lg:text-base xl:text-lg xl:py-4 hover:shadow-xl hover:bg-hospitaliaTeal-light active:bg-hospitaliaTeal-dark"
        whileHover={{ y: '-2px', transition: { duration: 0.2 } }}
        whileTap={{ y: '1px', transition: { duration: 0.05 } }}
      >
        {params.rest === 'thanks?' ? '' : goForwardLabel}
      </motion.span>
    </Link>
  );
};

type GameRouteParams = {
  gamePlayId: string;
  rest: string;
};
