import { GetState } from 'zustand';
import { ICountdown } from '../../concepts/countdown';
import { IDocs } from '../../concepts/docs';
import { IGame } from '../../concepts/game';
import { ILocations } from '../../concepts/locations';
import { IToDos } from '../../concepts/to-dos';
import { IPhaseExecutorFn } from '../game';

export const startIntro: IPhaseExecutorFn = (
  get: GetState<IGame & IDocs & IToDos & ILocations & ICountdown>
) => {
  get().closeLocation('/');

  get().pinLocation('intro/mandate');
  get().collectDoc('RegioneVentosa');
  get().collectDoc('UOCPneumologia');
  get().collectDoc('IRCCS');
  get().collectDoc('Organigramma');

  get().clearToDos();

  ToDos.forEach(get().appendToDo);

  get().startCountdown(get().currentPhaseStartTime, 45 * 60 * 1_000);
};

const ToDos = [
  {
    id: 'read-rules',
    type: 'read-info',
    locationToVisit: 'rules',
    nudge: 'È il momento di leggere le regole del gioco.',
    payload: null,
  },
  {
    id: 'read-mandate',
    type: 'read-info',
    locationToVisit: 'intro/mandate',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-roles',
    type: 'read-info',
    locationToVisit: 'intro/roles',
    payload: null,
    nudge: '',
  },
  {
    id: 'read-all-docs',
    type: 'read-all-docs',
    locationToVisit: 'docs',
    payload: null,
    nudge: 'Leggi tutti i documenti prima di proseguire',
  },
  {
    id: 'select-avatar',
    type: 'select-avatar',
    locationToVisit: 'avatar',
    payload: null,
    nudge: 'È giunto il momento di scegliere il volto del tuo personaggio.',
  },
];
