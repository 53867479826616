import { useEffect, useMemo, useState } from 'react';
import { useRoute } from 'wouter';
import { useGame } from '../../../stores/game';
import { NavigationBar } from '../../layout/navigation-bar';
import { NavPlayerInfo } from './nav.player-info';
import NavButton from './nav.button';
import { NavTimer } from './nav.timer';
import { useTheme } from '../../../stores/theme';
import { Chat } from '../../chat';

export const Menu = () => {
  const [_match, params] = useRoute<GameRouteParams>('/:gamePlayId/:rest*');
  const isMeetingOn = useGame(({ isMeetingOn }) => isMeetingOn);
  const basecamp = useGame(({ basecampLocation }) => basecampLocation());
  const isChatOpen = useGame(({ isChatOpen }) => isChatOpen);
  const isChatReadable = useGame(({ isChatReadable }) => isChatReadable);
  const alwaysAvailableLocations = useGame(g => g.alwaysAvailableLocations);
  const collectedDocs = useGame(g => g.collectedDocs);
  const images = useTheme(({ images }) => images);

  const menuItems = useMemo(
    () => [
      // {
      //   label: 'Regole',
      //   link: `rules`,
      //   icon: images('navigation-bar/Regole.svg'),
      // },
      // {
      //   label: 'Mandato',
      //   link: `intro/mandate`,
      //   // TODO find a new menu icon for mandate
      //   icon: images('navigation-bar/Archivio Documenti.svg'),
      //   disabled: !alwaysAvailableLocations.includes('intro/mandate'),
      // },
      {
        label: 'Documenti',
        link: `docs`,
        icon: images('navigation-bar/Archivio Documenti.svg'),
        disabled:
          !alwaysAvailableLocations.includes('docs') ||
          collectedDocs.length === 0,
      },
      {
        label: 'Ruoli',
        link: `profiles`,
        icon: images('navigation-bar/Profili.svg'),
        disabled: !alwaysAvailableLocations.includes('profiles'),
      },
      {
        label: 'Obiettivi',
        link: `team-goals`,
        icon: images('navigation-bar/Obiettivi.svg'),
        disabled: !alwaysAvailableLocations.includes('team-goals'),
      },
      // {
      //   label: 'Team',
      //   link: `/profiles`,
      //   icon: 'a',
      // },
      {
        label: 'Ultimo capitolo',
        link: isMeetingOn ? 'meeting' : basecamp,
        icon: images('navigation-bar/Bussola.svg'),
        disabled: !basecamp || (!isMeetingOn && basecamp === '/'),
      },
    ],
    [params, images]
  );

  return (
    <NavigationBar isActive>
      <NavPlayerInfo />
      <NavTimer />

      <div className="flex flex-col items-center w-full h-full space-y-1 lg:space-y-2 2xl:space-y-4">
        {menuItems.map(menuItem => (
          <NavButton
            key={menuItem.label}
            {...menuItem}
            link={`/${params.gamePlayId}/${menuItem.link}`}
            isActive={params.rest === menuItem.link}
          />
        ))}

        <Chat
          disabled={!isChatOpen && !isChatReadable}
          readOnly={!isChatOpen && isChatReadable}
        />
      </div>

      {/*
      <div>
        <Link href={`/${params.gamePlayId}/rules`}>Regole</Link>
      </div>
      <div>
        <Link href={`/${params.gamePlayId}/intro/mandate`}>Mandato</Link>
      </div>
      <div>
        <Link href={`/${params.gamePlayId}/intro/roles`}>Ruoli</Link>
      </div>
      <div>
        <Link href={`/${params.gamePlayId}/docs`}>Documenti</Link>
      </div>
      <div>
        <Link href={`/${params.gamePlayId}/profiles`}>Team</Link>
      </div>
      <div>
        <Link href={`/${params.gamePlayId}/team-goals`}>Obiettivi</Link>
      </div>

      <div>
        <Link href={`/${params.gamePlayId}/${basecamp}`}>Ultimo capitolo</Link>
      </div>
      */}
    </NavigationBar>
  );
};

type GameRouteParams = {
  gamePlayId: string;
  rest: string;
};
