import clsx from 'clsx';

interface IProps {
  variant: 'landing' | 'game';
  children: JSX.Element | JSX.Element[];
}

export const HomeHeader = ({ children, variant }: IProps) => (
  <header
    className={clsx(
      'h-[40px] lg:h-[60px] xl:h-[90px] 2xl:h-[100px] top-0 left-0 w-full flex flex-row justify-between px-4 sm:px-8 items-center _nodrag select-none',
      variant === 'landing'
        ? 'bg-hospitaliaBlue-base'
        : 'bg-hospitaliaTeal-base'
    )}
  >
    {children}
  </header>
);
