import { MutableRefObject, useEffect, useState } from 'react';

interface IScrollProgress {
  current: number;
  total: number;
}

export function useScrollProgress(
  element: HTMLElement | null
): IScrollProgress {
  const [state, setState] = useState<IScrollProgress>({ current: 0, total: 0 });

  const scrollHandler = (event: Event) => {
    const _element = event.target as HTMLElement;
    const total = _element.scrollHeight - _element.offsetHeight;
    const current = _element.scrollTop;
    setState({ total, current });
  };

  useEffect(() => {
    if (!element) return;

    element.addEventListener('scroll', scrollHandler);

    return () => element.removeEventListener('scroll', scrollHandler);
  }, [element, scrollHandler]);

  return state;
}

export function useScrollRef(
  reference: MutableRefObject<HTMLDivElement>
): number {
  const [dom, setDom] = useState<HTMLDivElement>(null);

  useEffect(() => {
    if (reference?.current) setDom(reference.current);
  }, [reference?.current]);

  const { current, total } = useScrollProgress(dom);

  return total === 0 ? 0 : (100 * current) / total;
}
