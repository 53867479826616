import Lottie from 'react-lottie-player';
import { useIntl } from 'react-intl';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import { useGame } from '../../stores/game';
import { useTheme } from '../../stores/theme';
import SectionTitle from '../game/section-title';
import { PlayTimeScreen } from './play-time-screen';
import TextRevealer from '../game/text-revealer';
import animation from '../../../lottie-animations/69030-confetti-full-screen.json';

const FeedbacksImages = {
  YouAreOpen: 'feedback_1',
  MeMyselfAndI: 'feedback_2',
};

export const FeedbackScreen = () => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);
  const feedback = useGame(s => s.feedback());

  return (
    <>
      <div className="overflow-hidden w-screen h-screen absolute pointer-events-none top-0 left-0">
        <Lottie
          animationData={animation}
          style={{ width: '100%' }}
          speed={0.6}
          play
          loop={false}
        />
      </div>

      <PlayTimeScreen>
        <SectionTitle
          color="#998be7"
          label={formatMessage({
            id: `pages:feedbacks-${feedback}.title`,
          })}
        />
        <p className="text-xl 2xl:text-4xl max-w-[640px] leading-tight ml-32 mt-16">
          <TextRevealer
            label={formatMessage({ id: `pages:feedbacks-${feedback}.message` })}
            initialDelay={1.4}
          />
        </p>
        <img
          style={{
            maxWidth: '40%',
            marginTop: '1em',
            float: 'right',
            position: 'absolute',
            bottom: '3em',
            right: '10em',
            zIndex: '-1',
          }}
          src={images(
            buildOptimizedAssetPath(`feedbacks/${FeedbacksImages[feedback]}`, [
              'avif',
              'webp',
            ])
          )}
          alt={formatMessage({
            id: `pages:feedbacks-${feedback}.img.alt`,
          })}
        />
      </PlayTimeScreen>
    </>
  );
};
