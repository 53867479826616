import clsx from 'clsx';
import { useTheme } from '../../stores/theme';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';

interface IProps {
  variant: 'landing' | 'game';
}

export const LearningLabLogo = ({ variant }: IProps) => {
  const images = useTheme(({ images }) => images);

  return (
    <img
      src={
        variant === 'game'
          ? images(buildOptimizedAssetPath('llab_area_white', ['avif', 'png']))
          : images(buildOptimizedAssetPath('logo_llab_ext', ['avif', 'webp']))
      }
      alt="Logo LLab"
      className={clsx(variant === 'game' ? 'w-3/6' : 'h-1/5')}
      style={{
        imageRendering: '-webkit-optimize-contrast',
      }}
    />
  );
};
