import { useIntl } from 'react-intl';
import { useGame } from '../../../stores/game';
import getPhaseColor from '../../../utils/get-phase-color';

const Phase3Scenario = () => {
  const { formatMessage } = useIntl();
  const role = useGame(({ role }) => role);

  return (
    <div className="w-full h-[90%]">
      <div
        className="w-1/3 py-1 pr-8 mt-4 mb-5 text-right xl:py-2 rounded-r-xl xl:mt-8 xl:mb-10 bg-opacity-80"
        style={{ backgroundColor: getPhaseColor('phase-3') }}
      >
        <p className="text-xs font-bold leading-none text-white uppercase md:leading-none lg:leading-none xl:leading-none md:text-sm lg:text-base 2xl:text-lg">
          {formatMessage({ id: 'pages:phases-phase-3.title' })}
        </p>
      </div>

      <div className="px-10 pb-8 lg:px-20 xl:px-32 2xl:px-64">
        <p className="text-base xl:text-lg 2xl:text-xl leading-tight`">
          {formatMessage({ id: 'pages:decisions-phase-3.title' })}
        </p>

        <ul className="mt-1 mb-8 ml-8 list-disc">
          <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
            1. {formatMessage({ id: 'pages:decisions-diagnosis.statement' })}
          </li>
          <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
            2. {formatMessage({ id: 'pages:decisions-distribution.statement' })}
          </li>
          <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
            3. {formatMessage({ id: 'pages:decisions-tender.statement' })}
          </li>
        </ul>

        <div className="px-8 py-4 mt-12 border-4 shadow-lg rounded-3xl border-neutral-200/70">
          <p className="mt-5 text-lg leading-tight xl:text-xl 2xl:text-2xl">
            {formatMessage({ id: 'pages:decisions-phase-3.statement' })}
          </p>
          <p className="mt-1 text-base leading-tight text-gray-700 2xl:text-lg">
            {formatMessage({ id: 'pages:decisions-phase-3.message' })}
          </p>

          <p className="mt-10 text-base font-bold leading-tight xl:text-lg 2xl:text-xl xl:mt-12 2xl:mt-20">
            1. {formatMessage({ id: 'pages:decisions-diagnosis.statement' })}
          </p>
          <ul className="mt-1 mb-8 ml-8 list-disc">
            <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
              a.{' '}
              {formatMessage({
                id: 'pages:decisions-diagnosis.answer-ImproveWelfareContinuity',
              })}
            </li>
            <li className="mt-2 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
              b.{' '}
              {formatMessage({
                id: 'pages:decisions-diagnosis.answer-InvestOnPeopleSensitization',
              })}
            </li>
          </ul>

          <p className="mt-10 text-base font-bold leading-tight xl:text-lg 2xl:text-xl xl:mt-12 2xl:mt-20">
            2. {formatMessage({ id: 'pages:decisions-distribution.statement' })}
          </p>
          <ul className="mt-1 mb-8 ml-8 list-disc">
            <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
              a.{' '}
              {formatMessage({
                id: 'pages:decisions-distribution.answer-IncreaseDPCUtilization',
              })}
            </li>
            <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
              b.{' '}
              {formatMessage({
                id: 'pages:decisions-distribution.answer-ImproveWarehouseManagement',
              })}
            </li>
          </ul>

          <p className="mt-10 text-base font-bold leading-tight xl:text-lg 2xl:text-xl xl:mt-12 2xl:mt-20">
            3. {formatMessage({ id: 'pages:decisions-tender.statement' })}
          </p>
          <ul className="mt-1 mb-8 ml-8 list-disc">
            <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
              a.{' '}
              {formatMessage({
                id: 'pages:decisions-tender.answer-ImproveSuppliersCompetition',
              })}
            </li>
            <li className="mt-4 text-base font-medium leading-tight xl:text-lg 2xl:text-xl">
              b.{' '}
              {formatMessage({
                id: 'pages:decisions-tender.answer-CentralizePurchasing',
              })}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Phase3Scenario;
