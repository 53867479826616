import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useGame } from '../../stores/game';
import { Goal } from '../game/goal';
import SectionTitle from '../game/section-title';
import { PlayTimeScreen } from './play-time-screen';

export const AchievementsScreen = () => {
  const { formatMessage } = useIntl();
  const teamGoals = useGame(g => g.teamGoals());
  const personalGoals = useGame(g => g.personalGoals());

  return (
    <PlayTimeScreen>
      <SectionTitle
        color="#998be7"
        label={formatMessage({
          id: `pages:my-goals.team.title`,
        })}
      />
      <div className="flex flex-row items-start justify-evenly text-center my-2">
        {teamGoals.map((goal, index) => (
          <motion.div
            key={index}
            className="w-1/3 flex items-center flex-col justify-center h-full px-32"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: index / 1.5 },
            }}
          >
            <Goal
              key={goal.id}
              prefix="team"
              goal={goal.id}
              goalResult={goal.isAchieved ? 'achieved' : 'not-achieved'}
              hideNotes
              animationDelay={index}
            />
          </motion.div>
        ))}
      </div>
      <SectionTitle
        color="#998be7"
        label={formatMessage({
          id: `pages:my-goals.personal.title`,
        })}
      />
      <div className="flex items-start justify-evenly text-center my-2">
        {personalGoals.map((goal, index) => (
          <motion.div
            key={index}
            className="w-1/3 flex items-center flex-col justify-center h-full px-32"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 2.5 + index / 1.5 },
            }}
          >
            <Goal
              key={goal.id}
              prefix="personal"
              goal={goal.id}
              goalResult={goal.isAchieved ? 'achieved' : 'not-achieved'}
              hideNotes
              animationDelay={2.5 + index}
            />
          </motion.div>
        ))}
      </div>
    </PlayTimeScreen>
  );
};
