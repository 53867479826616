import PhaseIntro from '../game/phase-intro';
import { PlayTimeScreen } from './play-time-screen';
import { useGame } from '../../stores/game';

export const PhaseIntroScreen = () => {
  const currentPhase = useGame(({ currentPhase }) => currentPhase);

  return (
    <PlayTimeScreen>
      <PhaseIntro phase={currentPhase} />
    </PlayTimeScreen>
  );
};
