import { useIntl } from 'react-intl';
import clsx from 'clsx';
import Lottie from 'react-lottie-player';
import { motion } from 'framer-motion';
import { useGame } from '../../../stores/game';
import animation from '../../../../lottie-animations/85185-checkmark.json';
import useModal from '../../hooks/useModal.hook';
import { useTheme } from '../../../stores/theme';
import ScrollableContainer from '../../utils/scrollable-container';
import useBrowser from '../../hooks/useBrowser.hook';

export const DocsArchive = () => {
  const collectedDocs = useGame(({ collectedDocs }) => collectedDocs);
  const unreadDocsCount = useGame(({ unreadDocsCount }) => unreadDocsCount());

  return (
    <div className="w-full h-[90%] flex flex-col">
      <div className="grid w-5/6 grid-cols-5 gap-2 mx-auto mt-4 xl:grid-cols-4 lg:w-3/4 xl:w-2/3 2xl:w-1/2 xl:mt-6 2xl:mt-8 xl:gap-3 2xl:gap-8">
        {collectedDocs.map((document, documentIndex) => (
          <Document key={documentIndex} id={document} />
        ))}
      </div>

      <span className="flex flex-row items-center mx-auto mt-auto mb-4 text-lg text-center text-gray-800 xl:text-xl 2xl:text-2xl lg:mb-6 xl:mb-8">
        {unreadDocsCount > 0 ? (
          <p>
            Ci sono ancora <strong>{unreadDocsCount}</strong> documenti non
            letti
          </p>
        ) : (
          <>
            <span className="h-12 lg:h-16 xl:h-20 2xl:h-24">
              <Lottie
                animationData={animation}
                style={{ height: '100%' }}
                play
                loop={false}
              />
            </span>
            <p className="font-light text-green-900">
              {'Hai letto tutti i documenti.'.split('').map((c, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 + index / 80 }}
                >
                  {c}
                </motion.span>
              ))}
            </p>
          </>
        )}
      </span>
    </div>
  );
};

interface IDocProps {
  id: string;
}

export const Document = ({ id }: IDocProps) => {
  const { formatMessage } = useIntl();
  const { setModalInfo } = useModal();
  const isRead: boolean = useGame(({ isDocRead }) => isDocRead(id));
  const read = useGame(({ readDoc }) => readDoc);
  const images = useTheme(({ images }) => images);
  const documents = useTheme(({ documents }) => documents);
  const { isSafari, isEdge } = useBrowser();

  return (
    <div
      className="relative flex flex-col flex-wrap items-center gap-1 cursor-pointer xl:gap-2"
      onClick={() => {
        read(id);

        setModalInfo({
          title: formatMessage({ id: `documents:${id}.title` }),
          content: (
            <div className="h-[70vh]">
              <ScrollableContainer>
                <img
                  src={documents(
                    `${id}.${isSafari || isEdge ? 'jpg' : 'avif'}`
                  )}
                  alt={id}
                  className="w-full"
                  style={{ imageRendering: '-webkit-optimize-contrast' }}
                />
              </ScrollableContainer>
            </div>
          ),
        });
      }}
    >
      {!isRead && (
        <>
          <span className="absolute z-10 block w-4 h-4 bg-red-500 border border-white rounded-full top-1 right-4 lg:right-5 xl:right-6 2xl:right-7" />
          <span className="absolute z-10 block w-4 h-4 bg-red-500 border border-white rounded-full top-1 right-4 lg:right-5 xl:right-6 2xl:right-7 animate-ping" />
        </>
      )}

      <motion.span
        animate={{ scale: 1, filter: 'saturate(1)' }}
        whileHover={{ scale: 1.02, transition: { duration: 0.4 } }}
        whileTap={{
          scale: 0.98,
          transition: { duration: 0.04 },
          filter: 'saturate(0.5)',
        }}
        className={clsx(
          'h-16 group lg:h-24 xl:h-28 2xl:h-[7.5rem] w-16 lg:w-24 xl:w-28 2xl:w-[7.5rem] rounded-xl xl:rounded-2xl 2xl:rounded-3xl inline-flex p-4 lg:p-5 2xl:p-6 border-4 border-neutral-200',
          isRead ? 'bg-white' : 'bg-hospitaliaTeal-base'
        )}
        style={{ boxShadow: 'rgb(167 167 167 / 50%) 0px 0px 50px 0px' }}
      >
        <img src={images(`documents/${id}.svg`)} alt={id} className="h-full m-auto group-hover:scale-105 group-active:scale-95 group-active:duration-200 transition-all duration-500 ease-in-out" />
      </motion.span>
      <p className="text-xs font-semibold leading-none text-center text-hospitaliaTeal-base 2xl:text-base 2xl:leading-none">
        {formatMessage({ id: `documents:${id}.title` })}
      </p>
    </div>
  );
};
