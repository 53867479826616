import { FC } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'wouter';

export const ContinueGameButton: FC<{ gamePlayId: string }> = props => {
  const [, setLocation] = useLocation();

  return (
    <motion.button
      className="relative px-8 py-4 font-bold text-white uppercase rounded-full shadow-lg cursor-pointer select-none bg-hospitaliaTeal-base hover:shadow-xl hover:bg-hospitaliaTeal-light active:bg-hospitaliaTeal-dark"
      whileHover={{ y: '-2px', transition: { duration: 0.2 } }}
      whileTap={{ y: '1px', transition: { duration: 0.05 } }}
      onClick={() => setLocation(`/${props.gamePlayId}/rules`)}
    >
      Inizia
    </motion.button>
  );
};
