import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '../../stores/theme';
import { PlayTimeScreen } from './play-time-screen';
import SectionTitle from '../game/section-title';
import roles from '../contents/roles';

const TeamOverviewRoleBlock = ({ name, role, icon }) => (
  <div className="flex flex-col items-center justify-start w-full h-full p-2 text-center text-gray-900">
    <span className="p-[4px] lg:p-[6px] flex-none 2xl:p-[12px] aspect-square border-4 mb-2 border-neutral-200/80 w-1/3 flex items-center justify-center rounded-full bg-white overflow-hidden">
      <img
        src={icon}
        alt={role}
        className="flex-none max-w-[90%] max-h-[90%] aspect-auto w-auto h-auto m-auto"
      />
    </span>
    <p className="text-lg font-medium xl:text-2xl">{name}</p>
    <p className="text-xs xl:text-base">{role}</p>
  </div>
);

export const IntroTeamScreen = () => {
  const { formatMessage } = useIntl();
  const images = useTheme(({ images }) => images);

  const teamMembers = useMemo(
    () =>
      roles.map(role => ({
        ...role,
        name: formatMessage({ id: `profiles:${role.id}.name` }),
        role: formatMessage({ id: `profiles:${role.id}.title` }),
        icon: images(`icon-ruolo/Big/${role.iconName}`),
      })),
    [roles, images]
  );

  return (
    <PlayTimeScreen>
      <SectionTitle label="Il tuo team" />

      <div className="px-10 py-2 lg:py-4 xl:py-8 lg:px-32 xl:px-64">
        <div className="grid grid-cols-3 grid-rows-2 gap-8">
          {teamMembers.map(teamMember => (
            <TeamOverviewRoleBlock key={teamMember.role} {...teamMember} />
          ))}
        </div>
      </div>
    </PlayTimeScreen>
  );
};
