import buildAssetPath from './build-asset-path';
import isEdge from './is-edge';
import isSafari from './is-safari';

export default function buildOptimizedAssetPath(
  path: string,
  formats: [optimized: string, unoptimized: string]
): string {
  return buildAssetPath(
    `${path}.${isSafari() || isEdge() ? formats[1] : formats[0]}`
  );
}
