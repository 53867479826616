import { LedspClient } from 'ledsp-sdk';
import hospitalia from '../../.ledsp-gc-manifest/hospitalia.json';
import { extractDefaultGameParams } from './default-game-params';

export default function getLedspClient(gamePlayInfoId?: string): LedspClient {
  // extract the gamePlayId param from window location without using hooks
  const id = gamePlayInfoId || window.location.pathname.split('/')[2];
  if (!id) throw new Error('Missing or invalid gamePlayInfoId or demoId!');

  const defaultParams = extractDefaultGameParams(id);

  return LedspClient.getInstance({
    gamePlayInfoId: id,
    environment: process.env.ENVIRONMENT,
    emulate: !!defaultParams.demoId,
    gameEventsMountPointId: 'events-debugger',
    gameConceptToEmulate: hospitalia,
  });
}
