import { LandingScreenLayout } from './layout/screen.landing';

interface IProps {
  text: string;
}

const ServiceScreen = ({ text }: IProps) => (
  <LandingScreenLayout>
    <div className="w-screen h-full select-none bg-gradient-to-tr from-blue-900 to-teal-100">
      <div className="flex items-center justify-center w-full h-full">
        <p className="text-3xl text-white md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">
          {text}
        </p>
      </div>
    </div>
  </LandingScreenLayout>
);

export default ServiceScreen;
