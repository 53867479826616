import extractPhase from './extract-phase';

export default function getPhaseColor(phaseId: string) {
  switch (extractPhase(phaseId)) {
    case 'phase-1':
      return '#EF907F';
    case 'phase-2':
      return '#73A7B8';
    case 'phase-3':
    case 'conclusions':
      return '#8370EB';
    default:
      return 'FireBrick';
  }
}
