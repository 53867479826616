import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import { MessageDoc } from '../../remote/apis/chat';
import { useChat } from '../../stores/chat';
import { useGame } from '../../stores/game';
import roles from '../contents/roles';
import { PlayTimeScreenLayout } from '../layout/screen.play-time';
import clsx from 'clsx';
import { useTheme } from '../../stores/theme';

const Circle = ({ ratio = 1, size = 1.5, children }) => (
  <div
    style={{ boxShadow: '0 0 10px 0 rgba(167,167,167,0.5)' }}
    className={clsx(
      'rounded-full bg-white w-12 h-12 inline-flex items-center justify-center'
    )}
  >
    {children}
  </div>
);

const handlePhaseColorType = (phase: string) => {
  switch (phase) {
    case 'phase-1':
      return '#EC7763';
    case 'phase-2':
      return '#4F91A4';
    case 'phase-3':
      return '#8370EB';
    default:
      return '#fff';
  }
};

const PhaseTitle = ({ phase, children }) => (
  <div
    style={{
      fontSize: '2.7rem',
      fontWeight: 'bold',
      paddingBottom: '30px',
      textAlign: 'center',
      margin: 0,
      textTransform: 'uppercase',
      color: handlePhaseColorType(phase),
    }}
  >
    {children}
  </div>
);

const PhaseCard = ({ children }) => (
  <div
    style={{
      // position: 'relative',
      backgroundColor: 'white',
      borderRadius: '15px',
      padding: '20px 40px 45px 40px',
      marginBottom: '120px',
      boxShadow: '0 0 0.7em 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    {children}
  </div>
);

const StyledMessageContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: '25px',
    }}
  >
    {children}
  </div>
);

const MessageAvatar = ({ children }) => (
  <div
    style={{
      marginRight: '15px',
      alignContent: 'center',
      display: 'flex',
    }}
  >
    {children}
  </div>
);

const MessageDataContainer = ({ children }) => (
  <div
    style={{
      fontSize: '0.9rem',
      display: 'flex',
      alignSelf: 'center',
      flexDirection: 'column',
    }}
  >
    {children}
  </div>
);

const MessageDetails = ({ children }) => (
  <div
    style={{
      textAlign: 'left',
    }}
  >
    {children}
  </div>
);

const MessageDate = ({ children }) => (
  <span
    style={{
      marginLeft: '15px',
      color: '#b2b2b2',
      fontStyle: 'italic',
    }}
  >
    {children}
  </span>
);

const MessageBody = ({ children }) => (
  <div
    style={{
      textAlign: 'left',
      color: 'black',
      marginTop: '5px',
      wordBreak: 'break-word',
    }}
  >
    {children}
  </div>
);

const NoMessages = ({ children }) => (
  <div
    style={{
      marginTop: '25px',
      fontSize: '0.9rem',
      color: '#b2b2b2',
      fontStyle: 'italic',
    }}
  >
    {children}
  </div>
);

const DecisionTitle = ({ phase, children }) => (
  <h4
    style={{
      fontSize: '20px',
      backgroundColor: handlePhaseColorType(phase),
      borderRadius: '25px',
      borderBottomRightRadius: '0px',
      boxShadow: '0 2px 18px 0 rgba(0, 0, 0, 0.15)',
      padding: '35px',
      maxWidth: '600px',
      minWidth: '10em',
      width: 'auto',
      color: 'white',
    }}
  >
    {children}
  </h4>
);

const DecisionWrapper = ({ children }) => (
  <div
    style={{
      marginBottom: '30px',
    }}
  >
    {children}
  </div>
);

const Answers = ({ children }) => <div>{children}</div>;

const Answer = ({ children, style = {}, className = '' }) => (
  <div
    style={{
      borderRadius: '25px',
      borderTopLeftRadius: '0px',
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 18px 0 rgba(0, 0, 0, 0.15)',
      padding: '35px',
      position: 'relative',
      maxWidth: '600px',
      minWidth: '10em',
      width: 'auto',
      marginLeft: 'auto',
      marginTop: '15px',
      marginBottom: '15px',
      ...style,
    }}
    className={className}
  >
    {children}
  </div>
);

const AnswerStatement = ({ children }) => (
  <div
    style={{
      fontSize: '20px',
      lineHeight: '1.4em',
      marginBottom: '5px',
    }}
  >
    {children}
  </div>
);

const AnswerValue = ({ children }) => (
  <div
    style={{
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }}
  >
    {children}
  </div>
);

type Player = {
  id: string;
  role: string;
  avatar: string;
};

const MessageContainer: React.FC<{
  message: MessageDoc;
  player: Player;
  teamMates: Player[];
}> = props => {
  const { player, message, teamMates } = props;
  const isOwn = message.sender === player.id;
  const playerMessageData = isOwn
    ? player
    : teamMates.find(tm => tm.id === message.sender);
  const { formatMessage: f } = useIntl();
  const images = useTheme(({ images }) => images);

  return (
    <StyledMessageContainer>
      <MessageAvatar>
        <Circle>
          <img
            className="inline-block h-full w-full"
            src={images(`avatars/${playerMessageData.avatar}.svg`)}
            alt=""
          />
        </Circle>
      </MessageAvatar>
      <MessageDataContainer>
        <MessageDetails>
          <span className="text-hospitaliaTeal-base font-semibold">
            {f({ id: `profiles:${playerMessageData?.role}.name` })}
          </span>
          <MessageDate>
            {DateTime.fromJSDate(new Date(message.timestamp)).toFormat(
              // TODO: ew, improve date handling here
              'dd/LL/y - HH:mm'
            )}
          </MessageDate>
        </MessageDetails>
        <MessageBody>{message.content}</MessageBody>
      </MessageDataContainer>
    </StyledMessageContainer>
  );
};

export const ConversationReport: React.FC = () => {
  const { formatMessage: f } = useIntl();
  const phases = ['phase-1', 'phase-2', 'phase-3'];
  const gameId = useGame(({ gameId }) => gameId);
  const player = useGame(
    ({
      playerId,
      role,
      avatars,
      team,
      budgetAmountResult,
      allocationDecisionResult,
      diagnosisDecisionResult,
      directDistributionDecisionResult,
      desertTenderCostsDecisionResult,
    }) => ({
      id: playerId,
      role: roles.find(r => r.shortname === role).id,
      avatar: avatars[`${role}.${team}`],
      budgetAmountResult: budgetAmountResult(),
      budgetAllocationDecision: allocationDecisionResult,
      phase3Answers: {
        diagnosis: diagnosisDecisionResult,
        distribution: directDistributionDecisionResult,
        tender: desertTenderCostsDecisionResult,
      },
    })
  );
  const teamMates = useGame(({ players, avatars, team }) =>
    players
      .filter(id => id !== player.id)
      .map(id => {
        const role = id.split('.')[0];
        const roleId = roles.find(r => r.shortname === role).id;
        return {
          id,
          role: roleId,
          avatar: avatars[`${role}.${team}`],
        };
      })
  );
  const init = useChat(({ init }) => init);
  const messages = useChat(({ messages }) => messages);

  useEffect(() => {
    // TODO add a loader if the chat is not initialized
    init(gameId, player.id);
  }, [gameId, player.id]);

  const getLabelID = (choiceID: string | null) => {
    switch (choiceID) {
      case 'ChangeOMABudget': {
        if (player.budgetAmountResult && player.budgetAmountResult > 0) {
          return 'IncreaseOMABudget';
        } else {
          return 'DecreaseOMABudget';
        }
      }
      case 'ChangeMEPOBudget': {
        if (player.budgetAmountResult && player.budgetAmountResult > 0) {
          return 'IncreaseMEPOBudget';
        } else {
          return 'DecreaseMEPOBudget';
        }
      }
      default:
        return choiceID;
    }
  };

  return (
    <PlayTimeScreenLayout navigation={null} side={null}>
      <div
        className="m-auto text-center bg-contain bg-top bg-no-repeat pt-16"
        style={{
          backgroundImage: `url(${f({
            id: 'pages:conversation-report.background.url',
          })})`,
        }}
      >
        <div className="font-normal m-auto max-w-[920px] text-[2rem]">
          {phases.map((phase, index) => (
            <Fragment key={index}>
              <PhaseTitle phase={phase}>
                {f({ id: `pages:phases-phase-${index + 1}.title` })}
              </PhaseTitle>

              {phase === 'phase-1' && player.budgetAmountResult != null && (
                <DecisionWrapper>
                  <DecisionTitle phase="phase-1">
                    {f({
                      id: 'pages:conversation-report.outcomes-phase-1.title',
                    })}
                  </DecisionTitle>
                  <Answer
                    className="text-hospitaliaTeal-base"
                    style={{
                      maxWidth: '12em',
                      fontWeight: 'bold',
                    }}
                  >
                    {numeral(player.budgetAmountResult || 0).format('0,0 $')}
                  </Answer>
                </DecisionWrapper>
              )}

              {phase === 'phase-2' && player.budgetAllocationDecision && (
                <DecisionWrapper>
                  <DecisionTitle phase="phase-2">
                    {f({
                      id: 'pages:conversation-report.outcomes-phase-2.title',
                    })}
                  </DecisionTitle>
                  <Answer
                    className="text-hospitaliaTeal-base"
                    style={{
                      minWidth: '10em',
                      width: 'auto',
                    }}
                  >
                    <AnswerValue>
                      {f({
                        id: `decisions:phase-2.option:${getLabelID(
                          player.budgetAllocationDecision
                        )}`,
                      })}
                    </AnswerValue>
                  </Answer>
                </DecisionWrapper>
              )}

              {phase === 'phase-3' &&
                player.phase3Answers.diagnosis &&
                player.phase3Answers.distribution &&
                player.phase3Answers.tender && (
                  <DecisionWrapper>
                    <DecisionTitle phase="phase-3">
                      {f({
                        id: 'pages:conversation-report.outcomes-phase-3.title',
                      })}
                    </DecisionTitle>
                    <Answers>
                      {['diagnosis', 'distribution', 'tender'].map(
                        (decisionID, i) => (
                          <Answer key={i} className="text-hospitaliaTeal-base">
                            <AnswerStatement>
                              {f({
                                id: `pages:decisions-${decisionID}.number`,
                              })}
                              &nbsp;
                              {f({
                                id: `pages:decisions-${decisionID}.statement`,
                              })}
                            </AnswerStatement>
                            <AnswerValue>
                              {f({
                                id: `pages:decisions-${decisionID}.answer-${player.phase3Answers[decisionID]}`,
                              })}
                            </AnswerValue>
                          </Answer>
                        )
                      )}
                    </Answers>
                  </DecisionWrapper>
                )}

              <PhaseCard>
                {messages.filter(message => message.phase === phase).length >
                0 ? (
                  messages
                    .filter(message => message.phase === phase)
                    .map((message, j) => (
                      <MessageContainer
                        key={j}
                        message={message}
                        player={player}
                        teamMates={teamMates}
                      />
                    ))
                ) : (
                  <NoMessages>
                    {f({ id: 'pages:conversation-report.missing-messages' })}
                  </NoMessages>
                )}
              </PhaseCard>
            </Fragment>
          ))}
        </div>
      </div>
    </PlayTimeScreenLayout>
  );
};
