import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IProps {
  children: JSX.Element | JSX.Element[] | ReactNode;
  title: string;
  actionsSection?: JSX.Element | JSX.Element[];
  isOpen?: boolean;
  handleClose: () => void;
}

const Modal = ({
  children,
  title,
  actionsSection,
  isOpen,
  handleClose,
}: IProps) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={handleClose}
    >
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-opacity bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-50"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-2xl shadow-2xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 text-gray-900 bg-gradient-to-b from-blue-200 to-blue-50 bg-opacity-50 px-8 py-2 rounded-t-xl"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="w-full mt-4">
                    {children || <div className="w-full h-96"></div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
              {actionsSection}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export default Modal;
