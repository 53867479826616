import { extractDefaultGameParams } from '../../../utils/default-game-params';
import getLedspClient from '../../../utils/get-ledsp-client';
import { StoreSlice } from '../../utils/store-slice';
import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { IPlayer } from '../concepts/player';

const playerFromGamePlayIdCache = new Map();

export const create: StoreSlice<IPlayer, ILogger & IGame> = (set, get) => {
  return {
    playerId: '--null--',
    team: '',
    role: '',
    gamePlayId: '',
    players: [],

    async playerFromGamePlayId(gamePlayId: string = '') {
      if (playerFromGamePlayIdCache.get(gamePlayId)) {
        console.log('playerFromGamePlayIdCache: cache hit!');

        return playerFromGamePlayIdCache.get(gamePlayId);
      }

      console.log('playerFromGamePlayIdCache: cache miss!');

      const defaultParams = extractDefaultGameParams(gamePlayId);
      const gamePlayInfo = await getLedspClient(gamePlayId).gamePlayInfo({
        gameId: defaultParams.gameId,
        playerId: defaultParams.playerId,
        role: defaultParams.role,
        team: {
          id: defaultParams.team,
          name: defaultParams.team,
        },
        settings: {
          configuration: {
            gameResultsRegistryEndpoint: '',
            players: [
              {
                id: defaultParams.playerId,
                displayName: defaultParams.playerId,
                color: 'red',
              },
            ],
            playOptions: {},
            returnPath: '',
          },
          playURL: '',
        },
      });

      if (!gamePlayInfo) return nullPlayerInfo();

      const { gameId, playerId, team, role, settings } = gamePlayInfo;

      const result = {
        isValid: true,
        gamePlayId,
        gameId,
        playerId,
        role,
        team: team.id,
        displayName:
          settings?.configuration?.players?.find(
            player => player.id === playerId
          )?.displayName || playerId,
        players: settings?.configuration?.players?.map(player => player.id) || [
          playerId,
        ],
      };

      playerFromGamePlayIdCache.set(gamePlayId, result);

      return result;
    },

    playerExists(playerId: string) {
      return get().players.includes(playerId);
    },
  };
};

const nullPlayerInfo = () => ({
  isValid: false,
  gamePlayId: '--null--',
  gameId: '--null--',
  playerId: '--null--',
  role: '--null--',
  team: '--null--',
});
