import { FC, ReactElement, useEffect, useState } from 'react';
import path from 'path';
import { Redirect, useRoute } from 'wouter';
import { useGame } from '../../stores/game';
import ServiceScreen from '../service-screen';

export const CheckIfPlayerIsInGame: FC<{
  children?: ReactElement;
}> = props => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [playerIsInGame, setPlayerIsInGame] = useState<boolean>(false);

  const [match, params] = useRoute<GameRouteParams>('/:gamePlayId/:rest*');
  const playerFromGamePlayId = useGame(
    ({ playerFromGamePlayId }) => playerFromGamePlayId
  );
  const playerExists = useGame(({ playerExists }) => playerExists);

  const pathname = path.join('/', params.gamePlayId, 'not-in-game');

  useEffect(() => {
    const init = async () => {
      try {
        const playerData = await playerFromGamePlayId(params.gamePlayId);
        setPlayerIsInGame(playerExists(playerData.playerId));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoading && !playerIsInGame) init();
  }, [playerFromGamePlayId, playerIsInGame, isLoading]);

  if (!match)
    return <span>This component is not loaded under the correct path</span>;

  if (isLoading) return <ServiceScreen text="Loading..." />;

  if (!playerIsInGame) return <Redirect key={pathname} to={pathname} />;
  else return props.children;
};

type GameRouteParams = {
  gamePlayId: string;
  rest: string;
};
