import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { useTheme } from '../../stores/theme';
import buildOptimizedAssetPath from '../../utils/build-optimized-asset-path';
import { LearningLabLogo } from '../assets/llab-logo';
import { SDABocconiLogo } from '../assets/sdabocconi-logo';
import ScrollableContainer from '../utils/scrollable-container';
import { HomeHeader } from './home-header';
import { MainLayoutWithTheme } from './main-with-theme';

export const PlayTimeScreenLayout: FC<{
  children: ReactNode;
  side: ReactNode;
  navigation: ReactNode;
}> = props => {
  const images = useTheme(({ images }) => images);

  return (
    <MainLayoutWithTheme primaryColor="red">
      <HomeHeader variant="game">
        <div className="flex items-center justify-start w-1/3 h-full">
          <SDABocconiLogo />
        </div>
        <div className="flex items-center justify-center w-1/3 h-full">
          <img
            src={images(
              buildOptimizedAssetPath('hospitalia_logo_white', ['avif', 'png'])
            )}
            alt="Hospitalia logo"
            className="relative h-1/2 -top-1"
            style={{
              imageRendering: '-webkit-optimize-contrast',
              transform: 'scale(1.04)',
            }}
          />
        </div>
        <div className="flex items-center justify-end w-1/3 h-full">
          <LearningLabLogo variant="game" />
        </div>
      </HomeHeader>

      <div className="flex flex-row h-[calc(100vh_-_40px)] lg:h-[calc(100vh_-_60px)] xl:h-[calc(100vh_-_100px)] w-full select-none _nodrag">
        <div
          className={clsx(
            'flex flex-col h-full',
            props.side ? 'w-11/12' : 'w-full'
          )}
        >
          <div
            className={clsx(
              'overflow-y-hidden',
              props.navigation ? 'h-[88%]' : 'h-full'
            )}
            id="full-page-area"
          >
            <ScrollableContainer>{props.children}</ScrollableContainer>
          </div>

          {props.navigation && (
            <div
              className="h-[12%] mt-auto p-2 xl:p-4 flex justify-center items-center"
              id="navigation-area"
            >
              {props.navigation}
            </div>
          )}
        </div>

        {props.side && <div className="w-1/12 h-full">{props.side}</div>}
      </div>
    </MainLayoutWithTheme>
  );
};
