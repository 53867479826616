import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player';
import TextRevealer from './text-revealer';
import animation from '../../../lottie-animations/90942-news-icon-animation-customizable.json';

interface IProps {
  phase: string;
}

const Phase3BreakingNews = ({ phase }: IProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col w-full p-2 mt-4 lg:p-4 xl:p-6 2xl:p-8 lg:pt-1 xl:mt-20">
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-auto px-8 py-2 mx-auto mt-4 mb-6 text-center rounded-xl"
      >
        <p className="font-bold uppercase text-hospitaliaTeal-base text-1xl xl:text-3xl 2xl:text-5xl">
          {formatMessage({ id: 'pages:beaking-news.title' })}
        </p>
      </motion.span>
      <p className="w-1/2 mx-auto text-base text-center text-hospitaliaTeal-dark lg:text-lg xl:text-xl">
        <TextRevealer
          initialDelay={0.8}
          label={formatMessage({ id: 'pages:beaking-news.content' })}
        />
      </p>

      <Lottie
        animationData={animation}
        style={{ height: '28vh' }}
        play
        loop={false}
      />
    </div>
  );
};

export default Phase3BreakingNews;
