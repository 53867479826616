import { useEffect } from 'react';
import { useRoute } from 'wouter';
import { useGame } from '../../stores/game';

export const RecordLocationVisit = () => {
  const [match, params] = useRoute<GameRouteParams>('/:gamePlayId/:rest*');

  const visitLocation = useGame(({ visitLocation }) => visitLocation);

  if (!match)
    return <span>This component is not loaded under the correct path</span>;

  useEffect(() => {
    visitLocation(params.rest);
  }, [params.rest]);

  return null;
};

type GameRouteParams = {
  gamePlayId: string;
  rest: string;
};
