import { useEffect, useState } from 'react';

export default function useFullscreen() {
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof document === 'undefined') return;

    const onFullscreenChange = () =>
      setFullscreen(!!document.fullscreenElement);

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  return isFullscreen;
}
