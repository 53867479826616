import clsx from 'clsx';
import { useGame } from '../../../stores/game';
import { timer } from '../../layout/formatters';
import { updateEvery } from '../../utils/update-every';

export const NavTimer = updateEvery(1000)(() => {
  const timeLeft = useGame(({ countdownTimeLeft }) =>
    countdownTimeLeft(Date.now())
  );
  const isAboutToExpire = useGame(({ isCountdownAboutToExpire }) =>
    isCountdownAboutToExpire(Date.now())
  );

  const isCountdownOn = useGame(g => g.isCountdownOn);

  return (
    <div
      className={clsx(
        'w-full py-2 lg:py-4 2xl:py-8 mb-2 2xl:mb-4 text-center',
        isAboutToExpire ? 'bg-red-500' : 'bg-white'
      )}
    >
      <p
        className={clsx(
          'text-xxs xl:text-xs uppercase font-bold mb-px lg:mb-1 2xl:mb-2 leading-none xl:leading-none',
          isAboutToExpire ? 'text-white' : 'text-hospitaliaBlue-base'
        )}
      >
        Timer
      </p>
      <p
        className={clsx(
          'text-xs xl:text-base 2xl:text-xl uppercase font-bold',
          isAboutToExpire ? 'text-white' : 'text-hospitaliaBlue-base'
        )}
      >
        {isCountdownOn ? timer(timeLeft) : '--:--'}
      </p>
    </div>
  );
});
