import { StoreSlice } from '../../utils/store-slice';
import { ILogger } from '../concept/logger';

export const create: StoreSlice<ILogger> = (set, get) => {
  return {
    log: (msg: string) => {
      console.log(`## ${msg}`);
    },
    error: (reason: string) => {
      console.log(`#! ${reason}`);
    },
  };
};
