import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  updateDoc,
  setDoc,
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
} from 'firebase/firestore';
import { PhaseNames } from '../stores/game/concepts/game';
import InMemoryCache from '../utils/in-memory-cache';
import { PlayerInfo } from './apis/avatar';
import { GameDoc } from './apis/game';
import { IssuedVoteDoc, VotesResults } from './apis/vote';
import { IRemote } from './interface';

const firebaseConfig = {
  apiKey: 'AIzaSyA_erHMJ_aVTJwr7BIwsQ8ZMZ4LytHuqjY',
  authDomain: 'hospitalia-b7039.firebaseapp.com',
  projectId: 'hospitalia-b7039',
  storageBucket: 'hospitalia-b7039.appspot.com',
  messagingSenderId: '449783732368',
  appId: '1:449783732368:web:095a6b2f01a9eaf1cce015',
};

const fb = initializeApp(firebaseConfig);
const db = getFirestore(fb);

const GAMES_COLLECTION = 'games';
const VOTES_COLLECTION = 'votes';
const avatarsCollection = (gameId: string) => `avatars-${gameId}`;
const meetingRooms = (gameId: string) => `meetings-${gameId}`;
const LOCATIONS_COLLECTION_NAME = 'locations';
const TODOS_COLLECTION_NAME = 'todos';

export const remote: IRemote = {
  onGameSnapshot(gameId: string, cb: (doc: GameDoc) => void) {
    return onSnapshot(doc(db, GAMES_COLLECTION, gameId), doc => {
      doc.data() && cb(initGameDoc(doc.data()));
    });
  },

  startGame(gameId: string, startTime: number, players: string[]) {
    return updateDoc(doc(db, GAMES_COLLECTION, gameId), {
      isStarted: true,
      startTime,
      players,
    });
  },

  startGamePhase(gameId: string, phaseName: PhaseNames, time = Date.now()) {
    return updateDoc(doc(db, GAMES_COLLECTION, gameId), {
      currentPhase: phaseName,
      currentPhaseStartTime: time,
    });
  },

  setPlayers(gameId: string, players: string[]) {
    return updateDoc(doc(db, GAMES_COLLECTION, gameId), { players });
  },

  openChat(gameId: string) {
    return updateDoc(doc(db, GAMES_COLLECTION, gameId), {
      isChatOpen: true,
      isChatReadable: true,
    });
  },

  closeChat(gameId: string) {
    return updateDoc(doc(db, GAMES_COLLECTION, gameId), { isChatOpen: false });
  },

  onVoteSnapshot(gameRecordId: string, cb: (doc: IssuedVoteDoc) => void) {
    return onSnapshot(
      query(
        collection(db, VOTES_COLLECTION),
        where('gameRecordId', '==', gameRecordId)
      ),
      snapshot => {
        snapshot
          .docChanges()
          .filter(change => change.type === 'added')
          .map(change => initIssuedVoteDoc(change.doc.data()))
          .forEach(cb);
      }
    );
  },

  async saveVote(
    gameRecordId: string,
    id: `${/* playerId */ string}:${/* votingSession */ string}`,
    issuedVote: IssuedVoteDoc
  ): Promise<void> {
    const cachedSessionId = InMemoryCache.get(
      'sessionIdFromGameId',
      gameRecordId
    );

    let sessionId: string;

    if (cachedSessionId) sessionId = String(cachedSessionId);
    else {
      const gameDocument = await getDoc(
        doc(db, GAMES_COLLECTION, gameRecordId)
      );
      sessionId = gameDocument.data().sessionId;

      InMemoryCache.set('sessionIdFromGameId', gameRecordId, sessionId);
    }

    return await setDoc(doc(db, VOTES_COLLECTION, `${gameRecordId}::${id}`), {
      ...issuedVote,
      gameId: gameRecordId.split('::')[1],
      gameRecordId,
      sessionId,
    });
  },

  async saveVotesResults(gameRecordId: string, votesResults: VotesResults) {
    return await updateDoc(doc(db, GAMES_COLLECTION, gameRecordId), {
      ...votesResults,
    });
  },

  onAvatarSnapshot(gameId: string, cb: (doc: PlayerInfo) => void) {
    const q = query(collection(db, avatarsCollection(gameId)));

    return onSnapshot(q, snapshot => {
      snapshot
        .docChanges()
        .filter(change => change.type === 'added')
        .map(change => initPlayerInfo(change.doc.data()))
        .forEach(cb);
    });
  },

  saveAvatar(gameId: string, playerId: string, avatar: string): Promise<void> {
    const ref = doc(db, avatarsCollection(gameId), playerId);
    return setDoc(ref, { avatar, playerId });
  },

  setRoleMeetingRoom(gameId: string, role: string, meetingRoomURL: string) {
    const ref = doc(db, meetingRooms(gameId), `${gameId}.${role}`);
    return setDoc(ref, { role, meetingRoomURL });
  },

  onMeetingRoomSnapshot(gameId: string, cb: (doc: any) => void) {
    const q = query(collection(db, meetingRooms(gameId)));

    return onSnapshot(q, snapshot => {
      snapshot
        .docChanges()
        .filter(change => change.type === 'added')
        .map(change => initMeetingRooms(change.doc.data()))
        .forEach(cb);
    });
  },

  async syncLocations(gameRecordId, playerId, locations): Promise<void> {
    const cachedSessionId = InMemoryCache.get(
      'sessionIdFromGameId',
      gameRecordId
    );

    let sessionId: string;

    if (cachedSessionId) sessionId = String(cachedSessionId);
    else {
      const gameDocument = await getDoc(
        doc(db, GAMES_COLLECTION, gameRecordId)
      );
      sessionId = gameDocument.data().sessionId;
      InMemoryCache.set('sessionIdFromGameId', gameRecordId, sessionId);
    }

    return setDoc(doc(db, LOCATIONS_COLLECTION_NAME, playerId), {
      id: playerId,
      gameRecordId,
      sessionId,
      gameId: gameRecordId.split('::')[1],
      playerId,
      locations,
    });
  },

  syncToDos(
    gameRecordId: string,
    playerId: string,
    toDos: any // TODO: Type this!
  ): Promise<void> {
    const reference = doc(db, TODOS_COLLECTION_NAME, gameRecordId);

    return setDoc(reference, {
      gameId: gameRecordId.split('::')[1],
      gameRecordId,
      playerId,
      toDos,
    });
  },
};

const initGameDoc = (plain: Partial<GameDoc>): GameDoc => ({
  isStarted: false,
  startTime: 0,
  isStopped: false,
  currentPhase: 'intro',
  currentPhaseStartTime: Date.now(),
  players: [],
  isChatReadable: false,
  isChatOpen: false,
  meetingRooms: {
    DS: null,
    DF: null,
    DM: null,
    DP: null,
    PE: null,
    CA: null,
  },
  ...plain,
});

const initIssuedVoteDoc = (plain: Partial<IssuedVoteDoc>): IssuedVoteDoc => ({
  session: '--null--',
  voter: '--null--',
  vote: '--null--',
  isAbstained: false,
  motivation: '',
  ...plain,
});

const initPlayerInfo = (plain: Partial<PlayerInfo>): PlayerInfo => ({
  playerId: '',
  avatar: '',
  ...plain,
});

const initMeetingRooms = (
  plain: Partial<{ role: string; meetingRoomURL: string }>
): any => ({
  role: '',
  meetingRoomURL: '',
  ...plain,
});
