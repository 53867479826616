import { Link } from 'wouter';
import clsx from 'clsx';

interface IProps {
  label: string;
  link?: string;
  icon: string;
  isActive: boolean;
  disabled?: boolean;
  handleClick?: () => void;
}

const NavButton = ({
  label,
  link,
  icon,
  isActive,
  disabled = false,
  handleClick,
}: IProps) => {
  const v = (
    <span
      className={clsx(
        'w-2/3 xl:w-1/2 flex items-center justify-center aspect-square rounded-full bg-none p-2 border transition-all duration-200 ease-in-out cursor-pointer group',
        isActive
          ? 'border-neutral-300 shadow-[rgba(0,0,0,0.1)_0px_0px_10px_0px] bg-neutral-100'
          : !disabled
          ? 'border-transparent hover:shadow-[rgba(0,0,0,0.08)_0px_0px_10px_0px]'
          : 'cursor-not-allowed'
      )}
      onClick={handleClick}
    >
      <img
        src={icon}
        alt={label}
        title={label}
        style={{
          filter: !disabled ? 'none' : 'saturate(0)',
        }}
        className="w-full aspect-square group-hover:scale-105 transform transition-all duration-200 ease-in-out"
      />
    </span>
  );

  return disabled || !link ? v : <Link href={link}>{v}</Link>;
};
export default NavButton;
