import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useGame } from '../../../stores/game';
import Phase3VotePicker from '../phase-3-vote-picker';
import Lottie from 'react-lottie-player';
import animation from '../../../../lottie-animations/lf30_editor_kdbr8xns.json';

interface IAnswer {
  votingSessionId: string;
  answer?: string;
  motivation?: string;
}

interface IAnswers {
  question1: IAnswer;
  question2: IAnswer;
  question3: IAnswer;
}

interface IProps {
  session: string;
  playerId: string;
  currentPhase: string;
}

const Phase3Voting = ({ session, currentPhase, playerId }: IProps) => {
  const { formatMessage } = useIntl();

  const [answers, setAnswers] = useState<IAnswers>({
    question1: {
      votingSessionId: 'phase-3-diagnosis',
      answer: null,
      motivation: null,
    },
    question2: {
      votingSessionId: 'phase-3-distribution',
      answer: null,
      motivation: null,
    },
    question3: {
      votingSessionId: 'phase-3-tender',
      answer: null,
      motivation: null,
    },
  });

  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  const labels = ['diagnosis', 'distribution', 'tender'];

  const options = [
    useGame(game => game.availableVotes('phase-3-diagnosis')),
    useGame(game => game.availableVotes('phase-3-distribution')),
    useGame(game => game.availableVotes('phase-3-tender')),
  ];

  const issueVote = useGame(({ issueVote }) => issueVote);
  const canVote: boolean = useGame(({ isVoterInAtLeastOne, playerId }) =>
    isVoterInAtLeastOne(
      ['phase-3-diagnosis', 'phase-3-distribution', 'phase-3-tender'],
      playerId
    )
  );

  if (!canVote)
    return (
      <div className="w-full h-[50%] flex flex-col items-center justify-center px-10 lg:px-20 xl:px-32 2xl:px-64">
        <Lottie
          animationData={animation}
          play
          loop
          style={{
            height: '18vh',
          }}
        />

        <p className="mt-8 text-4xl">
          {formatMessage({ id: 'pages:waiting-rooms-ds-vote.title' })}
        </p>
        <p className="text-xl">
          {formatMessage({ id: 'pages:waiting-rooms-ds-vote.message' })}
        </p>
      </div>
    );

  return (
    <div className="px-10 lg:px-20 xl:px-32 2xl:px-64">
      <p className="mb-3 text-sm xl:text-lg 2xl:text-xl xl:mb-8">
        {formatMessage({
          id: `pages:decisions-${labels[currentQuestion]}.title`,
        })}
      </p>

      <p className="mb-px leading-none text-center text-xxs xl:text-base xl:leading-none">
        Scelta {currentQuestion + 1} di 3
      </p>
      <motion.p
        layoutId={`scelta-${currentQuestion + 1}`}
        key={`scelta-${currentQuestion + 1}`}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="mb-1 text-xs font-semibold text-center xl:mb-3 2xl:mb-10 xl:text-lg 2xl:text-2xl"
      >
        {formatMessage({
          id: `pages:decisions-${labels[currentQuestion]}.statement`,
        })}
      </motion.p>

      <div className="flex flex-row w-full h-full">
        <div className="flex flex-col items-center justify-start p-4 xl:p-8 w-2/5 min-h-[34vh]">
          <p className="mr-auto text-xs font-bold text-left text-gray-600 xl:text-base">
            Scelta
          </p>
          <Phase3VotePicker
            canVote={canVote}
            selectedOption={answers[`question${currentQuestion + 1}`].answer}
            handleChange={value =>
              setAnswers(draft => ({
                ...draft,
                [`question${currentQuestion + 1}`]: {
                  ...draft[`question${currentQuestion + 1}`],
                  answer: value,
                },
              }))
            }
            options={options[currentQuestion]}
            currentQuestion={labels[currentQuestion]}
          />
        </div>

        <div className="flex flex-col items-center justify-start p-4 xl:p-8 w-3/5 min-h-[34vh]">
          <p className="mr-auto text-xs font-bold text-left text-gray-600 xl:text-base">
            Motivazione
          </p>
          <textarea
            className="w-full p-4 leading-tight text-gray-700 rounded-lg bg-neutral-200/70 outline-blue-100"
            rows={8}
            onChange={event =>
              setAnswers(draft => ({
                ...draft,
                [`question${currentQuestion + 1}`]: {
                  ...draft[`question${currentQuestion + 1}`],
                  motivation: event.target.value,
                },
              }))
            }
            value={answers[`question${currentQuestion + 1}`].motivation || ''}
          />
        </div>
      </div>

      <div className="flex flex-row justify-center w-full space-x-8">
        <button
          disabled={currentQuestion <= 0}
          className="px-4 py-1 font-medium text-center text-white transition-all duration-200 ease-in-out rounded-lg text-xxs xl:text-base disabled:bg-neutral-200 bg-hospitaliaTeal-base hover:scale-105 active:scale-95"
          onClick={() => setCurrentQuestion(draft => draft - 1)}
        >
          precedente
        </button>
        <button
          disabled={currentQuestion >= 2}
          className="px-4 py-1 font-medium text-center text-white transition-all duration-200 ease-in-out rounded-lg text-xxs xl:text-base disabled:bg-neutral-200 bg-hospitaliaTeal-base hover:scale-105 active:scale-95"
          onClick={() => setCurrentQuestion(draft => draft + 1)}
        >
          prossima
        </button>
        <hr />
      </div>
      <div className="flex items-center justify-center flex-grow w-full p-4 mt-1 xl:mt-4 2xl:mt-6">
        <button
          disabled={
            currentPhase !== 'phase-3-voting' ||
            Object.keys(answers).some(
              answerId =>
                !answers[answerId].answer ||
                !answers[answerId].motivation?.length
            )
          }
          className="px-8 py-2 text-base font-bold text-center text-white transition-all duration-200 ease-in-out rounded-full xl:text-xl disabled:bg-neutral-200 bg-hospitaliaTeal-base hover:scale-105 active:scale-95"
          onClick={() => {
            for (const question in answers)
              issueVote({
                session: answers[question].votingSessionId,
                time: Date.now(),
                vote: answers[question].answer,
                voter: playerId,
                motivation: answers[question].motivation,
              });
          }}
        >
          Conferma scelte
        </button>
      </div>
    </div>
  );
};

export default Phase3Voting;
