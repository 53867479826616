import { useGame } from '../../stores/game';
import Phase1Scenario from '../game/scenarios/phase-1.scenario';
import Phase2Scenario from '../game/scenarios/phase-2.scenario';
import Phase3Scenario from '../game/scenarios/phase-3.scenario';
import { PlayTimeScreen } from './play-time-screen';

export const PhaseScenarioScreen = () => {
  const currentPhase = useGame(({ currentPhase }) => currentPhase);

  return (
    <PlayTimeScreen>
      {currentPhase === 'phase-1' ? (
        <Phase1Scenario />
      ) : currentPhase === 'phase-1-voting' ? (
        <p>Phase1voting should have a scenario?!</p>
      ) : currentPhase === 'phase-2' ? (
        <Phase2Scenario />
      ) : currentPhase === 'phase-3' ? (
        <Phase3Scenario />
      ) : (
        <p>~</p>
      )}
    </PlayTimeScreen>
  );
};
