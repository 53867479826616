import { useGame } from '../../stores/game';
import { useEffect } from 'react';
import { updateEvery } from '../utils/update-every';

export const CheckToDoCompletion = updateEvery(5000)(() => {
  const visitedLocations = useGame(s => s.visitedLocations);
  const areAllDocsRead = useGame(s => s.areAllDocsRead());
  const areAllAvatarsSelected = useGame(s => s.areAllAvatarsSelected());
  const isVotingSessionEnded = useGame(s =>
    s.votingSessions
      .filter(v => s.isSessionEnded(v.id, Date.now()))
      .map(v => v.id)
      .join(':')
  );
  const completeFirstToDo = useGame(s => s.completeFirstToDo);

  useEffect(() => {
    completeFirstToDo();
  }, [
    visitedLocations.length,
    areAllDocsRead,
    areAllAvatarsSelected,
    isVotingSessionEnded,
    Date.now(),
  ]);

  return null;
});
