import { PlayTimeScreen } from './play-time-screen';
import { useGame } from '../../stores/game';
import React from 'react';
import Phase3BreakingNews from '../game/phase-3-breaking-news';
import { useTheme } from '../../stores/theme';

const Phase3BreakingNewsScreen = () => {
  const currentPhase = useGame(({ currentPhase }) => currentPhase);
  const images = useTheme(({ images }) => images);

  return (
    <PlayTimeScreen>
      <div className="w-full h-full bg-cover bg-bottom" style={{ backgroundImage: `url(${images('breaking-news.png')})` }}>
        <Phase3BreakingNews phase={currentPhase} />
      </div>
    </PlayTimeScreen>
  );
};

export default Phase3BreakingNewsScreen;
